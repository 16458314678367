import React, {Component, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import "./UncompletedUserInfo.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UncompletedUserInfo = () => {

    return (
        <>
            <section className="banner-sec">
                <Container>
                    <div className="errors-alert">
                        <FontAwesomeIcon
                            className="alert-icon"
                            icon={faInfoCircle}
                        ></FontAwesomeIcon>
                        <span>
                            لم تقم بإستكمال بيانات حتى الآن فضلا التوجة للصفحة الشخصية
                            &nbsp;
                            <Link className={'text-dark'} to={`/edit-profile`}>( من هنا )</Link>
                            &nbsp;
                            لإستكمال بياناتك
                        </span>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default UncompletedUserInfo;
