import { faBars, faCaretLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../../../Services/DataService';
import './index.scss';

const CatsButton = (props) => {
    const[isOpen,setIsOpen]= useState(false);
    const[cats,setCats]= useState([]);
    const[activeBtn,setActiveBtn]= useState(false);

    useEffect(() => {
        getData(`api/categories`).then((response) => {
            const data = response.data.data;
            setCats(data);
          });

    },[])
    const showSubCats = (e) => {
        let activeBtn = activeBtn;
        var x = e.target;
        x.classList.toggle("activeLink");
    
        x.nextElementSibling.classList.toggle("showSubCats");
        activeBtn = !activeBtn;
        setActiveBtn(activeBtn);
      };
    // loop data from api
    const Cats =
    cats ? (
      cats.map((item) => {
        return (
          <li key={item.id}>
            {item.sub_category ? (
              <p
                onClick={showSubCats}
                id={`item${item.id}`}
                className="siblings_of_ul"
              >
                <span>{item.title.ar}</span>
                <FontAwesomeIcon
                  icon={faCaretLeft}
                  className="register-icon"
                />
              </p>
            ) : (
              <NavLink to={item.id}>
                <span>{item.title.ar}</span>
              </NavLink>
            )}

            {item.sub_category? (
              <ul className="sub-cats sub-cats-parent" id="for_toggel">
                {item.sub_category.map((subItem) => {
                  return (
                    <li key={subItem.id}>
                      <NavLink to={`/books/category/sub/${subItem.id}`}>
                        <FontAwesomeIcon
                          icon={faCaretLeft}
                          className="register-icon"
                        />
                        <span>{subItem.title.ar}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </li>
        );
      })
    ) : (
      <li>
        <span>لا توجد اقسام حاليا للعرض</span>
      </li>
    );

  // toggle menu
  const showCats = () => {
    let element = document.getElementById("catsToggle");
    element.classList.toggle("showCats");
    setIsOpen(!isOpen);
  };

  return (
    <>
        <div
            className={`cats-control-toggle ${
              isOpen ? "activeHead" : ""
            }`}
            onClick={() => props.buttonClick(true)}
          >
            <span className="cats-icon">
              <FontAwesomeIcon icon={faBars} className="register-icon" />
            </span>
            <span>قائمة تصنيفات الكتب</span>
        </div>
    </>
  );
}

export default CatsButton;





