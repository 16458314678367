import React from "react";
import './index.scss';

const Loading = () => {
    return (
        <>
         <div className="loading">
            <div className="loader book">
                <figure className="page"></figure>
                <figure className="page"></figure>
                <figure className="page"></figure>
            </div>
        </div>
        </>
       
    )
}

export default Loading;