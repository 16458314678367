import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getData } from '../../../Services/DataService';
import './Video.scss';


const VideoSec = () => {
    
    const [video,setVideo] = useState('');

    useEffect(() => {
        getData(`api/mainsettings`).then( (response) =>  {
            const data = response?.data?.data;
            setVideo(data)
        },(error) => {
            setVideo([]);
        })
    },[])
    const youtubeCode = video?.home_video?.substring(video?.home_video.length, video?.home_video?.lastIndexOf(`/`)).slice(1);

    return (  
        <>
        <section className="video-sec">
            <Container>
                <Row className="flx-centered">
                    <Col md={5}>
                        <div className="video-txt">
                            <h2 className="video-title">{video?.home_title?.ar}</h2> 
                            <p   dangerouslySetInnerHTML={{ __html: video?.home_content?.ar }}></p>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className="video-parent"> 
                            <div className="cont">
                                <div className="cont-img">
                                    <iframe
                                        width="100%"
                                        height="400"
                                        src={video?.home_video}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        >
                                    </iframe>
                                    {/* <video src={video?.home_video} controls  poster={`https://img.youtube.com/vi/${youtubeCode}/hqdefault.jpg`}></video> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
    );
}


export default VideoSec;
