import React, { Component } from 'react';
import { Container , Row , Col , Form , Button} from 'react-bootstrap'; 
import  axios  from 'axios';
import BaseUrl from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle  } from '@fortawesome/free-solid-svg-icons';
import { getCookie } from  '../../../Components/Auth/ForCookie';
import './NewsAdd.scss';
class NewsAdd extends Component {
    state = { 
        blogTitle:'',
        blogContent:'',
        BlogImage:'',
        errorLog:{},
        sucessSend:false
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    // upload and get the image of user 
    handleUserPicUpload = (e) => {
        let fileSrc = e.target.files;
        this.setState({[e.target.id]:fileSrc[0]})
    }

    handleSubmit = (e) =>{
        e.preventDefault();
        this.setState({errorLog:{}})

        const formData = new FormData();
        formData.append("titleEn", this.state.blogTitle);
        formData.append("titleAr", this.state.blogTitle);
        formData.append("contentEn", this.state.blogContent);
        formData.append("contentAr", this.state.blogContent);
        formData.append("blog_img", this.state.BlogImage);



        const headers = {
            "Content-Type": "multipart/form-data",
            "Accept":"application/json",
            "Authorization":`Bearer ${getCookie('token')}`
        }

        axios.post(`${BaseUrl}/api/add-blogs`, formData , {headers}).then(
            (response) => {
                this.setState({sucessSend:true})
            },
            (error) => {
                const errorLog = error.response.data;
                this.setState({errorLog})
            }
        );
    }


    render() { 
        return (
            <>
            <section className='contact-us-page-parent'>
                <Container>
                    <Row>
                        <Col xs={12} lg={10} className="mx-auto">
                            <div className="form-heading">
                                <h1>رفع مقال</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={8} className="mx-auto">
                        {this.state.sucessSend &&  
                            <div className="sucess-alert mt-4">
                                <FontAwesomeIcon className="alert-icon" icon={faCheckCircle}></FontAwesomeIcon>
                                <span>شكرا لك لقد تم الرفع بنجاح</span>
                            </div>
                        }
                            <Form className="Contact-us-form FormParent" onSubmit={this.handleSubmit}>                                 
                                <Form.Group className={`${this.state.errorLog && this.state.errorLog.titleAr ?'has-error':null}`}>
                                    <Form.Label>عنوان المقال</Form.Label>
                                    <Form.Control type="text" id="blogTitle" value={this.state.blogTitle} onChange={this.handleChange} />
                                    { this.state.errorLog && this.state.errorLog.titleAr ? 
                                            <div className="errors-parent">
                                                <span className='error-txt'>{this.state.errorLog.titleAr.ar}</span>
                                            </div>
                                        :null
                                    }
                                </Form.Group>

                                <Form.Group className={`${this.state.errorLog && this.state.errorLog.blog_img ?'has-error':null}`}>
                                    <Form.Label>صورة المقال</Form.Label>
                                    <div className="custom-input-file-parent" onChange={this.getTextOfInputName}> 
                                        <Form.Control type="file" onChange={this.handleUserPicUpload} id="BlogImage"/> 
                                        <div className="custom-input-file">
                                            <span className="changable-txt">
                                                {this.state.userPicName?this.state.userPicName:`حجم الملف لا يزيد عن 5 ميجا بايت`}
                                            </span>
                                            <span className="colored-txt">آختر صورة</span>
                                        </div>
                                    </div>
                                    { this.state.errorLog && this.state.errorLog.blog_img ? 
                                        <div className="errors-parent">
                                            <span className='error-txt'>{this.state.errorLog.blog_img.ar}</span>
                                        </div>
                                    :null
                                    }
                                </Form.Group>

                                <Form.Group className={`${this.state.errorLog && this.state.errorLog.contentAr ?'has-error':null}`}>
                                    <Form.Label>محتوي المقال</Form.Label>
                                    <Form.Control as="textarea" id="blogContent" value={this.state.blogContent} onChange={this.handleChange} />
                                    { this.state.errorLog && this.state.errorLog.contentAr ? 
                                            <div className="errors-parent">
                                                <span className='error-txt'>{this.state.errorLog.contentAr.ar}</span>
                                            </div>
                                        :null
                                    }
                                </Form.Group>
                                
                                <Button type="submit" className="btn primary-btn mt-3"> إرسال</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>

            </section>
            </>
        );
    }
}

export default NewsAdd;