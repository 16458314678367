import React, { Component } from 'react';
import axios from 'axios';
import BaseUrl from '../../../../config';
import {Form , Button} from 'react-bootstrap';
import { getCookie } from '../../../../Components/Auth/ForCookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle  } from '@fortawesome/free-solid-svg-icons';
import './CommentsAdd.scss';

class AddComment extends Component {
    state = { 
        blogCommentContent:'',
        sucessSend:false,
        errorLog:{},
        
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({errorLog:{}})
        const formData = new FormData();
        formData.append("blog_id", this.props.blogId);
        formData.append("blog_comment", this.state.blogCommentContent);


        const headers = {
            "Content-Type": "multipart/form-data",
            "Accept":"application/json",
            "Authorization":`Bearer ${getCookie('token')}`
        }

        axios.post(`${BaseUrl}/api/blog/comment`, formData , {headers}).then(
            (response) => {
                this.setState({sucessSend:true})
                setTimeout(() => {
                    this.setState({sucessSend:false});
                }, 5000);  
            },
            (error) => {
                const errorLog = error.response.data;
                this.setState({errorLog})
            }
        );
    }

    render() { 
        return (
            <>
                <div className="add-comment-parent">
                    <h2>إضافة تعليق</h2>
                    {this.state.sucessSend &&  
                        <div className="sucess-alert mt-4">
                            <FontAwesomeIcon className="alert-icon" icon={faCheckCircle}></FontAwesomeIcon>
                            <span>شكرا لك لقد تم الرفع بنجاح</span>
                        </div>
                    }
                    {
                        this.state.errorLog && this.state.errorLog.blog_id ? 
                        <div className="danger-alert mt-4">
                            <FontAwesomeIcon className="alert-icon" icon={faCheckCircle}></FontAwesomeIcon>
                            <span>حدث خطا ما</span>
                        </div>:null
                    }
                    <Form className='FormParent' onSubmit={this.handleSubmit}>
                        <Form.Group className={`${this.state.errorLog && this.state.errorLog.blog_comment ?'has-error':null}`}>
                                <Form.Label>التعليق</Form.Label>
                                <Form.Control as="textarea" id="blogCommentContent" value={this.state.blogCommentContent} onChange={this.handleChange} />
                                { this.state.errorLog && this.state.errorLog.blog_comment ? 
                                        <div className="errors-parent">
                                            <span className='error-txt'>{this.state.errorLog.blog_comment.ar}</span>
                                        </div>
                                    :null
                                }
                            </Form.Group>
                            
                            <Button type="submit" className="btn primary-btn mt-3"> نشر التعليق</Button>
                    </Form>
                </div>
            </>
        );
    }
}
 
export default AddComment;