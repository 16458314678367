import React, { Component } from "react";
import axios from "axios";
import BaseUrl from "../../../../config";
import { getCookie } from "../../../../Components/Auth/ForCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faClock,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import "../commentsList/commentsList.scss";
import { NavLink } from "react-router-dom";

const DealsComments = (props) => {
  return (
    <>
      <section className="comments-list-parent">
        <div className="heading">
          <h2>التعليقات</h2>
        </div>

        {props?.service_orders?.length > 0 ? (
          props?.service_orders?.map((comment) => {
            return (
              <div className="comment-body" key={comment.id}>
                <div className="cont d-flex">
                  <div className="cont-img">
                    <Link to={`/profile/${comment.request_user.id}`}>
                      <img src={comment.request_user.avatar} alt="" />
                    </Link>
                  </div>
                  <div className="cont-txt">
                    <Link to={`/profile/${comment.request_user.id}`}>
                      {comment.request_user.name}
                    </Link>
                    <div className="d-flex align-items-center">
                      <div className="date">
                        <i>
                          <FontAwesomeIcon icon={faClock} />
                        </i>
                        <span>{comment.created_at}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  className="comment-txt"
                  dangerouslySetInnerHTML={{ __html: comment.service_comment }}
                ></p>
              </div>
            );
          })
        ) : (
          <div className="no-content-show">
            <p>لا يوجد تعليقات لعرضها </p>
          </div>
        )}
      </section>
    </>
  );
};

export default DealsComments;
