import React from 'react';
import {  Container , Row, Col } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import authors from '../../../Assets/Img/authors.png';
import library from '../../../Assets/Img/library.png';
import postHome from '../../../Assets/Img/post-home.png';
import printer from '../../../Assets/Img/printer.png';
import './Categories.scss';
const WebsiteCategories = () => {
    return ( 
        <>
            <section className="categories-parent">
                <Container>
                    <Row>
                        <Col>
                            <div className="text-center m-b-40">
                                <h2 className="news-title">الأقسام</h2>
                                <p>نحن نخدم كلا من</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={3}>
                            <div className="card-parent">
                                <div className="cont">
                                    <div className="cont-img">
                                        <img src={authors} alt="المؤلفين" />
                                    </div>
                                    <div className="cont-txt">
                                        <h2>المؤلفين</h2>
                                        <p> نساعدك في الوصول السريع للجمهور والتواصل معهم، وإبراز وتسويق نتاجك العلمي.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card-parent">
                                <div className="cont">
                                    <div className="cont-img">
                                        <img src={postHome} alt="دار النشر" />
                                    </div>
                                    <div className="cont-txt">
                                        <h2>دار النشر</h2>
                                        <p>
                                        نساعدك في تقديم خدماتك المتنوعة، وبناء العلاقات مع المؤلفين وغيرهم بشكل سريع ومباشر.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card-parent">
                                <div className="cont">
                                    <div className="cont-img">
                                        <img src={library} alt="المكتبات" />
                                    </div>
                                    <div className="cont-txt">
                                        <h2>المكتبات</h2>
                                        <p>سهلنا عليك عملية التحول التقني بأسلوب جديد يعزز وجودك الافتراضي ويربطك بالمجتمع في أي مكان. </p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card-parent">
                                <div className="cont">
                                    <div className="cont-img">
                                        <img src={printer} alt="المطابع" />
                                    </div>
                                    <div className="cont-txt">
                                        <h2>المطابع</h2>
                                        <p>أنتم تستحقون الاهتمام لذلك أوجدنا منطقة صفقات بيع والشراء لتقديم عروضكم المتميزة.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
 
export default WebsiteCategories;