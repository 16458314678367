import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../config";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import Favourite from "../../../Components/Blocks/Favourite";
import Loading from '../../../Components/Blocks/Loading';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import "./index.scss";

class AllBooks extends React.Component {
  state = {
    isOpen: false,
    categoryData: [],
    pages: [],
    activeBtn: false,
    Loading: true,
  };

  componentDidMount() {
    //request to get the books of this category
    axios.get(`${BaseUrl}/api/books`).then(
      (response) => {
        const categoryData = response.data.data.data;
        this.setState({ Loading: false });
        this.setState({ categoryData });
        this.setState({ currentPage: `${response.data.data.current_page}` });
        this.setState({ nextPage: `${response.data.data.next_page_url}` });
        this.setState({ prevPage: `${response.data.data.prev_page_url}` });
        this.setState({ LastPage: `${response.data.data.last_page_url}` });
        this.setState({ totalPages: `${response.data.data.last_page}` });
        this.setState({ currentLink: `${response.data.data.path}` });
        this.setState({ last_page: response.data.data.last_page });
        this.setState({ pages: response.data.pages });
      },
      (error) => {
        this.setState({ categoryData: [] });
      }
    );
  }
  // handle change in content according to pagination
  fillData = (url) => {
    axios.get(url).then(
      (response) => {
        const categoryData = response.data.data.data;
        this.setState({Loading:false});
        this.setState({ categoryData });
        this.setState({ currentPage: `${response.data.data.current_page}` });
        this.setState({ nextPage: `${response.data.data.next_page_url}` });
        this.setState({ prevPage: `${response.data.data.prev_page_url}` });
        this.setState({ LastPage: `${response.data.data.last_page_url}` });
        this.setState({ totalPages: `${response.data.data.last_page}` });
        this.setState({ currentLink: `${response.data.data.path}` });
        this.setState({ last_page: response.data.data.last_page });
        this.setState({ pages: response.data.pages });
        window.scrollTo({ top: 0, behavior: "smooth" });

      },
      (error) => {
        this.setState({ categoryData: [] });
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  handleNextPage = (id) => {
    this.fillData(this.state.nextPage);
  };

  handlePrevPage = () => {
    this.fillData(this.state.prevPage);
  };

  handleGoToPage = (id) => {
    let pageNumber = id;
    this.fillData(`${this.state.currentLink}?page=${pageNumber}`);
  };

  render() {
    const pages = this.state.pages.length
      ? this.state.pages.map((page, index) => {
          return (
            <li
              key={index}
              className={
                Number(this.state.currentPage) === page ? `active` : ""
              }
              onClick={() => this.handleGoToPage(page)}
            >
              <button>{page}</button>
            </li>
          );
        })
      : null;
    const settings_imgs = {
      dots: true,
      touchMove: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      rtl: true,
    };
    const loopOnBooks = this.state.categoryData.length
      ? this.state.categoryData.map((book) => {
          return (
            <Col xs={12} lg={3} key={book.id} className="book-cont">
              <div className="cont">
                <div className="cont-img parent-imaes-slider">
                  <Slider {...settings_imgs}>
                    {book.images.map((img) => {
                      return (
                        <NavLink to={`/books/${book.id}`} key={Math.random()}>
                          <LazyLoadImage key={Math.random()} src={img} alt="bookImg" />
                        </NavLink>
                      );
                    })}
                  </Slider>
                </div>
                <div className="cont-txt">
                  <div className="cat-parent">
                    <span className="cat-name">
                      {book.main_category.title.ar}
                    </span>
                    <div className="rating">
                      <span>4.8</span>
                      <FontAwesomeIcon
                        icon={faStar}
                        className="register-icon"
                      />
                    </div>
                  </div>
                  <h3>
                    {" "}
                    <NavLink to={`/books/${book.id}`}>
                      {book.title.ar.length > 20 ? (
                        <>{book.title.ar.slice(0, 20).concat("...")}</>
                      ) : (
                        <>{book.title.ar}</>
                      )}
                    </NavLink>
                    <Favourite
                      bookId={book.id}
                      isFavourite={`${
                        book.check_wishlist === 1 ? true : false
                      }`}
                    />
                  </h3>

                  <div className="price-info flx-centered">
                    <p className="price">
                      <span>{book.price}</span>ريال سعودي
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          );
        })
      : null;
    return (
      <>
        <Helmet>
          <title>الكتب</title>
          <meta name="description" content="All Books" />
        </Helmet>
        {this.state.Loading === true ? <Loading/> : 
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <NavLink
                    to="/books/categories"
                    className="mob-bredcrumb t-none"
                  >
                    الكتب
                  </NavLink>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={12}>
              <Row className="books-parent">{loopOnBooks}</Row>
            </Col>
            <Col xs={12} lg={9} className="pagination-parent-books">
              {parseInt(this.state.totalPages) > 1 ? (
                <ul className="pagination-parent flx-centered">
                  <li className="first-page">
                    <button
                      onClick={this.handlePrevPage}
                      className={
                        this.state.currentPage == "1"
                          ? `disabled-btn`
                          : `arrow-btn`
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </li>

                  {this.state.currentPage !== 1 &&
                    this.state.currentPage !== 2 && (
                      <li
                        className="test-last"
                        onClick={() => this.handleGoToPage(1)}
                      >
                        <button>1</button>
                      </li>
                    )}
                  {this.state.currentPage !== 1 &&
                    this.state.currentPage !== 2 && (
                      <li className="to-continue">...</li>
                    )}

                  {pages}

                  {this.state.totalPages !== this.state.currentPage &&
                    this.state.currentPage !==
                      Number(this.state.totalPages) - 1 && (
                      <li className="to-continue">...</li>
                    )}
                  {this.state.totalPages !== this.state.currentPage &&
                    this.state.currentPage !==
                      Number(this.state.totalPages) - 1 && (
                      <li
                        className="test-last"
                        onClick={() =>
                          this.handleGoToPage(this.state.totalPages)
                        }
                      >
                        <button>{this.state.totalPages}</button>
                      </li>
                    )}

                  {/* {this.state.currentPage !== } */}
                  <li className="last-page">
                    <button
                      onClick={this.handleNextPage}
                      className={
                        this.state.currentPage === this.state.totalPages
                          ? `disabled-btn`
                          : `arrow-btn`
                      }
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                  </li>
                </ul>
              ) : null}
            </Col>
          </Row>
        </Container>
  }
      </>
    );
  }
}

export default AllBooks;
