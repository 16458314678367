import React, { Component, useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import BaseUrl from "../../../config";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import Favourite from "../../../Components/Blocks/Favourite";
import { getCookie } from "../../../Components/Auth/ForCookie";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const FeaturedBooks = () => {
  const [featuredBooks, setFeaturedBooks] = useState([]);
  useEffect(() => {
    getData(`api/sponsored/books`).then(
      (response) => {
        const data = response?.data?.data;
        setFeaturedBooks(data);
      },
      (error) => {
        setFeaturedBooks([]);
      }
    );
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Number(featuredBooks.length) <= 3 ? featuredBooks.length : 3,
    arrows: true,
    rtl: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Number(featuredBooks.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 922,
        settings: {
          slidesToShow: Number(featuredBooks.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };
  return (
    <>
      <section className="Featured-books-sec">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="widget-title">
                <h2>كتب مميزة</h2>
              </div>
            </Col>

            <Col>
              <Slider {...settings} className="featured-slider">
                {featuredBooks.length > 0 ? (
                  featuredBooks.map((item) => {
                    return (
                      <div key={item.id} className="featured-slide">
                        <div className="cont">
                          <div className="cont-img">
                            <Link to={`/books/${item.id}`}>
                              {item.images.length > 0 ? (
                                <Slider {...settings2}>
                                  {item?.images?.map((img, index) => {
                                    return (
                                      <div key={index}>
                                        <img src={img} alt={item?.title?.ar} />
                                      </div>
                                    );
                                  })}
                                </Slider>
                              ) : null}
                            </Link>
                          </div>
                          <div className="cont-txt">
                            <div className="cat-parent">
                              <span className="cat-name">
                                {item.main_category.title.ar}
                              </span>
                              <div className="rating">
                                <span>{item.rates}</span>
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="register-icon"
                                />
                              </div>
                            </div>

                            <h3>
                              <NavLink to={`/books/${item.id}`}>
                                {item.title.ar.length > 25
                                  ? `${item.title.ar.slice(0, 23)}...`
                                  : item.title.ar}
                              </NavLink>
                              <Favourite
                                bookId={item.id}
                                isFavourite={`${
                                  item.check_wishlist == 1 ? true : false
                                }`}
                              />
                            </h3>
                            <p
                              className="book-desc"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.content.ar.length > 25
                                    ? `${item.content.ar.slice(0, 25)}...`
                                    : item.content.ar,
                              }}
                            ></p>
                            <div className="price-info flx-centered">
                              <p className="price">
                                <span>{item.price}</span>ريال سعودي
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="errors-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faInfoCircle}
                    ></FontAwesomeIcon>
                    <span>لا توجد كتب مميزة</span>
                  </div>
                )}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FeaturedBooks;
