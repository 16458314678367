import React, { useEffect, useState } from "react";
import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../../Components/Auth/ForCookie";
import { getData } from "../../../Services/DataService";
import AddComment from "../Comments/CommentsAdd/CommentsAdd";
import CommentsList from "../Comments/commentsList/commentsList";
import NewsRelated from "../NewsRelated/NewsRelated";
import Loading from "../../../Components/Blocks/Loading";
import "./index.scss";
import { Helmet } from "react-helmet";

const NewsShow = (props) => {
  const [bLogNews, setBLogNews] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageName, setPageName] = useState("");
  useEffect(() => {
    getData(`api/blogs/${props?.match?.params?.id}`).then(
      (response) => {
        const data = response?.data?.data;
        setBLogNews(data);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setBLogNews([]);
        setLoading(false);
      }
    );
    setPageName(props.location.pathname.includes("blogs") ? "blogs" : "news");
  }, [props]);
  return (
    <>
      <Helmet>
        <title>{bLogNews?.title?.ar}</title>
      </Helmet>
      <section className="new-show-parent">
        {/* <Sugar customLoading={loading}  color={'#00A388'} background="#87D4C7"/> */}
        {loading && <Loading />}
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  {props?.location?.pathname?.includes("blogs") ? (
                    <NavLink to="/blogs" className="bredcrumb-link">
                      المدونة
                    </NavLink>
                  ) : (
                    <NavLink to="/news" className="bredcrumb-link">
                      الاخبار
                    </NavLink>
                  )}
                </li>

                <li className="breadcrumb-item">
                  <span
                    className="mob-bredcrumb active"
                    dangerouslySetInnerHTML={{
                      __html: bLogNews?.title?.ar && bLogNews?.title?.ar,
                    }}
                  ></span>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="cont">
                <div className="cont-title">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: bLogNews?.title?.ar && bLogNews?.title?.ar,
                    }}
                  ></h2>
                </div>
                <div className="cont-img">
                  <img src={bLogNews?.blog_img && bLogNews?.blog_img} alt="" />
                </div>

                <div className="cont-info">
                  <div className="date">
                    <FontAwesomeIcon icon={faClock} />
                    <span>{bLogNews?.created_at && bLogNews?.created_at}</span>
                  </div>
                  <div className="views">
                    <FontAwesomeIcon icon={faEye} />
                    <span>{bLogNews?.views} مشاهدة</span>
                  </div>
                </div>
                <div className="cont-txt">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: bLogNews?.content?.ar && bLogNews?.content?.ar,
                    }}
                  ></p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={10} className="mx-auto">
              {getCookie("loginned") === "true" && (
                <AddComment blogId={props?.match?.params?.id} />
              )}
              <CommentsList blogId={props?.match?.params?.id} />
            </Col>
          </Row>
        </Container>
        <NewsRelated pageName={pageName} />
      </section>
    </>
  );
};

export default NewsShow;
