import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import Loading from "../../../Components/Blocks/Loading";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import { useHistory, Link } from "react-router-dom";

const NewsBlock = () => {
  const [bLogNews, setBLogNews] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData(`api/news`).then(
      (response) => {
        const data = response?.data?.data?.data;
        setBLogNews(data);
        console.log("blogs", response);
        setTimeout(() => {
          setLoading(!loading);
        }, 800);
      },
      (error) => {
        setBLogNews([]);
        setTimeout(() => {
          setLoading(!loading);
        }, 800);
      }
    );
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Number(bLogNews?.length) >= 3 ? 3 : 1,
    slidesToScroll: 3,
    arrows: true,
    rtl: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 922,
        settings: {
          slidesToShow: Number(bLogNews?.length) >= 3 ? 3 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Number(bLogNews?.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const history = useHistory();
  return (
    <>
      <section className="blog-sec">
        <Container>
          {/* <Sugar customLoading={this.state.Loading} color={'#00A388'} background="#87D4C7"/> */}
          {loading && <Loading />}
          <Row>
            <Col>
              <div className="text-center m-b-40">
                <h2 className="news-title">الأخبار والأحداث</h2>
                <p>السواني منصة سعودية ثقافية</p>
                <a href="/news" className="all-btn">
                  جميع الاخبار
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Slider {...settings} className="featured-slider">
                {bLogNews.length > 0
                  ? bLogNews.map((item) => {
                      return (
                        <div key={item.id} className="blog-post-parent">
                          <div className="cont">
                            <div className="cont-img">
                              <img
                                src={`${item.blog_img}`}
                                alt=""
                                onClick={() => {
                                  history.push(`/news/${item.id}`);
                                }}
                              />
                            </div>
                            <div className="cont-txt">
                              <div className="d-flex">
                                <div className="date">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="register-icon"
                                  />
                                  <span>{item.created_at}</span>
                                </div>
                                <div className="views">
                                  <FontAwesomeIcon icon={faEye} />
                                  <span>{item.views} مشاهدة</span>
                                </div>
                              </div>
                              <h2>
                                <Link to={`/news/${item.id}`}>
                                  {item.title.ar}{" "}
                                </Link>
                              </h2>
                              <div
                                className="desc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.content.ar?.length > 80
                                      ? `${item.content.ar.slice(0, 80)}...`
                                      : item.content.ar,
                                }}
                              ></div>
                              <Link
                                to={`/news/${item.id}`}
                                className="btn primary-btn"
                              >
                                تفاصيل أكثر
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewsBlock;
