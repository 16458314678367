import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../config";
// import { Sugar } from 'react-preloaders';
import Slider from "react-slick";
import { getCookie } from "../../../Components/Auth/ForCookie";
import Favourite from "../../../Components/Blocks/Favourite";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getData } from "../../../Services/DataService";
import LoadingIndicator from "../../../Components/Blocks/Loading/Content";
import "./index.scss";

const AutherBooksRejected = (props) => {
  const [booksData, setbooksData] = useState([]);
  const [currentPage, setCurrentPage] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [prevPage, setPrevPage] = useState(true);
  const [LastPage, setLastPage] = useState(true);
  const [totalPages, setTotalPages] = useState(true);
  const [currentLink, setCurrentLink] = useState(true);
  const [last_page, setLast_page] = useState(true);
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //request to get the books of this category
    getData(`api/user-blocked-books`).then(
      (response) => {
        const data = response.data.data;

        setbooksData(response.data.data);
        console.log(data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setbooksData([]);
        setLoading(false);
      }
    );
  }, []);

  // handle change in content according to pagination
  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const data = response.data.data;
        setbooksData(data.data);
        console.log(booksData);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setbooksData([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const pages = Pages?.length
    ? Pages?.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;

  const settings_imgs = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };
  const loopOnBooks =
    booksData !== "not found" && booksData?.length > 0 ? (
      booksData?.map((book) => {
        return (
          <Col xs={12} lg={3} key={book.id} className="book-slide-cont">
            <div className="cont">
              <div className="cont-img parent-imaes-slider">
                <Slider {...settings_imgs}>
                  {book.images.map((img) => {
                    return (
                      <LazyLoadImage
                        key={Math.random()}
                        src={img}
                        alt="rijImg"
                      />
                    );
                  })}
                </Slider>
              </div>
              <div className="cont-txt">
                <div className="cat-parent">
                  {/* <span className="cat-name">
                    {book.main_category.title.ar}
                  </span>

                  <div className="rating">
                    <span>{book.rates}</span>
                    <FontAwesomeIcon icon={faStar} className="register-icon" />
                  </div> */}
                </div>
                <h3>
                  <NavLink to={`/books/${book.id}`}>
                    {book.title.ar.length > 50 ? (
                      <>{book.title.ar.slice(0, 50).concat("...")}</>
                    ) : (
                      <>{book.title.ar}</>
                    )}
                  </NavLink>
                  {/* <Favourite
                    bookId={book.id}
                    isFavourite={`${book?.check_wishlist === 1 ? true : false}`}
                  /> */}
                </h3>

                {/* <div className="price-info flx-centered">
                  <p className="price">
                    <span>{book?.price}</span>ريال سعودي
                  </p>
                </div> */}
              </div>
            </div>
          </Col>
        );
      })
    ) : (
      <Col xs={12} lg={7} className="no-books-show mx-auto">
        <div className="cont-txt">
          <p>
            لا يوجد لديك كتب مخالفة
            <br />
          </p>
        </div>
      </Col>
    );

  return (
    <>
      {/* <Sugar customLoading={Loading} color={'#00A388'} background="#87D4C7"/> */}

      <Row className="author-books-parent">
        {loading ? <LoadingIndicator /> : loopOnBooks}
      </Row>

      <Row>
        <Col xs={12} className="pagination-parent-books">
          {parseInt(totalPages) > 1 ? (
            <ul className="pagination-parent flx-centered">
              <li className="first-page">
                <button
                  onClick={handlePrevPage}
                  className={currentPage == "1" ? `disabled-btn` : `arrow-btn`}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </li>

              {currentPage !== 1 && currentPage !== 2 && (
                <li className="test-last" onClick={() => handleGoToPage(1)}>
                  <button>1</button>
                </li>
              )}
              {currentPage !== 1 && currentPage !== 2 && (
                <li className="to-continue">...</li>
              )}

              {pages}

              {totalPages !== currentPage &&
                currentPage !== Number(totalPages) - 1 && (
                  <li className="to-continue">...</li>
                )}
              {totalPages !== currentPage &&
                currentPage !== Number(totalPages) - 1 && (
                  <li
                    className="test-last"
                    onClick={() => handleGoToPage(totalPages)}
                  >
                    <button>{totalPages}</button>
                  </li>
                )}

              {/* {currentPage !== } */}
              <li className="last-page">
                <button
                  onClick={handleNextPage}
                  className={
                    currentPage === totalPages ? `disabled-btn` : `arrow-btn`
                  }
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </li>
            </ul>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AutherBooksRejected;
