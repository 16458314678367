const setCookie = (cookieName, cookieValue) => {
  var today = new Date();
  var expire = new Date();
  expire.setTime(today.getTime() + 3600000 * 24 * 15);

  document.cookie = `${cookieName}=${cookieValue};expires=${expire.toGMTString()}`;
};
const getCookie = (CookieName) => {
  var v = document.cookie.match("(^|;) ?" + CookieName + "=([^;]*)(;|$)");

  return v ? v[2] : null;
};
export { setCookie, getCookie };
