import React, { Component } from 'react';
import axios from 'axios';
import BaseUrl from '../../../../config';
import { getCookie } from '../../../../Components/Auth/ForCookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock , faChevronRight , faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Container , Row , Col } from 'react-bootstrap'; 
import './commentsList.scss';
import { NavLink } from 'react-router-dom';

class  CommentsList   extends Component {
    state = { 
        commentsData:[],
        currentPage:'',
        nextPage:'',
        prevPage:'',
        currentLink:``,
        pages:[],
        Loading:false,
        totalPages:'',
    } 

    async componentDidMount() {
        await axios.get(`${BaseUrl}/api/blog-comments/${this.props.blogId}`).then(
            (response)=>{
                const commentsData = response.data.data.data;
                this.setState({commentsData});
                this.setState({ currentPage: `${response.data.data.current_page}` });
                this.setState({ nextPage: `${response.data.data.next_page_url}` });
                this.setState({ prevPage: `${response.data.data.prev_page_url}` });
                this.setState({ LastPage: `${response.data.data.last_page_url}` });
                this.setState({ totalPages: `${response.data.data.last_page}` });
                this.setState({ currentLink: `${response.data.data.path}` });
                this.setState({last_page:response.data.data.last_page});
                this.setState({pages:response.data.pages});
            },(error) => {
                this.setState({commentsData:[]})
            }
        )
    }

    fillData =(url)=>{
        axios.get(url).then((response) => {
            const commentsData = response.data.data.data;
            this.setState({commentsData});
            this.setState({ currentPage: `${response.data.data.current_page}` });
            this.setState({ nextPage: `${response.data.data.next_page_url}` });
            this.setState({ prevPage: `${response.data.data.prev_page_url}` });
            this.setState({ LastPage: `${response.data.data.last_page_url}` });
            this.setState({ totalPages: `${response.data.data.last_page}` });
            this.setState({ currentLink: `${response.data.data.path}` });
            this.setState({last_page:response.data.data.last_page});
            this.setState({pages:response.data.pages});
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, (error) => {
            this.setState({deals:[]});
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        
    }

    handleNextPage = (id) => {
        this.fillData(this.state.nextPage);
    }

    handlePrevPage = () => {
        this.fillData(this.state.prevPage);
    };

    handleGoToPage = (id) => {
        let pageNumber = id
        this.fillData(`${this.state.currentLink}?page=${pageNumber}`);
    };


    render() { 
        const pages = this.state.pages.length ? this.state.pages.map((page,index)=>{
            return(
                
                <li key={index} className={Number(this.state.currentPage) === page ? `active`: ''} onClick={()=>this.handleGoToPage(page)}>
                    <button>
                        {page}
                    </button>
                </li>
                
            )
        }):null
        return (
            <>
                <section className='comments-list-parent'>
                    <div className="heading">
                        <h2>التعليقات</h2>
                    </div>

                    {this.state.commentsData.length > 0 ? this.state.commentsData.map((comment) => {
                        return (
                            <div className="comment-body" key={comment.id}>
                                <div className="cont d-flex">
                                    <div className="cont-img">
                                        <img src={comment.user_id.avatar} alt="" />
                                    </div>
                                    <div className="cont-txt">
                                        <a href={`/profile/${comment.user_id.id}`}>{comment.user_id.name}</a>
                                        <div className="d-flex align-items-center">
                                            <span className="role">{comment.user_id.roles.ar}</span>
                                            
                                            <div className="date">
                                                <i><FontAwesomeIcon  icon={faClock}/></i>
                                                <span>{comment.created_at}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className='comment-txt' dangerouslySetInnerHTML={{ __html: comment.blog_comment }}></p>
                            </div>
                        )
                        }):<div className='no-content-show'>
                                <p>لا يوجد تعليقات لعرضها </p>
                        </div>
                    }


                    <Container>
                    <Row>
                            <Col>
                                {parseInt(this.state.totalPages) > 1 ? (
                                    <ul className="pagination-parent flx-centered">
                                        <li className="first-page">
                                            <button onClick={this.handlePrevPage} className={this.state.currentPage == "1"? `disabled-btn`: `arrow-btn`}>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </li>

                                        {
                                            (this.state.currentPage != 1) && (this.state.currentPage != 2) && 
                                            <li className='test-last' onClick={() => this.handleGoToPage(1)} >
                                                <button>1</button>
                                            </li>
                                        }
                                        {
                                            (this.state.currentPage != 1) && (this.state.currentPage != 2) && 
                                            <li className='to-continue'>...</li>
                                        }

                                        
                                        {pages}

                                        {
                                            (this.state.totalPages != this.state.currentPage) && (this.state.currentPage != (Number(this.state.totalPages) - 1)) && 
                                            <li className='to-continue'>...</li>
                                        }
                                        {
                                            (this.state.totalPages != this.state.currentPage) && (this.state.currentPage != (Number(this.state.totalPages) - 1))  && 
                                            
                                            <li className='test-last' onClick={() => this.handleGoToPage(this.state.totalPages)} >
                                                <button>{this.state.totalPages}</button>
                                            </li>
                                        }

                                        
                                        {/* {this.state.currentPage != } */}
                                        <li className="last-page">
                                            <button onClick={this.handleNextPage} className={this.state.currentPage == this.state.totalPages ? `disabled-btn`: `arrow-btn`}>
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </button>
                                        </li>
                                    </ul>
                                ) : null}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}
 
export default CommentsList;