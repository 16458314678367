import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { createData } from "../../../Services/DataService.jsx";
import "./index.scss";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorLog, setErrorLog] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [errosObj, setErrosObj] = useState("");
  const [sucessStepOne, setSucessStepOne] = useState(false);

  const handleSubmitFirstStep = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    setShowError(false);
    createData(`api/forget-password-mail`, formData).then(
      (response) => {
        if (response.status === 200) {
          setSucessStepOne(true);
        }
      },
      (error) => {
        if (error) {
          setErrorLog(error.response.data.error);
          setShowError(true);
          setErrosObj(error.response.data.errors);
          setErrorData(error.response.data.data);
        } else {
          setErrorLog(null);
        }
      }
    );
  };
  return (
    <>
      <section className="login-sec">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="form-heading">
                <h1>مرحبا بعودتك !</h1>
                <p>تسجيل الدخول الى حسابك</p>
              </div>
            </Col>

            <Col md={6} xs={12} className="mx-auto">
              <Form className="loginForm" onSubmit={handleSubmitFirstStep}>
                {showError && (
                  <div className="errors-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faInfoCircle}
                    ></FontAwesomeIcon>
                    <span>{errorLog || errorData}</span>
                  </div>
                )}
                {sucessStepOne && (
                  <div className="sucess-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faCheckCircle}
                    ></FontAwesomeIcon>
                    <span>لقد تم ارسال رساله للبريد الالكتروني الخاص بك </span>
                  </div>
                )}
                <Form.Group
                  className={`${
                    errosObj && errosObj.email ? "has-error" : null
                  }`}
                >
                  <Form.Label>البريد الالكتروني</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@domain.com"
                    required
                  />
                  {errosObj && errosObj.email ? (
                    <div className="errors-parent">
                      <span className="error-txt">{errosObj.email.ar}</span>
                    </div>
                  ) : null}
                </Form.Group>

                {/* <Form.Group
                    className={`${
                      errosObj && errosObj.password ? "has-error" : null
                    }`}
                  >
                    <Form.Label>كلمة المرور</Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="على الأقل 8 ارقام و حروف"
                      required
                    />
                    {errosObj && errosObj.password ? (
                      <div className="errors-parent">
                        <span className="error-txt">
                          {errosObj.password.ar}
                        </span>
                      </div>
                    ) : null}
                  </Form.Group> */}

                <Button
                  className="btn all-btn login-btn flx-centered"
                  type="submit"
                >
                  تاكيد البريد الالكتروني
                </Button>

                <Form.Text className="text-muted">
                  <span>ليس لديك عضوية ؟ برجاء التوجة الي صفحة</span>
                  <Link href="/register">التسجيل</Link>
                </Form.Text>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgetPassword;
