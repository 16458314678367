import React, { Component } from 'react';
import {  Nav , Navbar } from 'react-bootstrap';
import {NavLink} from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const NavMenu = () => {
    const toggleMenu = () => {
        let element = document.getElementById("menuToggle");
        element.classList.toggle("showMenu");
    }
    
    return ( 
        <>
            <Navbar  expand="lg" className="nav-menu-parent">
                <div className="mobile-menu-parent">
                    <span className="icon" onClick={toggleMenu}>
                        <FontAwesomeIcon  icon={faTimes} className="register-icon"/>
                    </span>
                    <span>القائمة الرئيسية</span>
                </div>
                <Nav className="ml-auto">
                    <NavLink exact to="/" >الرئيسية</NavLink>
                    <NavLink to="/about-us">من نحن</NavLink>
                    <NavLink to="/books/categories">تصنيفات الكتب</NavLink>
                    <NavLink to="/news">الأخبار </NavLink>
                    <NavLink to="/blogs">المدونة</NavLink>
                    <NavLink to="/best-seller">افضل المعلنين</NavLink>
                    <NavLink to="/how-to-use">طريقة استخدام الموقع</NavLink>                           
                </Nav> 
            </Navbar>
        </>
);
}

export default NavMenu;