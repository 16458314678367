import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import clock from "../../../Assets/Img/clock.svg";
import { NavLink } from "react-router-dom";
import { getData } from "../../../Services/DataService";
import "./index.scss";

const CounterSlider = () => {
  const [events, setEvents] = useState([]);
  const [counterEnd, setCounterEnd] = useState("");

  useEffect(() => {
    getData(`api/latestevents`).then((response) => {
      const data = response.data.data;
      setEvents(data);
      // TODO check and ask for counter_end point
      // if (data.counter_end?.length > 0) {
      //   setCounterEnd(data.counter_end);
      // }
    });
  }, []);

  //Set the date we're counting down to

  if (counterEnd.length > 0) {
    let countDownDate = new Date(`${counterEnd}`).getTime();

    // Update the count down every 1 second
    let x = setInterval(function() {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      if (document.getElementById("countDown")) {
        document.getElementById(
          "countDown"
        ).innerHTML = `  <div className='count-item'><div className='count-number'> ${seconds}</div> ثانية</div>
                <div className='count-item'> <div className='count-number'>${minutes}</div> دقيقة</div>
                <div className='count-item'> <div className='count-number'>${hours}</div> ساعة</div>
                <div className='count-item'> <div className='count-number'> ${days}</div> يوم</div>
                `;

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          document.getElementById(
            "countDown"
          ).innerHTML = `<div className='count-item'>انتهي وقت الفاعلية</div>`;
        }
      }
    }, 1000);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };

  return (
    <>
      <section className="events-sec">
        <Container className="events-cont">
        <Slider {...settings} className="featured-slider">
          {
            events.map((event) => {
              return (
                <>
                <div className="widget-title">
                  <h2>الفعاليات</h2>
                  <NavLink to="/events" className="all-btn">
                    جميع الفعاليات
                  </NavLink>
                </div>
                <div className="bg-parent">
                  <div className="event-slider">
                    <Row>
                      <Col md={3}>
                        <div className="img-parent">
                          <img src={event?.img} alt="counter-event" />
                        </div>
                      </Col>
                      <Col md={{ span: 9, order: 2 }}>
                        <div className="cont-txt">
                          <div className="event-timing">
                            <img src={clock} alt="" />

                            <span>
                              من {event.event_start} حتي {event.event_end}
                            </span>
                          </div>
                          <h2>{event.title.ar}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: event.content.ar,
                            }}
                          ></p>
                          <NavLink
                            to={`/events/${event.id}`}
                            className="red-btn details"
                          >
                            تفاصيل اكثر
                          </NavLink>
                          <a
                            href={event.link}
                            target="__blank"
                            className="red-btn details mr-3"
                          >
                            رابط الفعالية
                          </a>

                          <h3>الوقت المتبقي على الفاعلية</h3>
                          <div className="count-sec" id="countDown"></div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  </div>
                </>
              )
            })
          }
        </Slider>
        </Container>
      </section>
    </>
  );
};

export default CounterSlider;
