import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import aboutImg from "../../Assets/Img/about-us.jpg";
import Loading from "../../Components/Blocks/Loading";
import { getData } from "../../Services/DataService";
import useScrollToTop from "../../hooks/useScrollToTop";
import "./Privacy.scss";

const Privacy = () => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);
  const [pageSlug, setPageSlug] = useState("");
  const [pageImage, setPageImage] = useState("");
  const [pageContent, setPageContent] = useState("");

  useEffect(() => {
    getData(`api/privacy-policy`).then(
      (response) => {
        const data = response.data.data;
        setLoading(false);
        setPageData(data);
        setPageSlug(response.data.data.slug.ar);
        setPageImage(response.data.data.image);
        setPageContent(response.data.data.content.ar);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);
  useScrollToTop();
  return (
    <>
      <Helmet>
        <title>{pageData?.title?.ar}</title>
      </Helmet>
      <section className="pricavy-page-parent">
        {/* <Sugar customLoading={loading}  color={'#00A388'} background="#87D4C7"/> */}
        {loading && <Loading />}

        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">سياسة الخصوصية</span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            {pageData ? (
              <Col xs={12}>
                <div className="cont">
                  <div className="cont-title">
                    <h2>
                      {pageSlug.length > 0 ? pageSlug : `تعرف  علي  skitab.com`}
                    </h2>
                  </div>
                  <div className="cont-img">
                    <img src={pageImage ? pageImage : aboutImg} alt="" />
                  </div>
                  <div className="cont-txt">
                    {pageContent.length ? (
                      <p dangerouslySetInnerHTML={{ __html: pageContent }}></p>
                    ) : (
                      <p>حدث خطا ما يرجي اعادة تحميل الصفحة</p>
                    )}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
