import axios from "axios";
import {getCookie} from '../Components/Auth/ForCookie';
const BaseUrl = process.env.REACT_APP_API_ENDPOINT;
const AxiosInstance = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept":"application/json",
    "Authorization":`Bearer ${getCookie('token')}`
  },
});

export default AxiosInstance;