import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../config";
import { getCookie } from "../../../Components/Auth/ForCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { useHistory } from "react-router-dom";

function SendMessage(props) {
  const [messageContent, setMessageContent] = useState("");
  const [successSend, setSuccessSend] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});

  const handleChange = (e) => {
    setMessageContent(e.target.value);
  };
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorsLog({});
    const formData = new FormData();
    formData.append("receive", props.recevierId);
    formData.append("message", messageContent);
    formData.append("type", props.type);
    formData.append("model_id", props.CatID);

    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    };

    axios.post(`${BaseUrl}/api/chat-module`, formData, { headers }).then(
      (response) => {
        setSuccessSend(true);
        setTimeout(() => {
          setSuccessSend(false);
        }, 3000);
      },
      (error) => {
        if (error) {
          const errors = error.response.data;
          if (errors?.chat_id) {
            history.push(
              `/messages?tab=${props?.type}&chat=${errors?.chat_id}`
            );
          } else {
            setErrorsLog(errors);
          }
        }
      }
    );
  };

  return (
    <>
      {successSend && (
        <div className="success-alert">
          <FontAwesomeIcon
            className="alert-icon"
            icon={faCheckCircle}
          ></FontAwesomeIcon>
          <span>شكرا لقد تم ارسال رسالتك بنجاح</span>
        </div>
      )}
      {errorsLog && errorsLog.data ? (
        <div className="errors-alert">
          <FontAwesomeIcon
            className="alert-icon"
            icon={faInfoCircle}
          ></FontAwesomeIcon>
          <span>{errorsLog.data}</span>
        </div>
      ) : null}
      <Form className="FormParent" onSubmit={handleSubmit}>
        <Form.Group
          className={`${errorsLog && errorsLog.message ? "has-error" : null}`}
        >
          <Form.Label>تفاصيل الرسالة</Form.Label>
          <Form.Control
            id="messageContent"
            as="textarea"
            onChange={handleChange}
            placeholder="اكتب تفاصيل الرسالة هنا"
          />
          {errorsLog && errorsLog.message ? (
            <div className="errors-parent">
              <span className="error-txt">{errorsLog.message.ar}</span>
            </div>
          ) : null}
        </Form.Group>
        <Button type="submit" className="btn primary-btn">
          {" "}
          إرسال
        </Button>
      </Form>
    </>
  );
}

export default SendMessage;
