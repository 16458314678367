import React from 'react';
import './404Page.scss';
import {Container , Row , Col} from 'react-bootstrap'
import Image from '../../Assets/Img/404/404.png';
import {NavLink} from 'react-router-dom';

const ErrorPage = () => {
    return ( 
        <>
            <section className="error-page-parent">
                <Container>
                    <Row>
                        <Col className="cont flx-centered">
                            <img src={ Image && Image} alt="error-image" />
                            <div className="cont-txt">
                                <div className="widget-title">
                                    <h2>
                                        الصفحة التي تريد الوصول اليها غير موجودة        
                                    </h2>
                                </div>
                                    <NavLink to='/' className="primary-btn-rgba btn">
                                        برجاء العودة للصفحة الرئيسية
                                    </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default ErrorPage;