import AxiosInstance from '../Network/AxiosInstance'
import {getCookie} from '../Components/Auth/ForCookie';
import Axios from 'axios';
const BaseUrl = process.env.REACT_APP_API_ENDPOINT;
export const getData = async (url) => {
    const response = await AxiosInstance.get(`${url}`);
    // console.log("responseServices", response)
    if (response !== undefined)
        if (response.status === 200) 
        return response
}

export const getDataById = async (url,id) => {
    const response = await AxiosInstance.get(`${url}/${id}`);
    if (response !== undefined)
        if (response.status === 200) 
        return response.data
}



// Example POST method implementation:
export async function createData(url, formData) {
    // console.log("formDatadata", formData)
    const response = await Axios.post(`${BaseUrl}/${url}`, formData, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      return response;
  }

export const editData = async (url,id,obj) => {
    const response = await AxiosInstance.put(`${url}/${id}`, obj);
    if (response !== undefined)
        if (response.status === 200) 
        return response.data;
};


