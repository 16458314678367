import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import BaseUrl from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faThList } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AiFillCheckCircle } from "react-icons/ai";
import Loading from "../../Components/Blocks/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.scss";
import useScrollToTop from "../../hooks/useScrollToTop";

const BestSeller = () => {
  const [seller, setSeller] = useState([]);
  const [gridView, setGridView] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/best-sellers`)
      .then((res) => {
        setSeller(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const hanldeView = () => {
    setGridView(!gridView);
  };
  useScrollToTop();
  return (
    <Fragment>
      <Helmet>
        <title>أفضل المعلنين</title>
        <meta name="description" content="Best Seller" />
      </Helmet>
      {loading === true ? (
        <Loading />
      ) : (
        <div className="bestSeller">
          <Container className="container">
            {/* Start Title PAge  */}
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/" className="bredcrumb-link">
                  الرئيسية
                </NavLink>
              </li>

              <li className="breadcrumb-item">
                <NavLink
                  to="/deals"
                  className="bredcrumb-link mob-bredcrumb t-none"
                >
                  أفضل المعلنين
                </NavLink>
              </li>
            </ul>

            {/* STart View Page  */}

            <Row>
              <Col xs={12}>
                <div className="new-offer">
                  <div className="controls">
                    <div
                      className={`grid ${gridView === true && "active"}`}
                      onClick={hanldeView}
                    >
                      <FontAwesomeIcon
                        icon={faThLarge}
                        className="control-icon"
                      />
                    </div>
                    <div
                      className={`list ${gridView === false && "active"}`}
                      onClick={hanldeView}
                    >
                      <FontAwesomeIcon
                        icon={faThList}
                        className="control-icon"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* STart Content Page  */}
            <Row>
              {seller && seller.length > 0 ? (
                seller.map((item) => {
                  return (
                    <Col lg={gridView && 4} xs={12} key={item.id}>
                      <div
                        className={`offers-list flx-centered ${gridView &&
                          "grid-parent"}`}
                      >
                        <div className="cont-user">
                          <div className="info">
                            <div
                              className="cont-img"
                              onClick={() => {
                                history.push(`/profile/${item.id}`);
                              }}
                            >
                              <LazyLoadImage
                                src={item.avatar ? item.avatar : null}
                                alt={item.name && item.name}
                              />
                            </div>
                            <div className="cont-txt">
                              <h2 className="author-name">
                                <NavLink to={`/profile/${item.id}`}>
                                  {item.name.length > 10
                                    ? `${item.name.slice(0, 10)}...`
                                    : item.name}{" "}
                                  {Number(item.verified) === 2 ? (
                                    <span>
                                      <AiFillCheckCircle />
                                    </span>
                                  ) : (
                                    <span className="rejectedVrf">
                                      غير موثق
                                    </span>
                                  )}
                                </NavLink>
                              </h2>
                              <div className="author-info flx-start">
                                <p className="author-books">
                                  عدد الكتب {item.user_books && item.user_books}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="rateing submit-offer">
                            <p>{`(تقييم ${item.user_rate})`}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col xs={12} lg={7} className="no-content-show mx-auto">
                  <div className="cont-txt">
                    <p>لا يوجد بيانات لعرضها</p>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      )}
    </Fragment>
  );
};

export default BestSeller;
