import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartFull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { createData } from "../../../Services/DataService";
import { getCookie } from "../../Auth/ForCookie";
import "./index.scss";
import { toast } from "react-toastify";

const Favourite = (props) => {
  const [isFavourite, setIsFavourite] = useState(props.isFavourite);

  useEffect(() => {
    props && setIsFavourite(props.isFavourite);
  }, [props]);

  const handleAddToFavorite = (e) => {
    const formData = new FormData();
    formData.append("book_id", props?.bookId);
    formData.append("user_id", getCookie("userId"));
    createData(`api/add-wishlist-book`, formData).then(
      (response) => {
        setIsFavourite("true");
        toast.success("تمت الاضافة الى المفضلة", {
          toastId: `${props?.bookId} add`,
        });
      },
      (error) => {
        setIsFavourite(false);
      }
    );
  };

  const handleRemoveFromFavorite = () => {
    const formData = new FormData();
    formData.append("book_id", props?.bookId);
    formData.append("user_id", getCookie("userId"));
    createData(`api/remove-wishlist-book`, formData).then(
      (response) => {
        setIsFavourite(false);
        toast.success("تمت الازالة من المفضلة", {
          toastId: `${props?.bookId} remove`,
        });
      },
      (error) => {
        setIsFavourite("true");
      }
    );
  };
  return (
    <>
      {isFavourite === "true" ? (
        <div className="favoruite" onClick={handleRemoveFromFavorite}>
          <FontAwesomeIcon icon={faHeartFull} />
        </div>
      ) : (
        <div className="favoruite" onClick={handleAddToFavorite}>
          <FontAwesomeIcon icon={faHeart} />
        </div>
      )}
    </>
  );
};

export default Favourite;
