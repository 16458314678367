import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Loading from "../../Components/Blocks/Loading";
import { getData } from "../../Services/DataService";
import "./index.scss";
import { Helmet } from "react-helmet";

const HowToUse = () => {
  const [questionsList, setQuestionsList] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData(`api/how-to-use`).then(
      (response) => {
        const data = response?.data?.data;
        setQuestionsList(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);
  //variable to loop on the questions and show them
  const loopOnQuestions =
    questionsList?.length > 0 ? (
      questionsList?.map((item) => {
        return (
          <AccordionItem className="custom-accrodion" key={item.id}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.question.ar}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div dangerouslySetInnerHTML={{ __html: item?.answer?.ar }}></div>
            </AccordionItemPanel>
          </AccordionItem>
        );
      })
    ) : (
      <Col xs={12} lg={7} className="no-content-show mx-auto">
        <div className="cont-txt">
          <p>لا يوجد اسئلة حاليا</p>
        </div>
      </Col>
    );
  return (
    <>
      <Helmet>
        <title>طريقة استخدام الموقع</title>
      </Helmet>
      <section className="how-to-use-page-parent">
        {/* <Sugar customLoading={loading}  color={'#00A388'} background="#87D4C7"/> */}
        {loading && <Loading />}
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">
                    {" "}
                    طريقة استخدام الموقع
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <Accordion>{loopOnQuestions}</Accordion>
        </Container>
      </section>
    </>
  );
};

export default HowToUse;
