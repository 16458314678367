import React from "react";
import { Link } from "react-router-dom";
import {
  faFacebookF,
  faInstagram,
  faSnapchatGhost,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Love from "./Love";
import Subscribe from "./Subscribe";
import "./index.scss";

const Footer = (props) => {
  return (
    <>
      <section className="Footer-parent">
        <Container>
          <Subscribe />
          <Row>
            <Col md={4}>
              <div className="aboutus flx-centered">
                <LazyLoadImage
                  src={props?.mainsettings && props?.mainsettings?.logo?.ar}
                  alt=""
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: props?.mainsettings?.content?.ar,
                  }}
                ></div>
                {/* <p>{props?.mainsettings && props.mainsettings?.content?.ar}</p> */}
                <div className="social-parent">
                  <a
                    href={
                      props?.mainsettings?.socialmedia &&
                      props?.mainsettings?.socialmedia?.facebook
                    }
                    className="social-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href={
                      props.mainsettings.socialmedia &&
                      props.mainsettings.socialmedia?.instagram
                    }
                    className="social-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href={
                      props.mainsettings.socialmedia &&
                      props.mainsettings.socialmedia?.twitter
                    }
                    className="social-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a
                    href={
                      props.mainsettings.socialmedia &&
                      props.mainsettings.socialmedia?.youtube
                    }
                    className="social-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    href={
                      props.mainsettings.socialmedia &&
                      props.mainsettings.socialmedia?.snapchat
                    }
                    className="social-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faSnapchatGhost} />
                  </a>
                  {/* <Link
                    href="/"
                    className="social-link"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faRss} />
                  </Link> */}
                </div>
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={7}>
              <Row className="footer-links-parent flx-centered">
                <ul className="footer-links">
                  <li>
                    <Link to="/terms-conditions">الشروط والأحكام</Link>{" "}
                  </li>
                  <li>
                    <Link to="/about-us">من نحن</Link>{" "}
                  </li>
                  <li>
                    <Link to="/privacy-policy"> سياسات الموقع</Link>{" "}
                  </li>
                  <li>
                    <Link to="/how-to-use">طريقة إستخدام الموقع</Link>{" "}
                  </li>
                  <li>
                    <Link to="/payment">بيانات الدفع</Link>{" "}
                  </li>
                  <li>
                    <Link to="/news">أخبار و أحداث</Link>{" "}
                  </li>
                  <li>
                    <Link to="/blogs">مدونة الموقع</Link>{" "}
                  </li>
                  <li>
                    <Link to="/best-seller">أفضل المعلنين</Link>{" "}
                  </li>
                  <li>
                    <Link to="/events">الفعاليات</Link>{" "}
                  </li>
                  {props.data === "1" ? (
                    <li>
                      <Link to="/deals"> صفقات البيع و الشراء</Link>{" "}
                    </li>
                  ) : null}
                  <li>
                    <Link to="/books/add">إضافة كتاب</Link>{" "}
                  </li>
                  <li>
                    <Link to="/contact-us">إتصل بنا</Link>{" "}
                  </li>
                </ul>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Love />
    </>
  );
};

export default Footer;
