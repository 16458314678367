import { faClock, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";
import Loading from "../../../Components/Blocks/Loading";
import { getData } from "../../../Services/DataService";
import "./EventsList.scss";
import { Helmet } from "react-helmet";

const EventsList = () => {
  const [events, setEvents] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData(`api/events`).then(
      (response) => {
        const data = response?.data?.data?.data;
        setEvents(data);
        setLoading(false);
      },
      (error) => {
        setEvents([]);
        setLoading(false);
      }
    );
  }, []);
  const history = useHistory();
  const loopOnEvnets = events.length ? (
    events.map((event) => {
      return (
        <Col xs={12} md={6} lg={4}>
          <div className="cont" key={event.id}>
            <div className="cont-img">
              <img
                src={event.img}
                alt="test"
                onClick={() => {
                  history.push(`/events/${event.id}`);
                }}
              />
            </div>
            <div className="cont-txt">
              <div className="flx-centered">
                <div className="date">
                  <FontAwesomeIcon icon={faClock} />
                  <span>{event.created_at}</span>
                </div>
              </div>
              <h2>
                <Link to={`/events/${event.id}`}>{event.title.ar}</Link>
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    event?.content?.ar?.length > 35
                      ? event?.content?.ar.slice(0, 35).concat("...")
                      : event?.content?.ar,
                }}
              ></div>
              {/* <p className="desc">{event.content.ar}</p> */}
              <Link to={`/events/${event.id}`} className="btn primary-btn">
                التفاصيل
              </Link>
            </div>
          </div>
        </Col>
      );
    })
  ) : (
    <Col xs={12}>
      <div className="errors-alert">
        <FontAwesomeIcon
          className="alert-icon"
          icon={faInfoCircle}
        ></FontAwesomeIcon>
        <span>لا يوجد فعاليات لعرضها</span>
      </div>
    </Col>
  );
  return (
    <>
      <Helmet>
        <title>الفعاليات</title>
      </Helmet>
      <section className="events-parent-page">
        {/* <Sugar customLoading={loading} color={'#00A388'} background="#87D4C7"/> */}
        {loading && <Loading />}
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item t-none">
                  <Link to="/" className="bredcrumb-link">
                    الرئيسية
                  </Link>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">الفعاليات</span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>{loopOnEvnets}</Row>
        </Container>
      </section>
    </>
  );
};

export default EventsList;
