import {
  faBars,
  faCaretLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getData } from "../../../../Services/DataService";
import "./index.scss";

const CatsBlock = (props) => {
  const [isOpenCats, setIsOpenCats] = useState(false);
  const [cats, setCats] = useState([]);
  const [activeBtn, setActiveBtn] = useState(false);

  useEffect(() => {
    getData(`api/categories`).then((response) => {
      const data = response.data.data;
      setCats(data);
    });
  }, []);
  const showSubCats = (e) => {
    let activeBtnn = activeBtn;
    var x = e.target;
    x.classList.toggle("activeLink");

    x.nextElementSibling.classList.toggle("showSubCats");
    activeBtnn = !activeBtnn;
    setActiveBtn(activeBtnn);
  };
  // loop data from api

  // toggle menu
  const showCats = () => {
    let element = document.getElementById("catsToggle");
    element.classList.toggle("showCats");
    setIsOpenCats(!isOpenCats);
  };

  return (
    <>
      <div
        className={props.isOpen ? `d-block cats-body` : `cats-body`}
        id="catsToggle"
      >
        <div class="cats-heading">
          <span className="cats-icon" onClick={() => props.buttonClick(true)}>
            <FontAwesomeIcon icon={faTimes} className="register-icon" />
          </span>
          <span>قائمة تصنيفات الكتب</span>
        </div>
        <ul>
          {props.isOpen &&
            (cats ? (
              cats.map((item) => {
                return (
                  <li key={item.id}>
                    {item.sub_category ? (
                      <p
                        onClick={showSubCats}
                        id={`item${item.id}`}
                        className="siblings_of_ul"
                      >
                        <span>{item.title.ar}</span>
                        <FontAwesomeIcon
                          icon={faCaretLeft}
                          className="register-icon"
                        />
                      </p>
                    ) : (
                      <NavLink to={item.id}>
                        <span>{item.title.ar}</span>
                      </NavLink>
                    )}

                    {item.sub_category ? (
                      <ul className="sub-cats sub-cats-parent" id="for_toggel">
                        {item.sub_category.map((subItem) => {
                          return (
                            <li key={subItem.id}>
                              <NavLink
                                to={`/books/categories/sub/${subItem.id}`}
                              >
                                <FontAwesomeIcon
                                  icon={faCaretLeft}
                                  className="register-icon"
                                />
                                <span>{subItem.title.ar}</span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                );
              })
            ) : (
              <li>
                <span>لا توجد اقسام حاليا للعرض</span>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default CatsBlock;
