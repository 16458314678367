import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { createData } from '../../../../Services/DataService';
import './index.scss';

const Subscribe = () => {
            const [subscriberEmail,setSubscriberEmail]= useState('');
            const [sucessSubscribe,setSucessSubscribe]= useState(false);
            const [errorSubscribe,setErrorSubscribe]= useState(false);
            const [subscribeErrorLog,setSubscribeErrorLog]= useState({});

         //get the data from the input and add this ti the state  
        const handleChange = (e) => {
            setSubscriberEmail(e.target.value);
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            setSubscribeErrorLog({});
            setSucessSubscribe(false);
           
            const formData = new FormData();
            formData.append("email", subscriberEmail);
            setSucessSubscribe(false);
            setErrorSubscribe(false);
            createData(`api/subscribers/add`, formData).then((response) => {
                setSucessSubscribe(true);
                    setTimeout(() => {
                        setSucessSubscribe(false);
                    }, 3000);
                },
                (error) => {
                    if (error) {
                        const errors = error.response.data.error.ar;
                        setSubscribeErrorLog(errors);
                        setErrorSubscribe(true);
                        setTimeout(() => {
                            setErrorSubscribe(false);
                        }, 3000);
                    } 
                }
            );
        }
        
        return ( 
            <>
                <section className="subscribe-sec">
                    <h2>القائمة البريدية</h2>
                    <p>يمكنك الاشراك في قائمتنا البريدية ومتابعة جديد الكتب المضافة لدينا فقط بوضع بريدك الإلكتروني في الخانة التالية ثم ضغط زر اشتراك</p> 

                    <form onSubmit={handleSubmit} className="subcribe-form" >
                        <input type="email" id="subscriberEmail" onChange={handleChange} required placeholder="ضع بريدك الإلكتروني هنا"/>
                        <button type="submit">
                            <FontAwesomeIcon className='subscribe-icon m-l-8' icon={faPaperPlane}/>
                            اشتراك
                        </button> 
                    </form>
                    {
                        errorSubscribe &&
                        <div className="errors-parent">
                            {subscribeErrorLog.length &&
                                subscribeErrorLog
                            }
                        </div>
                    }

                    <div className="succes-parent">
                        {sucessSubscribe && 
                            <span>تم الاشتراك بنجاح</span>
                        }
                    </div>
                </section>
            </>
        );
    }

export default Subscribe;