import React from "react";
import Banner from "../Home/BannerSLider/Banner";
import UncompletedUserInfo from "../Home/UncompletedUser/UncompletedUserInfo";
import VideoSec from "../Home/VideoBlock/Video";
import NewsBlock from "./BlogBlock";
import WebsiteCategories from "./Categories/Categories";
import CatsBlock from "./RecentBooks/CatsButton";
import CounterSlider from "./EventsBLock";
import FeaturedBooks from "./FeaturedBooks";
import RecommendedDeals from "./HotDeales";
import RecentBooks from "./RecentBooks";
import { Helmet } from "react-helmet";
import RecommendedBooks from "./Recommended";
import {getCookie} from "../../Components/Auth/ForCookie";

const Home = () => {
  return (
    <>
      <div className="Home">
        {!getCookie("c_at") && getCookie("loginned") ? (<UncompletedUserInfo />) : null}
        <Banner />
        <FeaturedBooks />
        <VideoSec />
        <RecommendedDeals />
        <RecentBooks />
        <RecommendedBooks />
        <WebsiteCategories />
        <CounterSlider />
        <NewsBlock />
      </div>
    </>
  );
};

export default Home;
