import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Rating from "react-rating";
import { createData } from "../../../Services/DataService";
import "./index.scss";

const RatingBlock = (props) => {
  const [responseBooks, setResponseBooks] = useState();
  const [responseUsers, setResponseUsers] = useState();
  const [bookRate, setBookRate] = useState();

  // useEffect(() => {
  //     props.getBooksRate(bookRate);
  // },[bookRate])

  const handleRating = (e) => {
    setBookRate(e);
    const formData = new FormData();
    formData.append("rate", e);
    formData.append("book_id", props?.bookId);
    createData(`api/rate-book`, formData).then(
      (response) => {
        setResponseBooks("تم التقييم");
      },
      (error) => {
        setResponseBooks("حدث خطا ما");
      }
    );
  };

  const handleRatingUser = (e) => {
    const formData = new FormData();
    formData.append("rate", e);
    formData.append("user_id", props?.userId);
    createData(`api/rate-user`, formData).then(
      (response) => {
        setResponseUsers("تم التقييم");
      },
      (error) => {
        setResponseUsers("حدث خطا ما");
      }
    );
  };

  return (
    <>
      {props.type === "book" ? (
        responseBooks ? (
          <div> {responseBooks}</div>
        ) : (
          <div className="rating-form">
            <label>{props.readonly ? "تم التقييم" : "قيم هذا الكتاب : "}</label>
            <Rating
              readonly={props.readonly}
              initialRating={props.initialRating}
              onChange={handleRating}
              className="custom-rating-parent"
              emptySymbol={
                <FontAwesomeIcon
                  icon={faStar}
                  className="before-rating"
                  size="1x"
                />
              }
              fullSymbol={
                <FontAwesomeIcon
                  icon={faStar}
                  className="after-rating"
                  size="1x"
                />
              }
            />
          </div>
        )
      ) : responseUsers ? (
        <div> {responseUsers}</div>
      ) : (
        <div className="rating-form">
          <label>{props.readonly ? "تم التقييم" : "قيم هذا المستخدم : "}</label>
          <Rating
            readonly={props.readonly}
            initialRating={props.initialRating}
            onChange={handleRatingUser}
            className="custom-rating-parent"
            emptySymbol={
              <FontAwesomeIcon
                icon={faStar}
                className="before-rating"
                size="1x"
              />
            }
            fullSymbol={
              <FontAwesomeIcon
                icon={faStar}
                className="after-rating"
                size="1x"
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default RatingBlock;
