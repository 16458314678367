import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../config";
// import aboutImg from "../../../Assets/Img/about-us.png";
import { Helmet } from "react-helmet";
import Loading from "../../Components/Blocks/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.scss";
import ErrorPage from "../NotFound/404Page";
class Terms extends React.Component {
  state = {
    Loading: true,
    pageData: {},
    pageSlug: "",
    pageImage: "",
    pageContent: "",
    error: false,
  };

  async componentDidMount() {
    await axios.get(`${BaseUrl}/api/terms-conditions`).then(
      (response) => {
        const pageData = response.data.data;
        this.setState({ Loading: false });
        this.setState({ pageData });
        this.setState({ pageSlug: response.data.data.slug.ar });
        this.setState({ pageImage: response.data.data.image });
        this.setState({ pageContent: response.data.data.content.ar });
      },
      (error) => {
        this.setState({ error: true });
      }
    );
  }

  render() {
    if (this.state.error === true) return <ErrorPage />;
    return (
      <>
        <Helmet>
          <title>{this.state.pageData?.title?.ar}</title>
        </Helmet>
        {this.state.Loading === true ? (
          <Loading />
        ) : (
          <section className="pricavy-page-parent">
            <Container>
              <Row>
                <Col xs={12}>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/" className="bredcrumb-link">
                        الرئيسية
                      </NavLink>
                    </li>

                    <li className="breadcrumb-item">
                      <span className="mob-bredcrumb active">
                        الشروط والأحكام
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                {this.state.pageData ? (
                  <Col xs={12}>
                    <div className="cont">
                      <div className="cont-title">
                        <h2>
                          {this.state.pageSlug.length > 0
                            ? this.state.pageSlug
                            : `تعرف  علي سواني للكتاب`}
                        </h2>
                      </div>
                      <div className="cont-img">
                        <LazyLoadImage
                          src={
                            // this.state.pageImage ? this.state.pageImage : aboutImg
                            this.state.pageImage && this.state.pageImage
                          }
                          alt=""
                        />
                      </div>
                      <div className="cont-txt">
                        {this.state.pageContent.length ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.pageContent,
                            }}
                          ></p>
                        ) : (
                          <p>حدث خطا ما يرجي اعادة تحميل الصفحة</p>
                        )}
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </Container>
          </section>
        )}
      </>
    );
  }
}

export default Terms;
