import React from 'react';
import ContentLoader from 'react-content-loader';
 
const LoadingIndicator = () => {

    return(
        <>
         {
            window.innerWidth > 768 ? (
                <ContentLoader viewBox="0 0 400 400" backgroundColor="#f0f0f0" foregroundColor="#dedede">
                    <rect x="0" y="0" rx="5" ry="5" width="95" height="120" />
                    <rect x="100" y="0" rx="5" ry="5" width="95" height="120" />
                    <rect x="200" y="0" rx="5" ry="5" width="95" height="120" />
                    <rect x="300" y="0" rx="5" ry="5" width="95" height="120" />
                </ContentLoader>
            ) : 
            (
                <ContentLoader viewBox="0 0 400 400" backgroundColor="#f0f0f0" foregroundColor="#dedede">
                    <rect x="35" y="0" rx="35" ry="5" width="330" height="500" />
                </ContentLoader>
            )
        }
        
        </>
       
       
    )
    
};
 
export default LoadingIndicator;