import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Loading from "../../Components/Blocks/Loading";
import { getData } from "../../Services/DataService";
import "./PaymentInfo.scss";
import { Helmet } from "react-helmet";
import useScrollToTop from "../../hooks/useScrollToTop";

const PaymentInformation = () => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);
  const [account_name, setAccount_name] = useState("");
  const [account_number, setAccount_number] = useState("");
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    getData(`api/payment-data`).then(
      (response) => {
        const data = response.data.data;
        setLoading(false);
        setPageData(data);
        setAccount_name(data.account_name);
        setAccount_number(data.account_number);
        setPercentage(data.percentage);
      },
      (error) => {
        setPageData([]);
        setLoading(false);
      }
    );
  }, []);
  useScrollToTop();
  return (
    <>
      <Helmet>
        <title>بيانات الدفع</title>
      </Helmet>
      <section className="payment-info-page">
        {/* <Sugar customLoading={loading}  color={'#00A388'} background="#87D4C7"/> */}
        {loading && <Loading />}
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active"> بيانات الدفع</span>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            {pageData ? (
              <Col xs={12}>
                <div className="cont">
                  <div className="cont-title">
                    <h2>بيانات الدفع</h2>
                  </div>
                  <div className="cont-img">
                    <img src={pageData?.image} alt="" />
                  </div>
                  <div className="cont-txt">
                    <div className="txt d-flex align-items-center">
                      <p className="cont-p">اسم الحساب</p>
                      <p>{account_name && account_name}</p>
                    </div>

                    <div className="txt d-flex align-items-center">
                      <p className="cont-p">رقم الحساب</p>
                      <p>{account_number && account_number}</p>
                    </div>

                    <div className="txt d-flex align-items-center">
                      <p className="cont-p">النسبة المئوية</p>
                      <p>{percentage && percentage}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PaymentInformation;
