import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../config";
import { getCookie } from "../../../Components/Auth/ForCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { useHistory } from "react-router-dom";

function SendMessage(props) {
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [messageSubject, setMessageSubject] = useState("0");
  const [successSend, setSuccessSend] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});
  const history = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "messageTitle") {
      setMessageTitle(value);
    } else if (id === "messageContent") {
      setMessageContent(value);
    }
  };

  const handleChangeIdNumber = (e) => {
    setMessageSubject(e.target[e.target.selectedIndex].id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorsLog({});
    const formData = new FormData();
    formData.append("receive", props.recevierId);
    formData.append("title", messageTitle);
    formData.append("subject", messageSubject);
    formData.append("message", messageContent);

    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    };

    axios.post(`${BaseUrl}/api/make-chats`, formData, { headers }).then(
      (response) => {
        setSuccessSend(true);
        setTimeout(() => {
          setSuccessSend(false);
        }, 3000);
      },
      (error) => {
        if (error) {
          console.log(error.response.data);
          if (error?.response?.data?.chat_id) {
            history.push(
              `/messages?tab=General&chat=${error?.response?.data?.chat_id}`
            );
          } else {
            const errors = error.response.data;
            setErrorsLog(errors);
          }
        }
      }
    );
  };

  return (
    <>
      {successSend && (
        <div className="success-alert">
          <FontAwesomeIcon
            className="alert-icon"
            icon={faCheckCircle}
          ></FontAwesomeIcon>
          <span>شكرا لقد تم ارسال رسالتك بنجاح</span>
        </div>
      )}
      {errorsLog && errorsLog.data ? (
        <div className="errors-alert">
          <FontAwesomeIcon
            className="alert-icon"
            icon={faInfoCircle}
          ></FontAwesomeIcon>
          <span>{errorsLog.data}</span>
        </div>
      ) : null}
      <Form className="FormParent" onSubmit={handleSubmit}>
        <Form.Group
          className={`${errorsLog && errorsLog.title ? "has-error" : null}`}
        >
          <Form.Label>عنوان الرسالة</Form.Label>
          <Form.Control
            id="messageTitle"
            onChange={handleChange}
            type="text"
            value={messageTitle}
            placeholder="اكتب عنوان الرسالة هنا"
          />
          {errorsLog && errorsLog.title ? (
            <div className="errors-parent">
              <span className="error-txt">{errorsLog.title.ar}</span>
            </div>
          ) : null}
        </Form.Group>

        <Form.Group
          className={`${errorsLog && errorsLog.subject ? "has-error" : null}`}
        >
          <Form.Label>موضوع الرسالة</Form.Label>
          <Form.Control
            as="select"
            className={`form-select ${
              errorsLog && errorsLog.subject ? "has-error" : null
            }`}
            id="messageSubject"
            onChange={handleChangeIdNumber}
          >
            <option id="0">مراسلة عامة</option>
            {/* <option id="1">البيع والشراء</option>
                        <option id="2">عروض الصفقات</option> */}
          </Form.Control>
          {errorsLog && errorsLog.subject ? (
            <div className="errors-parent">
              <span className="error-txt">{errorsLog.subject.ar}</span>
            </div>
          ) : null}
        </Form.Group>

        <Form.Group
          className={`${errorsLog && errorsLog.message ? "has-error" : null}`}
        >
          <Form.Label>تفاصيل الرسالة</Form.Label>
          <Form.Control
            id="messageContent"
            as="textarea"
            onChange={handleChange}
            placeholder="اكتب تفاصيل الرسالة هنا"
          />
          {errorsLog && errorsLog.message ? (
            <div className="errors-parent">
              <span className="error-txt">{errorsLog.message.ar}</span>
            </div>
          ) : null}
        </Form.Group>
        <Button type="submit" className="btn primary-btn">
          {" "}
          إرسال
        </Button>
      </Form>
    </>
  );
}

export default SendMessage;
