import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Col, Container, Form, Row } from "react-bootstrap";
import { isBrowser } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import Favourite from "../../../Components/Blocks/Favourite";
import Loading from "../../../Components/Blocks/Loading";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import useScrollToTop from "../../../hooks/useScrollToTop";

const CategoryDetailed = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [activeBtn, setActiveBtn] = useState(false);
  const [bookCity, setBookCity] = useState("");
  const [allLanguages, setAllLanguages] = useState([]);
  const [filterBookPriceMin, setFilterBookPriceMin] = useState("");
  const [filterBookPriceMax, setFilterBookPriceMax] = useState("");
  const [filterBookCity, setFilterBookCity] = useState("");
  const [filterBookStatus, setFilterBookStatus] = useState("");
  const [filterBookLanguage, setFilterBookLanguage] = useState("1");
  const [filterUrlAttr, setFilterUrlAttr] = useState("");

  const [currentPage, setCurrentPage] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [prevPage, setPrevPage] = useState(true);
  const [LastPage, setLastPage] = useState(true);
  const [totalPages, setTotalPages] = useState(true);
  const [currentLink, setCurrentLink] = useState(true);
  const [last_page, setLast_page] = useState(true);
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  useScrollToTop();
  useEffect(() => {
    //request to get the books of category
    getData(`api/categories/books/${props.match.params.id}`).then(
      (response) => {
        const data = response.data.data;
        setCategoryData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setCategoryData([]);
        // window.location.href = '/404';
      }
    );
    getData(`api/all-languages`).then(
      (response) => {
        const allLanguages = response.data.data;
        setAllLanguages(allLanguages);
        if (allLanguages.length > 0) {
          setFilterBookLanguage(allLanguages[0].id);
        }
      },
      (error) => {
        setAllLanguages([]);
      }
    );
  }, [props]);
  // handle change in content according to pagination
  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const data = response.data.data;
        setCategoryData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setCategoryData([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    // console.log("filterUrlAttr",filterUrlAttr);
    let pageNumber = id;
    fillData(`${filterUrlAttr || currentLink}?page=${pageNumber}`);
  };

  // handle change of user type in radio select option
  //   const handleChangeRadio = (e) => {
  //     setState({
  //       [e.target.name]: e.target.id,
  //     });
  //   };

  //get the input from the select element

  const handleChangeIdNumber = (e) => {
    setFilterBookStatus(e.target[e.target.selectedIndex].id);
  };

  // make inut acceopts numbers only
  //   const handleChangeNumbers = (e) => {
  //     const theVal = e.target.value.replace(/[^ 0-9]/gi, "");
  //     setState({ [e.target.id]: theVal });
  //   };

  const handleChange = (e) => {
    setFilterBookCity(e.target.value);
  };

  const handleChangeLanguage = (e) => {
    setFilterBookLanguage(e.target.value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const filterUrl = `api/filter-book?min_price=${
      filterBookPriceMin.length > 0 ? filterBookPriceMin : ""
    }&max_price=${
      filterBookPriceMax.length > 0 ? filterBookPriceMax : ""
    }&buy_city=${filterBookCity.length > 0 ? filterBookCity : ""}&language=${
      filterBookLanguage.length > 0 ? filterBookLanguage : ""
    }&used=${filterBookStatus.length > 0 ? filterBookStatus : ""}`;
    setFilterUrlAttr(filterUrl);

    fillData(filterUrl);
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;

  const settings_imgs = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };

  const loopOnBooks = categoryData.length ? (
    categoryData.map((book) => {
      return (
        <Col xs={12} lg={4} key={book.id} className="book-cont">
          <div className="cont">
            <div className="cont-img parent-imaes-slider">
              <Slider {...settings_imgs}>
                {book.images.map((img) => {
                  return (
                    <NavLink to={`/books/${book?.id}`}>
                      <LazyLoadImage
                        key={Math.random()}
                        src={img}
                        alt="bookImg"
                      />
                    </NavLink>
                  );
                })}
              </Slider>
            </div>
            <div className="cont-txt">
              <div className="cat-parent">
                <span className="cat-name">{book.main_category.title.ar}</span>

                <div className="rating">
                  <span>{book.rates}</span>
                  <FontAwesomeIcon icon={faStar} className="register-icon" />
                </div>
              </div>
              <h3>
                <NavLink to={`/books/${book?.id}`}>
                  {book.title.ar.length > 20 ? (
                    <>{book.title.ar.slice(0, 20).concat("...")}</>
                  ) : (
                    <>{book.title.ar}</>
                  )}
                </NavLink>
                <Favourite
                  bookId={book.id}
                  isFavourite={`${book?.check_wishlist === 1 ? true : false}`}
                />
              </h3>

              <div className="price-info flx-centered">
                <p className="price">
                  <span>{book?.price}</span>ريال سعودي
                </p>
              </div>
            </div>
          </div>
        </Col>
      );
    })
  ) : (
    <Col xs={12} lg={7} className="no-content-show mx-auto">
      <div className="cont-txt">
        <p>
          عذراً لا يوجد لديك كتب معروضة للبيع
          <br />
          يمكنك عرض كتابك للبيع عن طريق الضغط على زر إضافة كتاب
        </p>
        <NavLink to="/books/add" className="red-btn">
          <FontAwesomeIcon icon={faPlus} className="plus-icon" />
          إضافة كتاب
        </NavLink>
      </div>
    </Col>
  );

  const loopOnLanguages =
    allLanguages.length > 0
      ? allLanguages.map((item) => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        })
      : null;
  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item t-none">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item t-none">
                  <NavLink to="/books/categories" className="mob-bredcrumb ">
                    تصنيفات الكتب
                  </NavLink>
                </li>

                {categoryData.length > 0 ? (
                  <li className="breadcrumb-item">
                    <span className="mob-bredcrumb active">
                      {categoryData.length > 0
                        ? categoryData[0].main_category.title.ar
                        : null}
                    </span>
                  </li>
                ) : null}

                {categoryData.length > 0 && props.match.params.sub == "sub" ? (
                  <li className="breadcrumb-item">
                    <span className="mob-bredcrumb active">
                      {categoryData.length > 0
                        ? categoryData[0].sub_category.title.ar
                        : null}
                    </span>
                  </li>
                ) : null}
              </ul>
            </Col>
          </Row>

          {categoryData.length ? (
            <Row>
              {isBrowser ? (
                <Col xs={12} lg={3}>
                  <div className="filter-parent">
                    <div className="filter-heading">
                      <h2>تصفية حسب</h2>
                    </div>
                    <Form
                      onSubmit={handleSubmitFilter}
                      className="filter-parent-form FormParent"
                    >
                      <div className="filter-body">
                        <Accordion allowMultipleExpanded>
                          <AccordionItem className="custom-accrodion">
                            <AccordionItemHeading>
                              <AccordionItemButton>السعر</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <Form.Group>
                                <Form.Label>حدد السعر</Form.Label>
                                <div className="d-flex">
                                  <Form.Control
                                    className="mb-3 mx-2"
                                    id="filterBookPriceMin"
                                    value={filterBookPriceMin}
                                    onChange={(e) =>
                                      setFilterBookPriceMin(
                                        e.target.value.replace(/[^ 0-9]/gi, "")
                                      )
                                    }
                                    type="text"
                                    placeholder="السعر من"
                                  />
                                  <Form.Control
                                    className="mb-3 mx-2"
                                    id="filterBookPriceMax"
                                    value={filterBookPriceMax}
                                    onChange={(e) =>
                                      setFilterBookPriceMax(
                                        e.target.value.replace(/[^ 0-9]/gi, "")
                                      )
                                    }
                                    type="text"
                                    placeholder="السعر الي"
                                  />
                                </div>
                              </Form.Group>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem className="custom-accrodion">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                مناطق بيع الكتاب
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <Form.Group>
                                <Form.Label>اختار مدينة</Form.Label>
                                <Form.Control
                                  id="filterBookCity"
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="مدينة"
                                />
                              </Form.Group>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem className="custom-accrodion">
                            <AccordionItemHeading>
                              <AccordionItemButton>الحالة</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <Form.Group>
                                <Form.Label>اختار حالة الكتاب</Form.Label>
                                <Form.Control
                                  as="select"
                                  className={`form-select`}
                                  id="filterBookStatus"
                                  onChange={handleChangeIdNumber}
                                >
                                  <option selected>اختار حالة الكتاب</option>
                                  <option id="0">مستعمل</option>
                                  <option id="1">جديد</option>
                                </Form.Control>
                              </Form.Group>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem className="custom-accrodion">
                            <AccordionItemHeading>
                              <AccordionItemButton>اللغة</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <Form.Group>
                                <Form.Label>اختار لغة الكتاب</Form.Label>
                                <select
                                  id="filterBookLanguage"
                                  onChange={handleChangeLanguage}
                                >
                                  {loopOnLanguages}
                                </select>
                              </Form.Group>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>

                      <button
                        type="submit"
                        className="btn primary-btn justify-content-center"
                      >
                        تطبيق
                      </button>
                    </Form>
                  </div>
                </Col>
              ) : null}
              <Col xs={12} lg={9}>
                <Row className="books-parent">{loopOnBooks}</Row>
              </Col>

              <Col xs={12} lg={9} className="pagination-parent-books">
                {parseInt(totalPages) > 1 ? (
                  <ul className="pagination-parent flx-centered">
                    <li className="first-page">
                      <button
                        onClick={handlePrevPage}
                        className={
                          currentPage == "1" ? `disabled-btn` : `arrow-btn`
                        }
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                    </li>

                    {currentPage != 1 && currentPage != 2 && (
                      <li
                        className="test-last"
                        onClick={() => handleGoToPage(1)}
                      >
                        <button>1</button>
                      </li>
                    )}
                    {currentPage != 1 && currentPage != 2 && (
                      <li className="to-continue">...</li>
                    )}

                    {pages}

                    {totalPages != currentPage &&
                      currentPage != Number(totalPages) - 1 && (
                        <li className="to-continue">...</li>
                      )}
                    {totalPages != currentPage &&
                      currentPage != Number(totalPages) - 1 && (
                        <li
                          className="test-last"
                          onClick={() => handleGoToPage(totalPages)}
                        >
                          <button>{totalPages}</button>
                        </li>
                      )}

                    {/* {currentPage != } */}
                    <li className="last-page">
                      <button
                        onClick={handleNextPage}
                        className={
                          currentPage == totalPages
                            ? `disabled-btn`
                            : `arrow-btn`
                        }
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button>
                    </li>
                  </ul>
                ) : null}
              </Col>
            </Row>
          ) : (
            <Col xs={12} lg={7} className="no-content-show mx-auto">
              <div className="cont-txt">
                <p>
                  عذراً لا يوجد لديك كتب معروضة للبيع
                  <br />
                  يمكنك عرض كتابك للبيع عن طريق الضغط على زر إضافة كتاب
                </p>
                <NavLink to="/books/add" className="red-btn">
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                  إضافة كتاب
                </NavLink>
              </div>
            </Col>
          )}
        </Container>
      )}
    </>
  );
};

export default CategoryDetailed;
