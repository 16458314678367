import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./NewsCats.scss";
import { NavLink } from "react-router-dom";
import BaseUrl from "../../../config";
import { useState } from "react";
import { useEffect } from "react";
import { getData } from "../../../Services/DataService";

const NewsCatsBlock = () => {
  const [cats, setCats] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(`api/blog/category`).then(
      (response) => {
        const data = Object.values(response.data.data);
        setCats(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  // loop data from api
  const Cats =
    cats.length > 0 ? (
      cats.map((item, index) => {
        return (
          <li key={index}>
            <NavLink to={`/news/category/${item?.id}`}>
              <span>{item.ar}</span>
              <FontAwesomeIcon icon={faCaretLeft} className="register-icon" />
            </NavLink>
          </li>
        );
      })
    ) : (
      <li>
        <span>لا توجد اقسام حاليا للعرض</span>
      </li>
    );

  return (
    <>
      <div className="news-cats-parent">
        <div className="cats-heading">
          <h2>اقسام الاخبار</h2>
        </div>
        <div className="cats-body">
          <ul>{Cats}</ul>
        </div>
      </div>
    </>
  );
};

export default NewsCatsBlock;
