/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faEllipsisV,
  faExclamationTriangle,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../config";
// import { Sugar } from 'react-preloaders';
import { getCookie } from "../../../Components/Auth/ForCookie";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createData, getData } from "../../../Services/DataService";
import "./index.scss";
const AuthorFollowingUsers = (props) => {
  const [usersData, setusersData] = useState([]);
  const [sucessunfollow, setSucessunfollow] = useState(false);
  const [errorExsist, setErrorExsist] = useState(false);
  const [sucessExsist, setSucessExsist] = useState(false);
  const [currentPage, setCurrentPage] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [prevPage, setPrevPage] = useState(true);
  const [LastPage, setLastPage] = useState(true);
  const [totalPages, setTotalPages] = useState(true);
  const [currentLink, setCurrentLink] = useState(true);
  const [last_page, setLast_page] = useState(true);
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorLogObj, setErrorLogObj] = useState("");

  useEffect(() => {
    //request to get the books of this category
    getData(`api/user-following/${props.userId}`).then(
      (response) => {
        const data = response.data.data;
        setusersData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setusersData([]);
        setLoading(false);
      }
    );
  }, [props]);

  // handle change in content according to pagination
  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const data = response.data.data;
        setusersData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setusersData([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const handleShowErrorExsist = (e) => {
    setErrorExsist(!errorExsist);
  };
  const handleShowSucessExsist = (e) => {
    setSucessExsist(!sucessExsist);
  };

  const handleUnFollowUsers = (e) => {
    const formData = new FormData();
    formData.append("user_id", props.userId);
    formData.append("follow_id", e);
    createData(`api/user-unfollow`, formData).then(
      (response) => {
        setSucessExsist(true);
        getData(`api/user-following/${props.userId}`).then(
          (response) => {
            const data = response.data.data;
            setusersData(data.data);
            setCurrentPage(data.current_page);
            setNextPage(data.next_page_url);
            setPrevPage(data.prev_page_url);
            setLastPage(data.last_page_url);
            setTotalPages(data.last_page);
            setCurrentLink(data.path);
            setLast_page(data.last_page);
            setPages(response.data.pages);
            setLoading(false);
          },
          (error) => {
            setusersData([]);
            setLoading(false);
          }
        );
      },
      (error) => {
        const errorLogObj = error.response.data.data;
        setErrorLogObj(errorLogObj);
        setErrorExsist(true);
      }
    );
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;
  const loopOnUsers = usersData.length ? (
    usersData.map((user) => {
      return (
        <Col xs={12} lg={3} key={user.id} className="user-slide-cont">
          <div className="info">
            {/* <div className="follow-btn-parent">
              <div
                className="dropdown-controller"
                onClick={() => {
                  setUnfollowList(!unfollowList);
                }}
              >
                hi
              </div>
              {unfollowList && (
                <div className="dropdown-list">
                  
                </div>
              )}
            </div> */}

            <div className="cont-img">
              <LazyLoadImage src={user.avatar} alt={user.avatar} />
            </div>
            <div className="cont-txt">
              <h2 className="author-name">
                <a href={`/profile/${user.id}`}>{user.name}</a>
              </h2>
              <div className="d-flex">
                <div className="author-info flx-centered">
                  <span className="author-type">{user.roles}</span>
                </div>
                {props.userId == getCookie("userId") && (
                  <div
                    className="red-btn unfollow-btn"
                    onClick={() => {
                      handleUnFollowUsers(user?.id);
                    }}
                  >
                    الغاء المتابعه
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      );
    })
  ) : (
    <Col xs={12} lg={7} className="no-users-show mx-auto">
      <div className="cont-txt">
        <p>لم تقم بمتابعة أي حساب حتي الأن</p>
      </div>
    </Col>
  );

  return (
    <>
      {/* <Sugar customLoading={Loading} color={'#00A388'} background="#87D4C7"/> */}

      <Row className="author-following-parent">{loopOnUsers}</Row>
      <Row>
        <Col xs={12} className="pagination-parent-books">
          {parseInt(totalPages) > 1 ? (
            <ul className="pagination-parent flx-centered">
              <li className="first-page">
                <button
                  onClick={handlePrevPage}
                  className={currentPage == "1" ? `disabled-btn` : `arrow-btn`}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </li>

              {currentPage != 1 && currentPage != 2 && (
                <li className="test-last" onClick={() => handleGoToPage(1)}>
                  <button>1</button>
                </li>
              )}
              {currentPage != 1 && currentPage != 2 && (
                <li className="to-continue">...</li>
              )}

              {pages}

              {totalPages != currentPage &&
                currentPage != Number(totalPages) - 1 && (
                  <li className="to-continue">...</li>
                )}
              {totalPages != currentPage &&
                currentPage != Number(totalPages) - 1 && (
                  <li
                    className="test-last"
                    onClick={() => handleGoToPage(totalPages)}
                  >
                    <button>{totalPages}</button>
                  </li>
                )}

              {/* {currentPage != } */}
              <li className="last-page">
                <button
                  onClick={handleNextPage}
                  className={
                    currentPage == totalPages ? `disabled-btn` : `arrow-btn`
                  }
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </li>
            </ul>
          ) : null}
        </Col>
      </Row>

      {sucessExsist ? (
        <div className="alret-parent">
          <div className="close-alert" onClick={handleShowSucessExsist}></div>
          <div className="cont">
            <div className="cont-txt">
              <div className="heading error">
                <FontAwesomeIcon
                  className="alert-icon sucess"
                  icon={faCheckDouble}
                ></FontAwesomeIcon>
                <h2>تم إلغاء الإشتراك بنجاح</h2>
              </div>
            </div>
            <div className="controls">
              <Button
                className="btn primary-btn"
                onClick={handleShowSucessExsist}
              >
                اغلاق
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {errorExsist ? (
        <div className="alret-parent">
          <div className="close-alert" onClick={handleShowErrorExsist}></div>
          <div className="cont">
            <div className="cont-txt">
              <div className="heading error">
                <FontAwesomeIcon
                  className="alert-icon error"
                  icon={faExclamationTriangle}
                ></FontAwesomeIcon>
                <h2>حدث خطا ما</h2>
              </div>
              <div className="errors-parent">
                <p>{errorLogObj}</p>
              </div>
            </div>
            <div className="controls">
              <Button
                className="btn primary-btn"
                onClick={handleShowErrorExsist}
              >
                اغلاق
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AuthorFollowingUsers;
