import React, { useState, useEffect } from "react";
import "./VerifyPage.scss";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../Components/Blocks/Loading";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

const VerifyEmail = () => {
  const { token, email } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const handleVerify = async () => {
    try {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/verify-user-email/${token}/${email}`
        )
        .then((res) => {
          setData(res.data);
        });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleVerify();
  }, []);
  if (loading) return <Loading />;
  if (data && data.status === true)
    return (
      <>
        <section className="error-page-paret">
          <Container>
            <Col className="cont flx-centered">
              <div className="sucess-alert">
                <FontAwesomeIcon
                  className="alert-icon"
                  icon={faCheckCircle}
                ></FontAwesomeIcon>
                <span>شكرا لقد تم تسجيلك بنجاح و سيتم تحويلك لصفحة الدخول</span>
              </div>
            </Col>
            <Col className="cont flx-centered">
              <NavLink to="/" className="primary-btn-rgba btn">
                تصفح الموقع
              </NavLink>
            </Col>
          </Container>
        </section>
      </>
    );

  return (
    <>
      <section className="error-page-parent">
        <Container>
          <Row>
            <Col className="cont flx-centered">
              <div className="errors-alert">
                <FontAwesomeIcon
                  className="alert-icon"
                  icon={faInfoCircle}
                ></FontAwesomeIcon>
                <span>حدث خطأ في تفعيل حسابك حاول مجددا في وقت لاحق!</span>{" "}
              </div>
              <NavLink to="/" className="primary-btn-rgba btn">
                برجاء العودة للصفحة الرئيسية
              </NavLink>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default VerifyEmail;
