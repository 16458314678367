import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import BookCard from "../../../Components/Blocks/BookCard";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import CatsBlock from "./CatsBlock";
import CatsButton from "./CatsButton";

const RecentBooks = (props) => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const buttonClick = (buttonClick) => {
    buttonClick && setIsOpen(!isOpen);
  };

  useEffect(() => {
    getData(`api/latestbooks`).then(
      (response) => {
        // main_category
        const books = response.data.data.data;
        setBooks(books);
        setLoading(false);
      },
      (error) => {
        setBooks([]);
      }
    );
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      Number(books.length) >= Number(`${isOpen ? 3 : 4}`)
        ? Number(`${isOpen ? 3 : 4}`)
        : 1,
    arrows: true,
    rtl: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Number(books.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 922,
        settings: {
          slidesToShow: Number(books.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: false,
      //   },
      // },
      // {
      //   breakpoint: 580,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: false,
      //   },
      // },
    ],
  };
  const settings_imgs = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };

  const settingsMin = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Number(books.length) >= 3 ? 3 : 1,
    slidesToScroll: 1,
    arrows: true,
    rtl: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Number(books.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 922,
        settings: {
          slidesToShow: Number(books.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="recent-books-sec">
        <Container>
          <Row>
            <Col md={3}>
              <div className="cats-parent">
                <CatsButton buttonClick={buttonClick} />
                <CatsBlock isOpen={isOpen} buttonClick={buttonClick} />
              </div>
            </Col>
            <Col md={isOpen ? 9 : 12}>
              <div className="widget-title">
                <h2>المضاف حديثا</h2>
                <div className="all-books">
                  <NavLink to="/books" className="all-btn">
                    جميع الكتب
                  </NavLink>
                </div>
              </div>
              <BookCard
                data={books}
                settings={settings}
                settings_2={settings_imgs}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RecentBooks;
