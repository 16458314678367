import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { createData } from "../../../Services/DataService.jsx";
import "./index.scss";

const ResetPassword = () => {
  const [errorLog, setErrorLog] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [errosObj, setErrosObj] = useState("");
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const { token } = useParams();
  const history = useHistory();
  console.log(token);

  const handleSubmitFirstStep = (e) => {
    e.preventDefault();
    setShowError(false);
    setErrosObj("");
    setErrorData("");
    setErrorLog({});
    const formData = new FormData();
    formData.append("password", password);
    formData.append("password_confirmation", rePassword);
    formData.append("token", token);
    createData(`api/submit-forget-password-form`, formData).then(
      (response) => {
        if (response.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        }
      },
      (error) => {
        if (error) {
          setErrorLog(error.response.data.error);
          setShowError(true);
          setErrosObj(error.response.data.errors);
          setErrorData(error.response.data.data);
        } else {
          setErrorLog(null);
        }
      }
    );
  };
  return (
    <>
      <section className="login-sec">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="form-heading">
                <h1>مرحبا بعودتك !</h1>
                <p>تسجيل الدخول الى حسابك</p>
              </div>
            </Col>

            <Col md={6} xs={12} className="mx-auto">
              <Form className="loginForm" onSubmit={handleSubmitFirstStep}>
                {showError && (
                  <div className="errors-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faInfoCircle}
                    ></FontAwesomeIcon>
                    <span>
                      {errorLog || errorData || "حدث خطأ ما..حاول مجددا !"}
                    </span>
                  </div>
                )}
                {success && (
                  <div className="sucess-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faCheckCircle}
                    ></FontAwesomeIcon>
                    <span>
                      لقد تم تغيير كلمة السر بنجاح سيتم تحويلك لتسجيل الدخول
                    </span>
                  </div>
                )}
                <Form.Group
                  className={`${
                    errosObj && errosObj.password ? "has-error" : null
                  }`}
                >
                  <Form.Label>كلمة المرور</Form.Label>
                  <Form.Control
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="على الأقل 8 ارقام و حروف"
                    required
                  />
                  {errosObj && errosObj.password ? (
                    <div className="errors-parent">
                      <span className="error-txt">{errosObj?.password}</span>
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  className={`${
                    errosObj && errosObj.email ? "has-error" : null
                  }`}
                >
                  <Form.Label>تاكيد كلمة المرور الجديده</Form.Label>
                  <Form.Control
                    type="password"
                    id="rePassword"
                    onChange={(e) => setRePassword(e.target.value)}
                    placeholder="تأكيد كلمة المرور الجديدة"
                    required
                  />
                  {errosObj && errosObj.email ? (
                    <div className="errors-parent">
                      <span className="error-txt">
                        {errosObj?.confirmation_password}
                      </span>
                    </div>
                  ) : null}
                </Form.Group>

                <Button
                  className="btn all-btn login-btn flx-centered"
                  type="submit"
                >
                  تاكيد كلمة المرور الجديدة
                </Button>

                <Form.Text className="text-muted">
                  <span>ليس لديك عضوية ؟ برجاء التوجة الي صفحة</span>
                  <Link to="/register">التسجيل</Link>
                </Form.Text>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
