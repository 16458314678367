/* eslint-disable eqeqeq */
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import Loading from "../../../Components/Blocks/Loading";
import { getData } from "../../../Services/DataService";
import NewsCatsBlock from "../NewsCats/NewsCats";
import "./index.scss";
import { Helmet } from "react-helmet";
import useScrollToTop from "../../../hooks/useScrollToTop";

const BlogsNewsList = (props) => {
  const [bLogNews, setBLogNews] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [LastPage, setLastPage] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [currentLink, setCurrentLink] = useState("");
  const [last_page, setLast_page] = useState("");
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    getData(`api/${props.location.pathname.slice(1)}`).then(
      (response) => {
        const data = response.data.data.data;
        setBLogNews(data);
        setCurrentPage(response.data.data.current_page);
        setNextPage(response.data.data.next_page_url);
        setPrevPage(response.data.data.prev_page_url);
        setLastPage(response.data.data.last_page_url);
        setTotalPages(response.data.data.last_page);
        setCurrentLink(response.data.data.path);
        setLast_page(response.data.data.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setBLogNews([]);
        setLoading(false);
      }
    );
    setPageName(props.location.pathname.slice(1));
  }, [props]);

  useEffect(() => {
    setLoading(true);
  }, [pageName]);
  useScrollToTop();

  // handle change in content according to pagination
  const fillData = (url) => {
    axios.get(url).then(
      (response) => {
        const data = response.data.data.data;
        setBLogNews(data);
        setCurrentPage(response.data.data.current_page);
        setNextPage(response.data.data.next_page_url);
        setPrevPage(response.data.data.prev_page_url);
        setLastPage(response.data.data.last_page_url);
        setTotalPages(response.data.data.last_page);
        setCurrentLink(response.data.data.path);
        setLast_page(response.data.data.last_page);
        setPages(response.data.pages);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setBLogNews([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions
  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;
  const loopOnNews = bLogNews.length ? (
    bLogNews.map((blogs) => {
      return (
        <Row className="cont" key={blogs.id}>
          <Col xs={12} xl={5} className="cont-img">
            <Link to={`/${pageName == "news" ? "news" : "blogs"}/${blogs.id}`}>
              <img src={blogs.blog_img} alt="test" />
            </Link>
          </Col>
          <Col xs={12} xl={7} className="cont-txt">
            <div className="flx-centered">
              <div className="date">
                <FontAwesomeIcon icon={faClock} />
                <span>{blogs.created_at}</span>
              </div>
              <div className="views">
                <FontAwesomeIcon icon={faEye} />
                <span>{blogs.views} مشاهدة</span>
              </div>
            </div>
            <h2>
              <NavLink
                to={`/${pageName == "news" ? "news" : "blogs"}/${blogs.id}`}
              >
                {blogs.title.ar.length > 50
                  ? `${blogs.title.ar.slice(0, 50)}...`
                  : blogs.title.ar}
              </NavLink>
            </h2>
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html:
                  blogs.content.ar?.length > 75
                    ? `${blogs.content.ar.slice(0, 75)}...`
                    : blogs.content.ar,
              }}
            ></div>
            <NavLink
              to={`/${pageName == "news" ? "news" : "blogs"}/${blogs.id}`}
              className="btn primary-btn"
            >
              تفاصيل أكثر
            </NavLink>
          </Col>
        </Row>
      );
    })
  ) : (
    <p>لا توجد اخبار</p>
  );
  return (
    <>
      <Helmet>
        <title>{pageName === "news" ? "الأخبار" : "المدونة"}</title>
      </Helmet>
      {/* <Sugar customLoading={loading}  color={'#00A388'} background="#87D4C7"/> */}
      {loading && <Loading />}
      <section className="news-list-parent">
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">
                    {`${pageName == "news" ? "الاخبار" : "المدونة"}`}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            {props.location.pathname.slice(1) == "news" && (
              <Col xs={12} md={5} xl={3}>
                <NewsCatsBlock />
              </Col>
            )}
            {console.log("pageName", pageName)}

            <Col md={7} xl={`${pageName == "news" ? 9 : 12}`} xs={12}>
              {loopOnNews}
            </Col>
            <Col xs={12} md={7} xl={9} className="pagination-parent-books">
              {parseInt(totalPages) > 1 ? (
                <ul className="pagination-parent flx-centered">
                  <li className="first-page">
                    <button
                      onClick={handlePrevPage}
                      className={
                        currentPage == "1" ? `disabled-btn` : `arrow-btn`
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </li>
                  {currentPage != 1 && currentPage != 2 && (
                    <li className="test-last" onClick={() => handleGoToPage(1)}>
                      <button>1</button>
                    </li>
                  )}
                  {currentPage != 1 && currentPage != 2 && (
                    <li className="to-continue">...</li>
                  )}
                  {pages}

                  {totalPages != currentPage &&
                    currentPage != Number(totalPages) - 1 && (
                      <li className="to-continue">...</li>
                    )}
                  {totalPages != currentPage &&
                    currentPage != Number(totalPages) - 1 && (
                      <li
                        className="test-last"
                        onClick={() => handleGoToPage(totalPages)}
                      >
                        <button>{totalPages}</button>
                      </li>
                    )}
                  <li className="last-page">
                    <button
                      onClick={handleNextPage}
                      className={
                        currentPage == totalPages ? `disabled-btn` : `arrow-btn`
                      }
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                  </li>
                </ul>
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogsNewsList;
