import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../../Components/Auth/ForCookie";
import Loading from "../../../Components/Blocks/Loading";
import { getData } from "../../../Services/DataService";
import BaseUrl from "../../../config";
import "./index.scss";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useScrollToTop from "../../../hooks/useScrollToTop";
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

const AddBook = () => {
  // const editorConfiguration = {
  //   plugins: [ Essentials, Bold, Italic, Paragraph ],
  //   toolbar: [ 'bold', 'italic' ]
  // };

  const [mainCategoriesData, setMainCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [bookTitle, setBookTitle] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [bookStatus, setBookStatus] = useState("0");
  const [bookContent, setBookContent] = useState("0");
  const [bookMainCategory, setBookMainCategory] = useState("");
  const [bookSubCategory, setBookSubCategory] = useState("");
  const [bookImages, setBookImages] = useState([]);
  const [bookPicName, setBookPicName] = useState("");
  const [bookLicensePicName, setBookLicensePicName] = useState("");
  const [bookLicensePic, setBookLicensePic] = useState("");
  const [licenseImgText, setLicenseImgText] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookCity, setBookCity] = useState("");
  const [bookPublishHouse, setBookPublishHouse] = useState("");
  const [bookLanguage, setBookLanguage] = useState("");
  const [bookPrintYear, setBookPrintYear] = useState("");
  const [bookPageNumber, setBookPageNumber] = useState("");
  const [bookQuantity, setBookQuantity] = useState("");
  const [bookIsBinCode, setBookIsBinCode] = useState("");
  const [alertErrors, setAlertErrors] = useState(false);
  const [sucessAddBook, setSucessAddBook] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});
  const [acceptRules, setAcceptRules] = useState(false);
  const [galleryImg, setGalleryImg] = useState("");
  const [allLanguages, setAllLanguages] = useState([]);
  const [allPublishHouses, setAllPublishHouses] = useState([]);
  const [allAuthors, setAllAuthors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState(false);
  const [user_type, setUser_type] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  useEffect(() => {
    getData(`api/user-types`).then((response) => {
      const data = response.data;
      const dataArr = Object.values(data);
      setUserTypes(dataArr);
      setUser_type(dataArr[0].en);
    });

    getData(`api/categories`).then(
      (response) => {
        const mainCategoriesData = response.data.data;
        setMainCategoriesData(mainCategoriesData);
        setBookMainCategory(mainCategoriesData[0]?.id);
        setLoading(false);
      },
      (error) => {
        setMainCategoriesData([]);
      }
    );
    getData(`api/sub-categories`).then(
      (response) => {
        const subCategoriesData = response.data.data;
        setSubCategoriesData(subCategoriesData);
        setBookSubCategory(subCategoriesData[0]?.id);
        setLoading(false);
      },
      (error) => {
        setSubCategoriesData([]);
      }
    );

    getData(`api/all-languages`).then(
      (response) => {
        const allLanguages = response.data.data;
        setAllLanguages(allLanguages);
        if (allLanguages.length > 0) {
          setBookLanguage(allLanguages[0].id);
        }
      },
      (error) => {
        setAllLanguages([]);
      }
    );

    // getData(`api/authors`).then(
    //   (response) => {
    //     const allAuthors = response.data.data;
    //     setAllAuthors(allAuthors);
    //     if (allAuthors.length > 0) {
    //     setBookAuthor(allAuthors[0].id);
    //     }
    //   },
    //   (error) => {
    //     setAllAuthors([]);
    //   }
    // );

    // getData(`api/publish-houses`).then((response) => {
    //     const allPublishHouses = response.data.data;
    //     setAllPublishHouses(allPublishHouses);
    //     // if (allPublishHouses.length > 0) {
    //     //   setBookPublishHouse(allPublishHouses[0].id);
    //     // }
    //   },
    //   (error) => {
    //     setAllPublishHouses([]);
    //   }
    // );
  }, []);
  useScrollToTop();
  const editorRef = useRef();

  // //get the data from the input and add this ti the state
  // const handleChange = (e) => {
  //   // setState({
  //   //   [e.target.id]: e.target.value,
  //   // });
  // };

  // // make inut acceopts numbers only
  // const handleChangeNumbers = (e) => {
  //   const theVal = e.target.value.replace(/[^ 0-9]/gi, "");
  //   setState({ [e.target.id]: theVal });
  // };

  //get the input from the select element

  const handleChangeIdNumber = (e) => {
    setBookStatus(e.target[e.target.selectedIndex].id);
  };

  // handel Change main category related sub categories
  const handleChangeMainCategory = (e) => {
    e.preventDefault();
    setBookMainCategory(e.target[e.target.selectedIndex].id);
    const subCategoriesData =
      mainCategoriesData[e.target.selectedIndex].sub_category;
    setSubCategoriesData(subCategoriesData);
    setBookSubCategory(subCategoriesData[0].id);
  };
  // handle change sub category
  const handleChangeSubCategory = (e) => {
    e.preventDefault();
    setBookSubCategory(e.target[e.target.selectedIndex].id);
  };
  const formRef = useRef();

  // handle change of user type in radio select option
  // const handleChangeRadio = (e) => {
  //   setState({
  //     [e.target.name]: e.target.id,
  //   });
  // };

  // upload and get the image of user
  const handleImageUpload = (e) => {
    setErrorsLog({ ...errorsLog, gallery: null});

    let uploadFileText = e.target.value;
    let fileSrc = e.target.files;
    setBookImages(fileSrc);
    if (fileSrc.length > 0 && fileSrc[0].type == "image/jpeg") {
      setGalleryImg(uploadFileText);
    } else {
      setErrorsLog({
        ...errorsLog,
        gallery: { ar: "برجاء اختيار صورة من صيغة jpg" },
      });
    }
  };

  // upload and get the image of user
  const handleLicenseImageUpload = (e) => {
    setErrorsLog({ ...errorsLog, license: null});
    let uploadFileText = e.target.value;
    let fileSrc = e.target.files;
    setBookLicensePic(fileSrc);
    if (fileSrc.length > 0 && fileSrc[0].type == "image/jpeg") {
      setLicenseImgText(uploadFileText);
    } else {
      setErrorsLog({
        ...errorsLog,
        license: { ar: "برجاء اختيار صورة من صيغة jpg" },
      });
    }
  };

  const handleAcceptRules = () => {
    setAcceptRules(!acceptRules);
  };

  const handleChangeLanguage = (e) => {
    setBookLanguage(e.target.value);
  };

  // const handleChangeAuthors = (e) => {
  //   setBookAuthor(e.value);
  // };

  // const handleChangePublishHouses = (e) => {
  //   setBookPublishHouse(e.value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorsLog(errorsLog);
    setAlertErrors(false);
    const formData = new FormData();
    formData.append("titleEn", bookTitle);
    formData.append("titleAr", bookTitle);

    formData.append("slugEn", bookContent);

    formData.append("slugAr", bookContent);

    formData.append("price", bookPrice);

    formData.append("used_status", bookStatus);

    formData.append("category_id", bookMainCategory);

    formData.append("sub_category", bookSubCategory);

    formData.append("author_id", bookAuthor);

    formData.append("publish_house_id", bookPublishHouse);

    formData.append("buyEn", bookCity);

    formData.append("buyAr", bookCity);

    formData.append("book_language", bookLanguage);

    formData.append("page_no", bookPageNumber);

    formData.append("qty", bookQuantity);

    formData.append("isbn", bookIsBinCode);

    formData.append("contentEn", bookContent);

    formData.append("contentAr", bookContent);

    formData.append("publish", bookPrintYear);

    formData.append("user_id", getCookie("userId"));

    const images = bookImages;
    for (var x = 0; x < images.length; x++) {
      formData.append("gallery[]", images[x]);
    }

    const licenseImages = bookLicensePic;
    for (var y = 0; y < licenseImages.length; y++) {
      formData.append("license_pic[]", licenseImages[y]);
    }

    formData.append("print_year", bookPrintYear);

    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    };

    axios.post(`${BaseUrl}/api/add-book`, formData, { headers }).then(
      (response) => {
        console.log("response", response);
        setSucessAddBook(true);
        //reset form
        formRef.current.reset();

        window.scrollTo({ top: 0, behavior: "smooth" });
        //remove the data from the input
        setBookTitle("");
        setBookPrice("");
        setBookStatus("0");
        setBookContent("");

        setBookImages([]);
        setBookPicName("");

        setBookLicensePicName("");
        setBookLicensePic("");

        setBookAuthor("");
        setBookCity("");
        setBookPublishHouse("");
        setBookPrintYear("");
        setBookPageNumber("");
        setBookQuantity("");
        setBookIsBinCode("");
        setGalleryImg("");
        setErrorsLog({});
        setAlertErrors(false);
        //clear the editor
        if (editorRef.current) {
          editorRef.current.editor.setData("0");
        }
        setBookLanguage(allLanguages[0].id);
        setBookMainCategory(mainCategoriesData[0].id);
        setBookSubCategory(subCategoriesData[0].id);
        setAcceptRules(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        if (error) {
          const errors = error.response.data;
          setErrorsLog(errors);
          console.log("errors", errors);
          setAlertErrors(true);
          //set specific error for each item using its id

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );
  };

  const customStyles = {
    menu: () => ({
      width: "100%",
      // background: `${context.theme == "dark" ? "#2F3853" : "#F9F9F9"}`,
      borderBottom: "1px dotted pink",
      color: "#BDBDBD",
      padding: "8px",
      textAlign: "right",
      // border: `${
      //   context.theme == "dark"
      //     ? "1px solid #172F54"
      //     : "1px solid #f9f9f9"
      // }`,
      borderRadius: "4px",
      position: "absolute",
      zIndex: "1000000",
      margin: "8px 0 0 0",
    }),
    control: (styles) => ({
      ...styles,
      // backgroundColor: `${
      //   context.theme == "dark" ? "#2F3853" : "#F9F9F9"
      // }`,
      color: "#BDBDBD",
      // borderRadius: "8px",
      height: "auto",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      // border: `${
      //   context.theme == "dark"
      //     ? "1px solid #172F54"
      //     : "1px solid #f9f9f9"
      // }`,
    }),
    input: () => ({ display: "flex" }),
    placeholder: () => ({ color: `'#BDBDBD'` }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({
      width: "24px",
      height: "16px",
      background: "transparent",
      borderRadius: "8px",
      color: "#BDBDBD",
      display: "flex",
      alignItems: "center",
      justifiyContents: "center",
      marginLeft: "8px",
      fontSize: "0",
    }),
    valueContainer: () => ({
      height: "40px",
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      color: "#BDBDBD",
    }),
    singleValue: () => ({ color: `#BDBDBD` }),
  };

  // list the categoreis in the select box
  const loogOnMainCategories = mainCategoriesData.length
    ? mainCategoriesData.map((category) => {
        return (
          <option key={category.id} id={category.id}>
            {category.title.ar}
          </option>
        );
      })
    : null;
  // list the sub categories in the select box
  const loogOnSubCategories = subCategoriesData.length
    ? subCategoriesData.map((category) => {
        return (
          <option key={category.id} id={category.id}>
            {category.title.ar}
          </option>
        );
      })
    : null;

  const loopOnLanguages =
    allLanguages.length > 0
      ? allLanguages.map((item) => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        })
      : null;

  return (
    <>
      <Helmet>
        <title>اضافة كتاب</title>
        <meta name="description" content="Add Books" />
      </Helmet>
      {Loading === true ? (
        <Loading />
      ) : (
        <section className="AddBook-sec">
          <Container>
            <Row>
              <Col xs={12} lg={12} className="mx-auto">
                <div className="form-heading">
                  <h1>أضف بيانات الكتاب</h1>
                </div>
              </Col>
            </Row>
            <Row>
              {getCookie("loginned") ? (
                <Col xs={12} lg={10} className="mx-auto">
                  <div className="alert-sec">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faExclamationTriangle}
                    ></FontAwesomeIcon>
                    <span>
                      يمكنك زيادة الوصول لأكبر عدد من المهتمين بكتابك عن طريق
                      تعبئة البيانات بشكل صحيح ومفصل كما يمكنك طلب تضمين كتابك
                      ضمن النشرة الإعلانية للموقع عن طريق مراسلتنا
                      <Link to="/contact-us">هنا</Link>
                    </span>
                  </div>
                  {alertErrors && (
                    <div className="errors-alert">
                      <FontAwesomeIcon
                        className="alert-icon"
                        icon={faInfoCircle}
                      ></FontAwesomeIcon>
                      <span>برجاء عدم ترك الحقول المطلوبة فارغة</span>
                    </div>
                  )}
                  {sucessAddBook && (
                    <div className="sucess-alert">
                      <FontAwesomeIcon
                        className="alert-icon"
                        icon={faCheckCircle}
                      ></FontAwesomeIcon>
                      <span>شكرا لك تم رفع الكتاب بنجاح</span>
                    </div>
                  )}
                  <Form
                    className="AddBookForm FormParent"
                    onSubmit={handleSubmit}
                    ref={formRef}
                  >
                    <Row>
                      <Col xs={12} lg={6}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.titleAr ? "has-error" : null
                          }`}
                        >
                          <Form.Label> عنوان الكتاب * </Form.Label>
                          <Form.Control
                            id="bookTitle"
                            onChange={(e) => setBookTitle(e.target.value)}
                            type="text"
                            placeholder="ادخل عنوان الكتاب"
                            autoComplete="off"
                          />
                          {errorsLog && errorsLog.titleAr ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.titleAr.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={3}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.price ? "has-error" : null
                          }`}
                        >
                          <Form.Label>السعر *</Form.Label>
                          <Form.Control
                            id="bookPrice"
                            onChange={(e) =>
                              setBookPrice(
                                e.target.value.replace(/[^ 0-9]/gi, "")
                              )
                            }
                            value={bookPrice}
                            type="text"
                            autoComplete="off"
                            placeholder="ادخل سعر الكتاب"
                          />
                          {errorsLog && errorsLog.price ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.price.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col xs={12} lg={3}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.used_status
                              ? "has-error"
                              : null
                          }`}
                        >
                          <Form.Label>حالة الإستخدام</Form.Label>
                          <Form.Control
                            as="select"
                            className={`form-select ${
                              errorsLog && errorsLog.used_status
                                ? "has-error"
                                : null
                            }`}
                            id="bookStatus"
                            onChange={handleChangeIdNumber}
                          >
                            <option id="0">مستعمل</option>
                            <option id="1">جديد</option>
                          </Form.Control>

                          {errorsLog && errorsLog.used_status ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.used_status.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group
                      className={` mb-3  mobile-wrap ${
                        errorsLog && errorsLog.contentAr ? "has-error" : null
                      }`}
                      id="formType"
                    >
                      <Form.Label>التفاصيل</Form.Label>
                      <div>
                        <CKEditor
                          ref={editorRef}
                          UploadAdapter={true}
                          config={{
                            toolbar: {
                              //all items except image
                              items: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",

                                "insertTable",
                                "mediaEmbed",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                                "undo",
                                "redo",
                              ],
                            },
                          }}
                          //  ImageUploadUI={true}
                          editor={ClassicEditor}
                          data=""
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setBookContent(data);
                          }}
                          editorLoaded={editorLoaded}
                        />
                      </div>

                      {errorsLog && errorsLog.contentAr ? (
                        <div className="errors-parent">
                          <span className="error-txt">
                            {errorsLog.contentAr.ar}
                          </span>
                        </div>
                      ) : null}
                    </Form.Group>

                    <Row>
                      <Col xs={12} lg={6}>
                        <Form.Group className="mb-3 required">
                          <Form.Label htmlFor="selectedCategory">
                            التصنيف الرئيسي
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className={`form-select ${
                              errorsLog && errorsLog.category_id
                                ? "has-error"
                                : null
                            }`}
                            id="bookMainCategory"
                            onChange={handleChangeMainCategory}
                          >
                            {loogOnMainCategories}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xs={12} lg={6}>
                        <Form.Group className="mb-3 required">
                          <Form.Label htmlFor="selectedCategory">
                            التصنيف الفرعي
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className={`form-select ${
                              errorsLog && errorsLog.category_id
                                ? "has-error"
                                : null
                            }`}
                            id="bookSubCategory"
                            onChange={handleChangeSubCategory}
                          >
                            {loogOnSubCategories}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} lg={6}>
                        <Form.Group
                          className={`${
                            (errorsLog && errorsLog.gallery) ||
                            errorsLog["gallery.0"]
                              ? "has-error"
                              : null
                          }`}
                        >
                          <Form.Label>صورة الكتاب *</Form.Label>
                          <div
                            className="custom-input-file-parent"
                            onChange={(e) => setBookPicName(e.target.value)}
                            // onChange={getTextOfInputName}
                          >
                            <Form.Control
                              type="file"
                              multiple
                              onChange={handleImageUpload}
                              id="bookImages"
                            />
                            <div className="custom-input-file">
                              <span className="changable-txt">
                                {galleryImg
                                  ? galleryImg
                                  : `حجم الملف لا يزيد عن 5 ميجا بايت`}
                              </span>
                              <span className="colored-txt">آختر صورة</span>
                            </div>
                          </div>
                          {(errorsLog && errorsLog.gallery) ||
                          errorsLog["gallery.0"] ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.gallery?.ar ||
                                  "من فضلك ادخل صورة للكتاب صيغه jpg"}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.author_id
                              ? "has-error"
                              : null
                          }`}
                        >
                          <Form.Label>الكاتب / المؤلف *</Form.Label>
                          <Form.Control
                            id="bookAuthor"
                            onChange={(e) => setBookAuthor(e.target.value)}
                            type="text"
                            autoComplete="off"
                            placeholder="ادخل اسم الكاتب / المؤلف"
                          />
                          {errorsLog && errorsLog.author_id ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.author_id.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} lg={6}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.buyAr ? "has-error" : null
                          }`}
                        >
                          <Form.Label>مدينة البيع *</Form.Label>
                          <Form.Control
                            id="bookCity"
                            onChange={(e) => setBookCity(e.target.value)}
                            type="text"
                            autoComplete="off"
                            placeholder="اختار مدينة المتواجد بها الكتاب"
                          />
                          {errorsLog && errorsLog.buyAr ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.buyAr.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.publish_house_id
                              ? "has-error"
                              : null
                          }`}
                        >
                          <Form.Label>دار النشر *</Form.Label>
                          <Form.Control
                            id="bookPublishHouse"
                            onChange={(e) =>
                              setBookPublishHouse(e.target.value)
                            }
                            type="text"
                            autoComplete="off"
                            placeholder="ادخل اسم دار النشر "
                          />
                          {errorsLog && errorsLog.publish_house_id ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.publish_house_id.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={3}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.languageAr
                              ? "has-error"
                              : null
                          }`}
                        >
                          <Form.Label>اللغة</Form.Label>
                          <select onChange={handleChangeLanguage}>
                            {loopOnLanguages}
                          </select>
                          {errorsLog && errorsLog.languageAr ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.languageAr.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={3}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.print_year
                              ? "has-error"
                              : null
                          }`}
                        >
                          <Form.Label>سنة الطباعة *</Form.Label>
                          <Form.Control
                            id="bookPrintYear"
                            onChange={(e) =>
                              setBookPrintYear(
                                e.target.value.replace(/[^ 0-9]/gi, "")
                              )
                            }
                            value={bookPrintYear}
                            type="text"
                            autoComplete="off"
                            placeholder="اختار سنة الطباعه"
                          />

                          {errorsLog && errorsLog.print_year ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.print_year.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col xs={12} lg={3}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.page_no ? "has-error" : null
                          }`}
                        >
                          <Form.Label>عدد صفحات الكتاب *</Form.Label>
                          <Form.Control
                            id="bookPageNumber"
                            onChange={(e) =>
                              setBookPageNumber(
                                e.target.value.replace(/[^ 0-9]/gi, "")
                              )
                            }
                            value={bookPageNumber}
                            type="text"
                            autoComplete="off"
                            placeholder="عدد صفحات الكتاب"
                          />
                          {errorsLog && errorsLog.page_no ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.page_no.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={3}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.qty ? "has-error" : null
                          }`}
                        >
                          <Form.Label>الكمية *</Form.Label>
                          <Form.Control
                            id="bookQuantity"
                            onChange={(e) =>
                              setBookQuantity(
                                e.target.value.replace(/[^ 0-9]/gi, "")
                              )
                            }
                            value={bookQuantity}
                            type="text"
                            autoComplete="off"
                            placeholder="كمية الكتب المتاحة"
                          />
                          {errorsLog && errorsLog.qty ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.qty.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} lg={6}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.isbn ? "has-error" : null
                          }`}
                        >
                          <Form.Label>الرقم التسلسلي *</Form.Label>
                          <Form.Control
                            id="bookIsBinCode"
                            type="text"
                            autoComplete="off"
                            onChange={(e) =>
                              setBookIsBinCode(
                                e.target.value.replace(/[^ 0-9]/gi, "")
                              )
                            }
                            value={bookIsBinCode}
                            placeholder="ISBN code"
                          />
                          {errorsLog && errorsLog.isbn ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.isbn.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col xs={12} lg={6}>
                        <Form.Group
                            className={`${
                                (errorsLog && errorsLog.license) ||
                                errorsLog["gallery.0"]
                                    ? "has-error"
                                    : null
                            }`}
                        >
                          <Form.Label>صورة الترخيص *</Form.Label>
                          <div
                              className="custom-input-file-parent"
                              onChange={(e) => setBookLicensePicName(e.target.value)}
                              // onChange={getTextOfInputName}
                          >
                            <Form.Control
                                type="file"
                                multiple
                                onChange={handleLicenseImageUpload}
                                id="bookImages"
                            />
                            <div className="custom-input-file">
                              <span className="changable-txt">
                                {licenseImgText
                                    ? licenseImgText
                                    : `حجم الملف لا يزيد عن 5 ميجا بايت`}
                              </span>
                              <span className="colored-txt">آختر صورة</span>
                            </div>
                          </div>
                          {(errorsLog && errorsLog.license) ||
                          errorsLog["gallery.0"] ? (
                              <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.license?.ar ||
                                    "من فضلك ادخل صورة لترخيص الكتاب صيغه jpg"}
                              </span>
                              </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        id="aggrementCheck"
                        label="أوافق على القوانين والارشادات الخاصه بالموقع"
                        onClick={handleAcceptRules}
                      />
                    </Form.Group>

                    <Row>
                      <Col xs={6} className="mx-auto">
                        <Button
                          className="btn primary-btn flx-centered"
                          type="submit"
                          disabled={acceptRules === false ? `disabled` : null}
                        >
                          نشر الكتاب
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              ) : (
                <Col xs={12}>
                  <div className="errors-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faInfoCircle}
                    ></FontAwesomeIcon>
                    <span>
                      لتتمكن من اضافة كتاب جديد عليك الذهاب لصفحة
                      <NavLink to="/login">التسجيل</NavLink> اولا
                    </span>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default AddBook;
