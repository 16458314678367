import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { createData } from "../../../Services/DataService.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { getCookie, setCookie } from "../ForCookie.js";
import { Link } from "react-router-dom";
import "./index.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logined, setLogined] = useState(getCookie("loginned"));
  const [rememberMe, setRememberMe] = useState(false);
  const [errorLog, setErrorLog] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [errosObj, setErrosObj] = useState("");

  const handleChange = (e) => {
    // setState({
    //     [e.target.id]: e.target.value,
    // });
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    setShowError(false);
    createData(`api/auth/login`, formData)
      .then((response) => {
        setCookie("userEmail", response.data.user_info.email);
        setCookie("userName", response.data.user_info.name);
        setCookie("userimage", response.data.user_info.avatar);
        setCookie("userId", response.data.user_info.id);
        setCookie("token", response.data.token.original.access_token);
        setCookie("loginned", true);

        if(response.data.user_info.completed_at)
          setCookie("c_at", response.data.user_info.completed_at);

        // if (rememberMe) {
        //   setCookie("userName", response.data.user_info.name);
        //   setCookie("userimage", response.data.user_info.avatar);
        //   setCookie("userId", response.data.user_info.id);
        //   setCookie(
        //     "token",
        //     response.data.token.original.access_token,
        //     3600000 * 24 * 15
        //   );
        //   setCookie("loginned", true, 3600000 * 24 * 15);
        // }
      })
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        if (error) {
          setErrorLog(error.response.data.error);
          setShowError(true);
          setErrosObj(error.response.data.errors);
          setErrorData(error.response.data.data);
        } else {
          setErrorLog(null);
        }
      });
  };
  return (
    <>
      <section className="login-sec">
        <Container>
          {getCookie("loginned") === "true" ? (
            <Row>
              <Col md={12} xs={12} className="mx-auto">
                <div className="no-content-show">
                  <p>انت مسجل بالفعل</p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="form-heading">
                  <h1>مرحبا بعودتك !</h1>
                  <p>تسجيل الدخول الى حسابك</p>
                </div>
              </Col>
              <Col md={6} xs={12} className="mx-auto">
                <Form className="loginForm" onSubmit={handleSubmit}>
                  {showError && (
                    <div className="errors-alert">
                      <FontAwesomeIcon
                        className="alert-icon"
                        icon={faInfoCircle}
                      ></FontAwesomeIcon>
                      <span>{errorLog || errorData}</span>
                    </div>
                  )}
                  <Form.Group
                    className={`${
                      errosObj && errosObj.email ? "has-error" : null
                    }`}
                  >
                    <Form.Label>البريد الالكتروني</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@domain.com"
                      required
                    />
                    {errosObj && errosObj.email ? (
                      <div className="errors-parent">
                        <span className="error-txt">{errosObj.email.ar}</span>
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group
                    className={`${
                      errosObj && errosObj.password ? "has-error" : null
                    }`}
                  >
                    <Form.Label>كلمة المرور</Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="على الأقل 8 ارقام و حروف"
                      required
                    />
                    {errosObj && errosObj.password ? (
                      <div className="errors-parent">
                        <span className="error-txt">
                          {errosObj.password.ar}
                        </span>
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      onClick={handleRememberMe}
                      type="checkbox"
                      label="تذكر معلوماتي"
                    />
                  </Form.Group>
                  <Button
                    className="btn all-btn login-btn flx-centered"
                    type="submit"
                  >
                    تسجيل الدخول
                  </Button>

                  <Form.Text className="text-muted">
                    <span>ليس لديك عضوية ؟ برجاء التوجة الي صفحة</span>
                    <Link href="/register">التسجيل</Link>
                    <div className="forgetPasswrod">
                      <Link to="/forget-password">فقدت كلمة المرور ؟</Link>
                    </div>
                  </Form.Text>
                </Form>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default Login;
