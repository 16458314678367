import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLink } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Loading from "../../../Components/Blocks/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import useScrollToTop from "../../../hooks/useScrollToTop";

const EventsShow = (props) => {
  const [events, setEvents] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(`api/events/${props.match.params.id}`).then(
      (response) => {
        const data = response?.data?.data;
        console.log("events", data);
        setEvents(data);
        setLoading(false);
      },
      (error) => {
        setEvents([]);
        setLoading(false);
      }
    );
  }, [props]);
  useScrollToTop();
  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <section className="events-parent-page-show">
          <Container>
            <Row>
              <Col xs={12}>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item t-none">
                    <NavLink to="/" className="bredcrumb-link">
                      الرئيسية
                    </NavLink>
                  </li>

                  <li className="breadcrumb-item t-none">
                    <NavLink to="/events" className="bredcrumb-link">
                      <span className="mob-bredcrumb active">الفعاليات</span>
                    </NavLink>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="mob-bredcrumb active">
                      {events?.title?.ar && events?.title?.ar}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="cont">
                  <div className="cont-title">
                    <h2>{events?.title?.ar && events?.title?.ar}</h2>
                  </div>

                  <div className="cont-inner">
                    <div className="cont-details">
                      <div className="cont-info">
                        <div className="date">
                          <span className="black-cl">بداية الفعالية :</span>
                          <FontAwesomeIcon icon={faClock} />
                          <span>
                            {events?.event_start && events?.event_start}
                          </span>
                        </div>
                      </div>
                      <div className="cont-info">
                        <div className="date">
                          <span className="black-cl">نهاية الفعالية :</span>
                          <FontAwesomeIcon icon={faClock} />
                          <span>{events?.event_end && events?.event_end}</span>
                        </div>
                      </div>
                      {/* <div className="cont-info">
                            <div className="date">
                              <span className="black-cl">رابط الفعالية :</span>
                              <FontAwesomeIcon icon={faLink} />
                              {events?.link === "" ? (
                                <span>لا يوجد رابط</span>
                              ) : (
                                <a
                                  href={events?.link}
                                  alt="link"
                                  target="_blank"
                                >
                                  الرابط
                                </a>
                              )}
                            </div>
                          </div> */}
                      <div className="cont-txt">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: events?.content?.ar && events?.content?.ar,
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="cont-img">
                      <LazyLoadImage src={events?.img && events?.img} alt="" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default EventsShow;
