import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
// import axios from 'axios';
// import BaseUrl from '../../../config';
// import imgOne from '../../../Assets/Img/deal-1.png';
// import imgTwo from '../../../Assets/Img/deal-2.png';
// import imgThree from '../../../Assets/Img/deal-3.png';
import { getData } from "../../../Services/DataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import {  faHeart } from '@fortawesome/free-regular-svg-icons';
import { NavLink } from "react-router-dom";
import "./index.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const RecommendedDeals = () => {
  const [dealsData, setDealsData] = useState();

  useEffect(() => {
    getData(`api/services-most-request`).then(
      (response) => {
        const data = response.data.data;
        setDealsData(data);
      },
      (error) => {
        setDealsData([]);
      }
    );
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Number(dealsData?.length) >= 3 ? 3 : 1,
    slidesToScroll: 3,
    arrows: true,
    rtl: true,
    responsive: [
      {
        breakpoint: 922,
        settings: {
          slidesToShow: Number(dealsData?.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: false,
      //   },
      // },
      // {
      //   breakpoint: 580,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: false,
      //   },
      // },
    ],
  };
  return (
    <>
      <section className="recommended-deals-sec">
        <Container>
          <Row>
            <Col>
              <div className="widget-title">
                <h2>صفقات الأكثر تفاعلاً</h2>
                <div className="all-books">
                  <a href="/deals" className="all-btn">
                    جميع الصفقات
                  </a>
                </div>
              </div>
              {dealsData?.length ? (
                <Slider {...settings} className="recommended-slider">
                  {dealsData?.length > 0
                    ? dealsData?.map((item) => {
                        return (
                          <div className="recommended-slide" key={item.id}>
                            <Link to={`/deals/${item.id}`}>
                              <div className="cont">
                                <div className="cont-img">
                                  <img
                                    src={item.images[0]}
                                    alt={item.title.ar}
                                  />
                                </div>
                                <div className="cont-txt">
                                  <h2>
                                    <NavLink
                                      to={`/deals/${item.id}`}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.title.ar.length > 20
                                            ? `${item.title.ar.slice(0, 20)}...`
                                            : item.title.ar,
                                      }}
                                    ></NavLink>
                                  </h2>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.content.ar.length > 20
                                          ? `${item.content.ar.slice(0, 20)}...`
                                          : item.content.ar,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    : null}
                </Slider>
              ) : (
                <div className="errors-alert">
                  <FontAwesomeIcon
                    className="alert-icon"
                    icon={faInfoCircle}
                  ></FontAwesomeIcon>
                  <span>لا توجد صفقات لعرضها</span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RecommendedDeals;
