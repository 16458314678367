import React, { useState } from 'react';
import { SearchContext } from './SearchContext';
const SearchProvider = (props) => {
    const [searchType, setSearchType] = useState("books")
    return (
        <SearchContext.Provider  value={{searchType, setSearchType}}>
            {props.children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;