import {
  faCheckDouble,
  faExclamationTriangle,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { isBrowser } from "react-device-detect";
import { Helmet } from "react-helmet";
import { AiFillCheckCircle } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, NavLink } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getCookie } from "../../../Components/Auth/ForCookie";
import Loading from "../../../Components/Blocks/Loading";
import RatingBlock from "../../../Components/Blocks/Rating";
import { createData, getData } from "../../../Services/DataService";
import SendMessage from "../../Chats/SendMessage/SendMessage";
import AuthorBlogsProfile from "../Author-blogs";
import AuthorBooksProfile from "../Author-books";
import AutherBooksRejected from "../Author-books-rejected";
import AuthorFollowersUsers from "../Author-followers";
import AuthorFollowingUsers from "../Author-following";
import AuthorServicesProfile from "../Author-services";
import AuthorWishlistBooks from "../Author-wishlist-books";
import "./index.scss";

import useScrollToTop from "../../../hooks/useScrollToTop";
import axios from "axios";
import { toast } from "react-toastify";

const AuthorPage = (props) => {
  useScrollToTop();
  const [DealData, setDealData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [userId, setuserId] = useState("");
  const [userImage, setUserImage] = useState("");
  const [isRated, setIsRated] = useState("");
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userAbout, setUserAbout] = useState("");
  const [verified, setVerified] = useState("");
  const [emailVerified, setEmailVerified] = useState(0);
  const [userBooksCount, setUserBooksCount] = useState("");
  const [sucessunfollow, setSucessunfollow] = useState(false);
  const [userFollowingCount, setUserFollowingCount] = useState("");
  const [userFollowersCount, setUserFollowersCount] = useState("");
  const [userRates, setUserRates] = useState("");
  const [loading, setLoading] = useState(true);
  const [isUser, setIsUser] = useState(true);
  const [errorExsist, setErrorExsist] = useState(false);
  const [sucessExsist, setSucessExsist] = useState(false);
  const [following, setFollowing] = useState(false);
  const [dataCanFollow, setDataCanFollow] = useState(0);
  const [dataCanMessage, setDataCanMessage] = useState(0);
  const [openSendMessage, setOpenSendMessage] = useState(0);
  const [errorLogObj, setErrorLogObj] = useState("");
  const [loginedUserRate, setLoginedUserRate] = useState("");

  const handleVerifyEmail = async () => {
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/resend_email_verification`
      )
      .then(
        (response) => {
          toast.success("تم ارسال رسالة التفعيل لبريدك الالكتروني", {
            toastId: "verifyEmail",
          });
        },
        (error) => {
          console.log(error);
          toast.error("تم ارسال الايميل...برجاء المحاولة لاحقا", {
            toastId: "verifyEmail",
          });
        }
      );
  };

  useEffect(() => {
    getData(`api/user-profile/${props.match.params.id}`).then(
      (response) => {
        const data = response?.data?.data;
        console.log("DealData", data);
        setDealData(data);
        setuserId(data.id);
        setIsRated(data.is_rated);
        setUserImage(data.avatar);
        setUserName(data.name);
        setUserType(data.roles);
        // setUserCountry(data?.region?.title?.ar);
        setUserCountry(data?.country);
        setUserCity(data?.city?.ar);
        // setUserCity(data?.city_id?.title?.ar);
        setUserAbout(data.about_me.ar);
        setLoginedUserRate(data.user_rate);
        setVerified(data?.verified);
        setUserBooksCount(data.uploaded_books_count);
        setUserFollowingCount(data.following_count);
        setUserFollowersCount(data.followers_count);
        setUserRates(data.rates);
        setEmailVerified(data?.verify);
        setLoading(false);
      },
      (error) => {
        window.location.href = "/404";
        setIsUser(false);
        setLoading(false);
      }
    );

    getData(
      `api/check-follow/${getCookie("userId")}/${props.match.params.id}`
    ).then(
      (response) => {
        if (response.data.data === "followed") {
          setFollowing(true);
        } else {
          setFollowing(false);
        }
      },
      (error) => {
        window.location.href = "/404";
      }
    );
    getData(`api/can-follow`).then(
      (response) => {
        const dataCanFollow = response.data.data;
        setDataCanFollow(dataCanFollow);
      },
      (error) => {
        setDataCanFollow(0);
      }
    );

    getData(`api/can-message`).then(
      (response) => {
        const dataCanMessage = response.data.data;
        setDataCanMessage(dataCanMessage);
      },
      (error) => {
        setDataCanMessage(0);
      }
    );

    // await axios.get(`${BaseUrl}/api/verify-request/${props.match.params.id}`).then(
    //   (res) => {
    //     const verified = res.data.data;
    //     setState({ verified });
    //     console.log(verified);
    //   },
    //   (err) => {
    //     setState({ verified: "0" });
    //   }
    // );
    // getData(`api/user-following/${ getCookie("userId") }`).then(
    //   (response) => {
    //     const usersData = response.data.data.data;
    //     setUsersData(usersData);
    //   },
    //   (error) => {
    //     setUsersData( []);
    //   }
    // );
  }, [props.match.params.id]);

  const handleShowErrorExsist = () => {
    setErrorExsist(!errorExsist);
  };
  const handleShowSucessExsist = () => {
    setSucessExsist(!sucessExsist);
  };

  // this function will show the mesage popup
  const handleShowSendMessage = (e) => {
    setOpenSendMessage(!openSendMessage);
  };

  // functions to follow and unfollow user
  const handleFollowUser = () => {
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    formData.append("follow_id", props.match.params.id);
    createData(`api/user-follow`, formData).then(
      (response) => {
        setSucessExsist(true);
        // check the request to the button
        getData(
          `api/check-follow/${getCookie("userId")}/${props.match.params.id}`
        ).then(
          (response) => {
            if (response.data.data === "followed") {
              setFollowing(true);
            } else {
              setFollowing(false);
            }
          },
          (error) => {}
        );
      },
      (error) => {
        const errorLogObj = error.response.data.data;
        setErrorLogObj(errorLogObj);
        setErrorExsist(true);
      }
    );
  };

  const handleUnFollowUsers = (e) => {
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    formData.append("follow_id", props.match.params.id);

    createData(`api/user-unfollow`, formData).then(
      (response) => {
        setSucessunfollow(true);
        setFollowing(false);
        getData(`api/user-following/${props.userId}`).then(
          (response) => {
            const usersData = response.data.data.data;
            setUsersData(usersData);
          },
          (error) => {
            setUsersData([]);
          }
        );
      },
      (error) => {
        const errorLogObj = error.response.data.data;
        setErrorLogObj(errorLogObj);
        setErrorExsist(true);
      }
    );
  };

  const BaseUrl = process.env.REACT_APP_API_ENDPOINT;
  // this function will send virfy request to the user
  const handleSendVerifyRequest = async (e) => {
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    formData.append("follow_id", e);

    createData(`api/verify-request`, formData).then(
      (res) => {
        const verified = res.data.data;
        setVerified(verified);
      },
      (err) => {
        setVerified("");
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {getCookie("userId") == userId
            ? `بياناتي`
            : `${userName && userName}`}
        </title>
        <meta name="description" content="Profile" />
      </Helmet>
      {loading === true ? (
        <Loading />
      ) : (
        <section className="athuor-page-parent">
          <Container>
            <Row>
              <Col xs={12}>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/" className="bredcrumb-link">
                      الرئيسية
                    </NavLink>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="mob-bredcrumb active">
                      {userName && userName}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>

            {isUser ? (
              <Row>
                <Col xs={12}>
                  <div className="cont-user">
                    <div className="info">
                      <div className="cont-img">
                        <LazyLoadImage
                          src={`${BaseUrl}/${userImage}`}
                          alt={userName && userName}
                        />
                      </div>
                      <div className="cont-txt">
                        <h2 className="author-name">
                          <b>{userName && userName}</b>{" "}
                          {(() => {
                            if (userName && verified == 1) {
                              return (
                                <>
                                  {props.match.params.id ==
                                  getCookie("userId") ? (
                                    <span className="PendingVrf">
                                      فى انتظار الموافقه على طلب التوثيق
                                    </span>
                                  ) : (
                                    <span className="rejectedVrf">
                                      غير موثق
                                    </span>
                                  )}
                                </>
                              );
                            } else if (userName && verified == 2) {
                              return (
                                <span>
                                  <AiFillCheckCircle />
                                </span>
                              );
                            } else if (userName && verified == 3) {
                              return (
                                <>
                                  {props.match.params.id ==
                                  getCookie("userId") ? (
                                    <span className="rejectedVrf">
                                      X تم رفض طلب التوثيق
                                    </span>
                                  ) : (
                                    <span className="rejectedVrf">
                                      غير موثق
                                    </span>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  {props.match.params.id ===
                                  getCookie("userId") ? (
                                    <button
                                      className="sendVef btn primary-btn"
                                      onClick={handleSendVerifyRequest}
                                    >
                                      طلب توثيق العضوية
                                    </button>
                                  ) : (
                                    <span className="rejectedVrf">
                                      غير موثق
                                    </span>
                                  )}
                                </>
                              );
                            }
                          })()}
                        </h2>

                        <div className="author-info flx-centered">
                          <span className="author-type">
                            {userType && userType}
                          </span>
                          <p className="author-location">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="rate-icon"
                            />
                            {userCountry
                              ? `${userCountry},${userCity}`
                              : userCity}
                          </p>
                        </div>
                      </div>
                    </div>

                    {props.match.params.id === getCookie("userId") ? (
                      <div className="controls">
                        {!emailVerified && (
                          <button
                            className="sendVef btn primary-btn"
                            onClick={handleVerifyEmail}
                          >
                            تفعيل البريد الالكتروني
                          </button>
                        )}
                        <NavLink to="/edit-profile" className="btn primary-btn">
                          ضبط حسابي
                        </NavLink>
                        {isBrowser && dataCanMessage === 1 ? (
                          <Link to="/messages" className=" btn red-btn">
                            رسائلي
                          </Link>
                        ) : null}
                      </div>
                    ) : (
                      <div className="controls">
                        {dataCanFollow === 1 && getCookie("loginned") ? (
                          following ? (
                            <Button
                              className="primary-btn"
                              onClick={() => {
                                handleUnFollowUsers();
                              }}
                            >
                              الغاء المتابعة
                            </Button>
                          ) : (
                            <Button
                              className="primary-btn"
                              onClick={handleFollowUser}
                            >
                              متابعة
                            </Button>
                          )
                        ) : null}

                        {dataCanMessage === 1 && getCookie("loginned") ? (
                          <Button
                            className="red-btn"
                            onClick={handleShowSendMessage}
                          >
                            إرسال رسالة
                          </Button>
                        ) : null}

                        {getCookie("loginned") && loginedUserRate == 0 ? (
                          // {(getCookie("loginned")) && (
                          <RatingBlock
                            bookRate={true}
                            userId={props.match.params.id}
                            type="user"
                          />
                        ) : (
                          <RatingBlock
                            initialRating={Math.round(loginedUserRate)}
                            bookRate={true}
                            userId={props.match.params.id}
                            type="user"
                          />
                        )}

                        {/* {
                          props.match.params.id === getCookie("userId") && (
                            isRated ? (
                              <div className="rate">
                                <RatingBlock
                                  bookRate={false}
                                  userId={props.match.params.id}
                                />
                              </div>
                            ) : 
                            <div className="rate" >تم التقييم بنجاح</div>

                          )
                         
                        } */}
                      </div>
                    )}
                  </div>
                </Col>

                <Col xs={12}>
                  <Tabs className="profile-tabs-parent">
                    <TabList className="tabs-controls">
                      <Tab>معلومات عني</Tab>
                      <Tab>الكتب المعروضة</Tab>
                      <Tab>الصفقات</Tab>
                      <Tab>المقالات المرفوعة</Tab>
                      <Tab>مفضلتي</Tab>
                      <Tab>المتابعون</Tab>
                      <Tab>يتابع</Tab>
                      {props.match.params.id === getCookie("userId") ? (
                        <Tab>الكتب المخالفة</Tab>
                      ) : null}
                    </TabList>
                    <TabPanel>
                      <div className="author-information-parent">
                        <div className="counters-row">
                          <div className="counter">
                            <p>عدد الكتب</p>
                            <span>{userBooksCount && userBooksCount}</span>
                          </div>
                          <div className="counter">
                            <p>المتابعون</p>
                            <span>
                              {userFollowersCount && userFollowersCount}
                            </span>
                          </div>
                          <div className="counter">
                            <p>يتابع</p>
                            <span>
                              {userFollowingCount && userFollowingCount}
                            </span>
                          </div>
                          <div className="counter">
                            <p>التقييم</p>
                            <span>{userRates && userRates}</span>
                          </div>
                        </div>

                        <div
                          className="about-txt"
                          dangerouslySetInnerHTML={{ __html: userAbout }}
                          // dangerouslySetInnerHTML={userAbout && userAbout}
                        >
                          {/* <p>{}</p> */}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <AuthorBooksProfile userId={props.match.params.id} />
                    </TabPanel>
                    <TabPanel>
                      <AuthorServicesProfile userId={props.match.params.id} />
                    </TabPanel>
                    <TabPanel>
                      <AuthorBlogsProfile userId={props.match.params.id} />
                    </TabPanel>

                    <TabPanel>
                      <AuthorWishlistBooks userId={props.match.params.id} />
                    </TabPanel>
                    <TabPanel>
                      <AuthorFollowersUsers userId={props.match.params.id} />
                    </TabPanel>
                    <TabPanel>
                      <AuthorFollowingUsers userId={props.match.params.id} />
                    </TabPanel>
                    <TabPanel>
                      {/* <AutherBooksRejected userId={props.match.params.id} /> */}
                      <AutherBooksRejected userId={props.match.params.id} />
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} lg={7} className="no-user-show mx-auto">
                  <div className="cont-txt">
                    <p>المستخدم غير موجود</p>
                  </div>
                </Col>
              </Row>
            )}
          </Container>

          {sucessExsist ? (
            <div className="alret-parent">
              <div
                className="close-alert"
                onClick={handleShowSucessExsist}
              ></div>
              <div className="cont">
                <div className="cont-txt">
                  <div className="heading error">
                    <FontAwesomeIcon
                      className="alert-icon sucess"
                      icon={faCheckDouble}
                    ></FontAwesomeIcon>
                    <h2>تم الإشتراك بنجاح</h2>
                  </div>
                </div>
                <div className="controls">
                  <Button
                    className="btn primary-btn"
                    onClick={handleShowSucessExsist}
                  >
                    اغلاق
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {errorExsist ? (
            <div className="alret-parent">
              <div
                className="close-alert"
                onClick={handleShowErrorExsist}
              ></div>
              <div className="cont">
                <div className="cont-txt">
                  <div className="heading error">
                    <FontAwesomeIcon
                      className="alert-icon error"
                      icon={faExclamationTriangle}
                    ></FontAwesomeIcon>
                    <h2>حدث خطا ما</h2>
                  </div>
                  <div className="errors-parent">
                    <p>{errorLogObj}</p>
                  </div>
                </div>
                <div className="controls">
                  <Button
                    className="btn primary-btn"
                    onClick={handleShowErrorExsist}
                  >
                    اغلاق
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      )}
      {openSendMessage ? (
        <div className="alret-parent send-message-parent">
          <div className="close-alert" onClick={handleShowSendMessage}></div>
          <div className="cont">
            <div className="heading">
              <h2>إرسال رسالة للمعلن</h2>
            </div>
            <SendMessage recevierId={props.match.params.id} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AuthorPage;
