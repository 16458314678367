import React from "react";

import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../../config";
import { getCookie } from "../../../../Components/Auth/ForCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { createData } from "../../../../Services/DataService";
import "./index.scss";
const EditPassword = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [userOldPassword, setUserOldPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [
    userNewPasswordConfirmation,
    setUserNewPasswordConfirmation,
  ] = useState("");
  const [errorsLog, setErrorsLog] = useState({});
  const [errorExsist, setErrorExsist] = useState("");
  const [sucessExsist, setSucessExsist] = useState("");

  const handleChange = (e) => {
    // setState({
    //     [e.target.id]: e.target.value,
    // });
  };

  const handleShowErrorExsist = (e) => {
    setErrorExsist(!errorExsist);
  };

  const handleShowSucessExsist = (e) => {
    setSucessExsist(!sucessExsist);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    formData.append(
      "email",
      userEmail.length > 0 ? userEmail : props.userEmail
    );
    formData.append("old_password", userOldPassword);
    formData.append("password", userNewPassword);
    formData.append("password_confirmation", userNewPasswordConfirmation);
    createData(`api/update-email-data`, formData).then(
      (response) => {
        setSucessExsist(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        if (error) {
          const errors = error.response.data;
          setErrorsLog(errors);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );
  };
  return (
    <>
      <Form className="FormParent edit-password-form" onSubmit={handleSubmit}>
        <Row>
          <Col>
            {errorsLog && errorsLog.error ? (
              <div className="errors-alert">
                <FontAwesomeIcon
                  className="alert-icon"
                  icon={faInfoCircle}
                ></FontAwesomeIcon>
                <span>{errorsLog.error}</span>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group
              className={`${errorsLog && errorsLog.email ? "has-error" : null}`}
            >
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                id="userEmail"
                onChange={(e) => setUserEmail(e.target.value)}
                type="email"
                placeholder={props.userEmail}
              />
              {errorsLog && errorsLog.email ? (
                <div className="errors-parent">
                  <span className="error-txt">{errorsLog.email.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group
              className={`${
                errorsLog && errorsLog.old_password ? "has-error" : null
              }`}
            >
              <Form.Label>كلمة المرور القديمة</Form.Label>
              <Form.Control
                id="userOldPassword"
                onChange={(e) => setUserOldPassword(e.target.value)}
                type="password"
              />
              {errorsLog && errorsLog.old_password ? (
                <div className="errors-parent">
                  <span className="error-txt">{errorsLog.old_password.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group
              className={`${
                errorsLog && errorsLog.password ? "has-error" : null
              }`}
            >
              <Form.Label>كلمة المرور الجديدة</Form.Label>
              <Form.Control
                id="userNewPassword"
                onChange={(e) => setUserNewPassword(e.target.value)}
                type="password"
              />
              {errorsLog && errorsLog.password ? (
                <div className="errors-parent">
                  <span className="error-txt">{errorsLog.password.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group
              className={`${
                errorsLog && errorsLog.password_confirmation
                  ? "has-error"
                  : null
              }`}
            >
              <Form.Label>تاكيد كلمة المرور الجديدة</Form.Label>
              <Form.Control
                id="userNewPasswordConfirmation"
                onChange={(e) => setUserNewPasswordConfirmation(e.target.value)}
                type="password"
              />
              {errorsLog && errorsLog.password_confirmation ? (
                <div className="errors-parent">
                  <span className="error-txt">
                    {errorsLog.password_confirmation.ar}
                  </span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <div className="form-controls">
          <Button type="submit" className="btn primary-btn">
            {" "}
            حفظ التعديلات
          </Button>
        </div>
      </Form>
      {sucessExsist ? (
        <div className="alret-parent">
          <div className="close-alert" onClick={handleShowSucessExsist}></div>
          <div className="cont">
            <div className="cont-txt">
              <div className="heading error">
                <FontAwesomeIcon
                  className="alert-icon sucess"
                  icon={faCheckDouble}
                ></FontAwesomeIcon>
                <h2>تم تعديل البيانات بنجاح</h2>
              </div>
            </div>
            <div className="controls">
              <Button
                className="btn primary-btn"
                onClick={handleShowSucessExsist}
              >
                اغلاق
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EditPassword;
