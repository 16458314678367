import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
// import { Sugar } from 'react-preloaders';
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingIndicator from "../../../Components/Blocks/Loading/Content";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import { getCookie } from "../../../Components/Auth/ForCookie";

const AuthorBlogsProfile = (props) => {
  const [blogsData, setBlogsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [prevPage, setPrevPage] = useState(true);
  const [LastPage, setLastPage] = useState(true);
  const [totalPages, setTotalPages] = useState(true);
  const [currentLink, setCurrentLink] = useState(true);
  const [last_page, setLast_page] = useState(true);
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    //request to get the books of this category
    getData(`api/user-blogs/${props.userId}`).then(
      (response) => {
        const data = response.data.data;
        setBlogsData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setBlogsData([]);
        setLoading(false);
      }
    );
  }, [props.userId]);

  // handle change in content according to pagination
  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const data = response.data.data;
        setBlogsData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setBlogsData([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;

  const settings_imgs = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };
  const loopOnBooks = blogsData.length ? (
    blogsData.map((blog) => {
      return (
        <Col xs={12} lg={3} key={blog.id} className="book-slide-cont">
          <div className="cont">
            <div className="cont-img">
              <NavLink to={`/blogs/${blog.id}`}>
                <LazyLoadImage src={blog.blog_img} alt="" />
              </NavLink>
            </div>
            <div className="cont-txt">
              <h3>
                <NavLink to={`/blogs/${blog.id}`}>{blog.title.ar}</NavLink>
              </h3>
            </div>
          </div>
        </Col>
      );
    })
  ) : (
    <Col xs={12} lg={7} className="no-books-show mx-auto">
      <div className="cont-txt">
        <p>
          عذراً لا يوجد لديك مقالات معروضة <br />
        </p>
        <NavLink to="/blogs/add" className="red-btn">
          <FontAwesomeIcon icon={faPlus} className="plus-icon" />
          إضافة مقال
        </NavLink>
      </div>
    </Col>
  );

  return (
    <>
      {id === getCookie("userId") && (
        <Row>
          <Col xs={12}>
            <div className="blogs/add mb-4">
              <NavLink to="/blogs/add" className="add-book-btn all-btn xl-btn">
                <FontAwesomeIcon className="add-book-icon" icon={faPlus} />
                إضافة مقال
              </NavLink>
            </div>
          </Col>
        </Row>
      )}

      <Row className="author-books-parent">
        {loading ? <LoadingIndicator /> : loopOnBooks}
      </Row>

      <Row>
        <Col xs={12} className="pagination-parent-books">
          {parseInt(totalPages) > 1 ? (
            <ul className="pagination-parent flx-centered">
              <li className="first-page">
                <button
                  onClick={handlePrevPage}
                  className={currentPage == "1" ? `disabled-btn` : `arrow-btn`}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </li>

              {currentPage != 1 && currentPage != 2 && (
                <li className="test-last" onClick={() => handleGoToPage(1)}>
                  <button>1</button>
                </li>
              )}
              {currentPage != 1 && currentPage != 2 && (
                <li className="to-continue">...</li>
              )}

              {pages}

              {totalPages != currentPage &&
                currentPage != Number(totalPages) - 1 && (
                  <li className="to-continue">...</li>
                )}
              {totalPages != currentPage &&
                currentPage != Number(totalPages) - 1 && (
                  <li
                    className="test-last"
                    onClick={() => handleGoToPage(totalPages)}
                  >
                    <button>{totalPages}</button>
                  </li>
                )}

              {/* {currentPage != } */}
              <li className="last-page">
                <button
                  onClick={handleNextPage}
                  className={
                    currentPage == totalPages ? `disabled-btn` : `arrow-btn`
                  }
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </li>
            </ul>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AuthorBlogsProfile;
