import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Favourite from "../Favourite";
import { NavLink, useHistory } from "react-router-dom";
import "./index.scss";
const BookCard = ({
  data,
  settings,
  settings_2,
  mainClass = "bookCard-slider",
  itemClass = "bookCard-slide",
}) => {
  const history = useHistory();

  return (
    <>
      {data?.length > 0 ? (
        <Slider {...settings} className={mainClass}>
          {data?.length > 0
            ? data?.map((item) => {
                return (
                  <div className={itemClass} key={item.id}>
                    <div className="cont">
                      <div className="cont-img parent-imaes-slider">
                        <Slider {...settings_2} className={mainClass}>
                          {item.images.map((img) => {
                            return (
                              <img
                                onClick={() =>
                                  history.push(`/books/${item.id}`)
                                }
                                src={img}
                                key={Math.random()}
                                alt={item.title.ar}
                              />
                            );
                          })}
                        </Slider>
                      </div>
                      <div className="cont-txt">
                        <div className="cat-parent">
                          <span className="cat-name">
                            {item.main_category.title.ar}
                          </span>
                          <div className="rating">
                            <span>{item.rates}</span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="register-icon"
                            />
                          </div>
                        </div>
                        <h3>
                          <NavLink to={`/books/${item.id}`}>
                            {item.title.ar.length > 20
                              ? `${item.title.ar.slice(0, 20)}...`
                              : item.title.ar}
                          </NavLink>
                          <Favourite
                            bookId={item.id}
                            isFavourite={`${
                              item.check_wishlist === 1 ? true : false
                            }`}
                          />
                        </h3>

                        <div className="price-info flx-centered">
                          <p className="price">
                            <span>{item.price}</span>ريال سعودي
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </Slider>
      ) : (
        <div className="errors-alert">
          <FontAwesomeIcon
            className="alert-icon"
            icon={faInfoCircle}
          ></FontAwesomeIcon>
          <span>لا توجد كتب لعرضها</span>
        </div>
      )}
    </>
  );
};

export default BookCard;
