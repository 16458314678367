import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../../../Components/Auth/ForCookie";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EditInfo from "../Edit-info";
import EditPassword from "../EditPassword";
import "./index.scss";
import { useState } from "react";
import { getData } from "../../../../Services/DataService";
import { useEffect } from "react";

const EditProfile = () => {
  const [userId, setuserId] = useState("");
  const [userImage, setuserImage] = useState("");
  const [userName, setuserName] = useState("");
  const [userGender, setUserGender] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userUserName, setuserUserName] = useState("");
  const [userRegions, setuserRegions] = useState("");
  const [userCity, setuserCity] = useState("");
  const [aboutUser, setAboutUser] = useState("");
  const [isUser, setisUser] = useState(false);

  useEffect(() => {
    getData(`api/user-profile/${getCookie("userId")}`).then(
      (response) => {
        const data = response?.data?.data;
        setuserId(data.id);
        setuserImage(data.avatar);
        setuserName(data.name);
        setuserEmail(data.email);
        setUserGender(data.gender);
        setUserMobile(data.mobile);
        setuserUserName(data.username);
        setuserRegions(data.region_id);
        setuserCity(data.city_id);
        setAboutUser(data.about_me.ar);
        setisUser(true)
      },
      (error) => {
        console.log(error);
        setisUser(false);
      }
    );
  }, []);
  return (
    <>
      <section className="athuor-page-parent">
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/profile/${userId}`} className="bredcrumb-link">
                    الملف الشخصي
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">ضبط حسابي</span>
                </li>
              </ul>
            </Col>
          </Row>
          {isUser ? (
            <Row>
              <Col xs={12} className="mx-auto">
                <Tabs className="profile-tabs-parent">
                  <TabList className="tabs-controls">
                    <Tab>المعلومات العامة</Tab>
                    <Tab>البريد / كلمة المرور</Tab>
                  </TabList>
                  <TabPanel>
                    <EditInfo
                      userImage={userImage}
                      userName={userName}
                      userUserName={userUserName}
                      userRegions={userRegions}
                      userCity={userCity}
                      userGender={userGender}
                      userMobile={userMobile}
                      aboutUser={aboutUser}
                    />
                  </TabPanel>
                  <TabPanel>
                    <EditPassword userEmail={userEmail} />
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} lg={7} className="no-user-show mx-auto">
                <div className="cont-txt">
                  <p>المستخدم غير موجود</p>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default EditProfile;
