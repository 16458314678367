import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import BaseUrl from "../../../config";
import { Helmet } from "react-helmet";
import Loading from "../../../Components/Blocks/Loading";
import "./index.scss";
import { getData } from "../../../Services/DataService";

const BooksCategories = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cats, setCats] = useState("");
  const [activeBtn, setActiveBtn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(`api/categories`).then((response) => {
      const data = response.data.data;
      setCats(data);
      setLoading(false);
    });
  }, []);

  const showSubCats = (e) => {
    var x = e.target;
    x.classList.toggle("activeLink");
    x.nextElementSibling.classList.toggle("showSubCats");
  };
  // loop data from api
  const Cats = cats ? (
    cats.map((item) => {
      return (
        <>
          <Helmet>
            <title>أقسام الكتب</title>
            <meta name="description" content="Books Categories" />
          </Helmet>
          <div key={item.id} className="cat-parent">
            {item.sub_category.length ? (
              <p onClick={showSubCats} className="siblings_of_ul">
                <a
                  href={`/books/categories/${item.id}`}
                  className="category-link-child"
                >
                  <span>{item.title.ar}</span>
                </a>
                <FontAwesomeIcon icon={faCaretLeft} className="register-icon" />
              </p>
            ) : (
              <p className="category-link">
                <a href={`/books/categories/${item.id}`}>
                  <span>{item.title.ar}</span>
                </a>
              </p>
            )}

            {item.sub_category ? (
              <ul className="sub-cats sub-cats-parent" id="for_toggel">
                {item.sub_category.map((subItem) => {
                  return (
                    <li key={subItem.id}>
                      <a href={`/books/categories/sub/${subItem.id}`}>
                        <FontAwesomeIcon
                          icon={faCaretLeft}
                          className="register-icon"
                        />
                        <span>{subItem.title.ar}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </>
      );
    })
  ) : (
    <div>
      <span>لا توجد اقسام حاليا للعرض</span>
    </div>
  );

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/" className="bredcrumb-link">
                    الرئيسية
                  </a>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">تصنيفات الكتب</span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="cats-page-parent">
                <div className="cats-body" id="catsToggle">
                  {Cats}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default BooksCategories;
