import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import Favourite from "../../../Components/Blocks/Favourite";
import Loading from "../../../Components/Blocks/Loading";
import { getData } from "../../../Services/DataService";

const SearchBooksResult = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [nextPage, setNextPage] = useState([]);
  const [prevPage, setPrevPage] = useState([]);
  const [LastPage, setLastPage] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [currentLink, setCurrentLink] = useState([]);
  const [last_page, setLast_page] = useState([]);
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [bookCity, setBookCity] = useState("");
  useEffect(() => {
    getData(
      `api/search?search_type=books&search=${
        props.searchData == "all" ? "" : props.searchData
      }`
    ).then(
      (response) => {
        const categoryData = response?.data?.data?.data;
        setCategoryData(categoryData);
        setCurrentPage(categoryData?.current_page);
        setNextPage(categoryData?.next_page_url);
        setPrevPage(categoryData?.prev_page_url);
        setLastPage(categoryData?.last_page_url);
        setTotalPages(categoryData?.last_page);
        setCurrentLink(categoryData?.path);
        setLast_page(categoryData?.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setCategoryData([]);
        setLoading(false);
      }
    );
  }, [props]);

  // handle change in content according to pagination
  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const categoryData = response?.data?.data?.data;
        setCategoryData(categoryData);
        setCurrentPage(categoryData?.current_page);
        setNextPage(categoryData?.next_page_url);
        setPrevPage(categoryData?.prev_page_url);
        setLastPage(categoryData?.last_page_url);
        setTotalPages(categoryData?.last_page);
        setCurrentLink(categoryData?.path);
        setLast_page(categoryData?.last_page);
        setPages(response.data.pages);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setCategoryData([]);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;

  const settings_imgs = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };
  const loopOnBooks = categoryData.length ? (
    categoryData.map((book) => {
      return (
        <Col xs={12} lg={4} key={book.id} className="book-cont">
          <div className="cont">
            <div className="cont-img parent-imaes-slider">
              <Slider {...settings_imgs}>
                {book.images.map((img) => {
                  return <img key={Math.random()} src={img} alt="image" />;
                })}
              </Slider>
            </div>
            <div className="cont-txt">
              <h3>
                <NavLink to={`/books/${book.id}`}>{book.title.ar}</NavLink>
                <Favourite
                  bookId={book.id}
                  isFavourite={`${book.check_wishlist === 1 ? true : false}`}
                />
              </h3>

              <div className="price-info flx-centered">
                <p className="price">
                  <span>{book.price}</span>ريال سعودي
                </p>
              </div>
            </div>
          </div>
        </Col>
      );
    })
  ) : (
    <Col xs={12} lg={7} className="no-content-show mx-auto">
      <div className="cont-txt">
        <p>
          عذراً لا يوجد لديك كتب معروضة للبيع
          <br />
          يمكنك عرض كتابك للبيع عن طريق الضغط على زر إضافة كتاب
        </p>
        <NavLink className="red-btn" to="/books/add">
          <FontAwesomeIcon icon={faPlus} className="plus-icon" />
          إضافة كتاب
        </NavLink>
      </div>
    </Col>
  );
  return (
    <>
      <Container>
        {/* <Sugar customLoading={loading} color={'#00A388'} background="#87D4C7"/> */}
        {loading && <Loading />}
        <Row>
          <Col xs={12}>
            <ul className="breadcrumb">
              <li className="breadcrumb-item t-none">
                <NavLink to="/" className="bredcrumb-link ">
                  الرئيسية
                </NavLink>
              </li>

              <li className="breadcrumb-item current">
                <NavLink
                  to="/books/categories"
                  className="mob-bredcrumb acitve"
                >
                  نتائج البحث
                </NavLink>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="books-parent">{loopOnBooks}</Row>
        <Row>
          <Col xs={12} lg={9} className="pagination-parent-books">
            {parseInt(totalPages) > 1 ? (
              <ul className="pagination-parent flx-centered">
                <li className="first-page">
                  <button
                    onClick={handlePrevPage}
                    className={
                      currentPage == "1" ? `disabled-btn` : `arrow-btn`
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </li>

                {currentPage != 1 && currentPage != 2 && (
                  <li className="test-last" onClick={() => handleGoToPage(1)}>
                    <button>1</button>
                  </li>
                )}
                {currentPage != 1 && currentPage != 2 && (
                  <li className="to-continue">...</li>
                )}

                {pages}

                {totalPages != currentPage &&
                  currentPage != Number(totalPages) - 1 && (
                    <li className="to-continue">...</li>
                  )}
                {totalPages != currentPage &&
                  currentPage != Number(totalPages) - 1 && (
                    <li
                      className="test-last"
                      onClick={() => handleGoToPage(totalPages)}
                    >
                      <button>{totalPages}</button>
                    </li>
                  )}

                {/* {currentPage != } */}
                <li className="last-page">
                  <button
                    onClick={handleNextPage}
                    className={
                      currentPage == totalPages ? `disabled-btn` : `arrow-btn`
                    }
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                </li>
              </ul>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SearchBooksResult;
