import {
  faCheckCircle,
  faClock,
  faCommentDots,
  faComments,
  faExclamationTriangle,
  faEye,
  faHeart,
  faInfoCircle,
  faMapMarkerAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AiFillCheckCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { getCookie } from "../../../Components/Auth/ForCookie";
// import CommentDeals from  '../../../Components/News/Comments/CommentDeals/CommentDeals';
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loading from "../../../Components/Blocks/Loading";
import { createData, getData } from "../../../Services/DataService";
import SendMessageCat from "../../Chats/SendMessageCat";
import "./index.scss";
import DealsComments from "../../NewsBlogs/Comments/DealsComments/DealsComments";

const DealsShow = (props) => {
  const [DealData, setDealData] = useState("");
  const [dealAuthorId, setDealAuthorId] = useState("");
  const [verified, setVerified] = useState("");
  const [service_comment, setService_comment] = useState("");
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const [sucessSend, setSucessSend] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("Services");
  const [userDataId, setUserDataId] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [service_title, setService_title] = useState("");
  const [openRejectMessage, setOpenRejectMessage] = useState(false);
  const handleShowSendRejectedMessage = (e) => {
    setOpenRejectMessage(!openRejectMessage);
  };
  useEffect(() => {
    getData(`api/services/${props.match.params.id}`).then(
      (response) => {
        const data = response?.data?.data;
        setDealData(data);
        setLoading(false);
        setDealAuthorId(data.user_id.id);
        setUserDataId(data.user_id.id);
        setVerified(data.user_id.verify);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        // setLoading(false);
        window.location.href = "/404";
      }
    );
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const formData = new FormData();
    formData.append("service_comment", service_comment);
    formData.append("user_id", getCookie("userId"));
    formData.append("type", "request");
    formData.append("service_id", props.match.params.id);
    createData(`api/request-services`, formData).then(
      (response) => {
        setSucessSend(true);
        // clearInputs();
        // window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      (error) => {
        setSucessSend(false);
        const errors = error?.response?.data;
        setErrorsLog(errors);

        // window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    );
  };
  const settings_imgs = {
    dots: true,
    // touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };
  return (
    <>
      <Helmet>
        <title>
          {DealData?.title?.ar ? DealData?.title?.ar : "تفاصيل الصفقه"}
        </title>
        <meta name="description" content="Details Deal" />
      </Helmet>
      {loading == true ? (
        <Loading />
      ) : (
        <section className="Deal-show-sec">
          <Container>
            <Row>
              <Col xs={12}>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/" className="bredcrumb-link">
                      الرئيسية
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to="/deals" className="bredcrumb-link">
                      صفقات البيع و الشراء
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <p className="mob-bredcrumb active">
                      {DealData && DealData?.title?.ar}
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="mob-reverse">
              <Col xs={12} xl={8}>
                <Row>
                  <Col xs={12}>
                    {DealData && (
                      <div className="deal-show-parent">
                        <div className="deal-heading">
                          <h2>{DealData?.title?.ar}</h2>
                          <div className="deal-details flx-centered">
                            <div>
                              <FontAwesomeIcon
                                icon={faClock}
                                className="register-icon"
                              />
                              <span>{DealData.created_at}</span>
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faCommentDots}
                                className="register-icon"
                              />
                              <span>{DealData.order_count} متقدم</span>
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faEye}
                                className="register-icon"
                              />
                              <span>{DealData.views} مشاهدة</span>
                            </div>
                          </div>
                        </div>
                        <div className="deal-body">
                          <div className="cont-img">
                            <Slider {...settings_imgs}>
                              {DealData.images.map((img) => {
                                return (
                                  <LazyLoadImage
                                    key={Math.random()}
                                    src={img}
                                    alt="dealImg"
                                  />
                                );
                              })}
                            </Slider>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DealData?.content?.ar,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </Col>

                  {getCookie("loginned") ? (
                    <Col xs={12}>
                      <div className="offer-apply-parent" id="addForm">
                        {sucessSend && (
                          <div className="sucess-alert">
                            <FontAwesomeIcon
                              className="alert-icon"
                              icon={faCheckCircle}
                            ></FontAwesomeIcon>
                            <span>تم ارسال ردك بنجاح</span>
                          </div>
                        )}
                        <form className="apply-form" onSubmit={handleSubmit}>
                          <div
                            className={`form-group ${
                              errorsLog && errorsLog.service_comment
                                ? "has-error"
                                : null
                            }`}
                          >
                            <label htmlFor="offerDetails">رد على الصفقة</label>
                            <textarea
                              name="service_comment"
                              onChange={(e) =>
                                setService_comment(e.target.value)
                              }
                              id="service_comment"
                              className="form-msg form-control"
                            ></textarea>
                            {errorsLog && errorsLog.service_comment ? (
                              <div className="errors-parent">
                                <span className="error-txt">
                                  {errorsLog?.service_comment?.ar}
                                </span>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-controls">
                            <button className="btn primary-btn lg-button">
                              تقديم الرد
                            </button>
                          </div>
                        </form>
                      </div>
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <div className="errors-alert">
                        <FontAwesomeIcon
                          className="alert-icon"
                          icon={faInfoCircle}
                        ></FontAwesomeIcon>
                        <span>
                          لتتمكن من اضافة عرض عليك الذهاب لصفحة{" "}
                          <NavLink to="/login">التسجيل</NavLink> اولا
                        </span>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>

              <Col xs={12} xl={4}>
                {getCookie("loginned") ? (
                  <>
                    {dealAuthorId == getCookie("userId") ? null : getCookie(
                        "message"
                      ) == "1" ? (
                      <div className="message-author">
                        <div
                          className="message"
                          onClick={(e) => setOpenSendMessage(!openSendMessage)}
                        >
                          <FontAwesomeIcon icon={faComments} />
                          <p>مراسلة المعلن</p>
                        </div>

                        {/* <div className="favorite desktop-hide">
                            <FontAwesomeIcon icon={faHeart} />
                          </div> */}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <div className="message-author">
                      <div
                        className="message"
                        disabled={getCookie("loginned") ? null : `disabled`}
                        title="يلزم تسجيل الدخول لتتمكن من ارسال رسالة للمعلن "
                      >
                        <FontAwesomeIcon icon={faComments} />
                        <p>مراسلة المعلن</p>
                      </div>

                      <div className="favorite desktop-hide">
                        <FontAwesomeIcon icon={faHeart} />
                      </div>
                    </div>
                  </>
                )}
                {DealData && (
                  <div className="author-sec flx-centered">
                    <div className="cont flx-centered">
                      <div className="cont-img">
                        <NavLink to={`/profile/${DealData.user_id.id}`}>
                          <LazyLoadImage
                            src={`${DealData.user_id.avatar}`}
                            alt=""
                          />
                        </NavLink>
                      </div>
                      <div className="cont-txt">
                        <h3>
                          <NavLink to={`/profile/${DealData.user_id.id}`}>
                            {DealData.user_id.name}{" "}
                          </NavLink>
                          {DealData.user_id?.verified == "2" ? (
                            <span>
                              <AiFillCheckCircle />
                            </span>
                          ) : (
                            <span className="rejectedVrf">غير موثق</span>
                          )}
                        </h3>
                        <div className="author-info mb-2 flx-centered">
                          {DealData.user_id.roles?.ar === null ? null : (
                            <span className="author-type">
                              {DealData.user_id.roles?.ar}
                            </span>
                          )}

                          <span className="author-rate">
                            {DealData.user_id.user_rate}
                            <FontAwesomeIcon
                              icon={faStar}
                              className="rate-icon"
                            />
                          </span>
                        </div>
                        <p className="author-location">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className="rate-icon"
                          />
                          {DealData?.user_id?.city?.ar}{" "}
                          {DealData?.user_id?.country &&
                            `-${DealData?.user_id?.country}`}
                        </p>
                      </div>
                    </div>
                  </div>

                  // <div className="author-sec flx-centered">
                  //   <div className="cont flx-centered">
                  //     <div className="cont-img">
                  //       <NavLink to={`/profile/${DealData.user_id.id}`}>
                  //         <LazyLoadImage
                  //           src={`${DealData.user_id.avatar}`}
                  //           alt=""
                  //         />
                  //       </NavLink>
                  //     </div>
                  //     <div className="cont-txt">
                  //       <h2 className="author-name">
                  //         <NavLink
                  //           to={`/profile/${DealData.user_id.id}`}
                  //         >
                  //           {DealData.user_id.name} {" "}
                  //         </NavLink>
                  //         {(() => {
                  //           if (
                  //             verified == "1"
                  //           ) {
                  //             return (
                  //               <span>
                  //                 <AiFillCheckCircle/>
                  //               </span>
                  //             );
                  //           } else {
                  //             return (
                  //               <>
                  //                   <span className="rejectedVrf">
                  //                    غير موثق
                  //                   </span>
                  //               </>
                  //             );
                  //           }
                  //         })()}
                  //       </h2>

                  //       <div className="author-info flx-centered">
                  //         {/* <span className="author-type">
                  //           {DealData.user_id.roles?.ar}
                  //         </span> */}
                  //         <span className="author-rate">
                  //           {DealData.user_id.user_rate}
                  //           <FontAwesomeIcon
                  //             icon={faStar}
                  //             className="rate-icon"
                  //           />
                  //         </span>
                  //       </div>
                  //       <p className="author-location mt-2">
                  //         <FontAwesomeIcon
                  //           icon={faMapMarkerAlt}
                  //           className="rate-icon"
                  //         />
                  //         {DealData?.user_id?.city_id?.title?.ar}{" "}
                  //         {DealData?.user_id?.region_id?.title?.ar}
                  //       </p>
                  //     </div>
                  //   </div>
                  // </div>
                )}

                <div className="instructions">
                  <h2>
                    <FontAwesomeIcon
                      className="safety-icon"
                      icon={faExclamationTriangle}
                    />
                    إرشادات لسلامتك في حال مقابلة المعلن
                  </h2>
                  <p className="instruction">
                    <span className="number">1-</span>
                    يرجي مقابلة المعلن في الأماكن العامة
                  </p>
                  <p className="instruction">
                    <span className="number">2-</span>
                    من الأفضل اصطحاب شخص معك اثناء مقابلة المعلن
                  </p>
                  <p className="instruction">
                    <span className="number">3-</span>
                    لا تقوم بدفع او تحويل ثمن الكتاب قبل معاينته
                  </p>
                </div>
                {/* <button
                    className="rejectedContent"
                    onClick={handleShowSendRejectedMessage}
                    disabled={getCookie("loginned") ? null : `disabled`}
                    title={
                      getCookie("loginned")
                        ? null
                        : "يلزم تسجيل الدخول لتتمكن من الابلاغ عن محتوى مخالف"
                    }
                  >
                    ابلاغ عن محتوي مخالف <span>!</span>
                  </button> */}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8} className="mx-auto">
                <DealsComments service_orders={DealData.service_orders} />
                {/* <CommentDeals blogId={props.match.params.id}/> */}
              </Col>
              <Col xs={12} lg={4}></Col>
            </Row>
          </Container>
        </section>
      )}

      {openSendMessage ? (
        <div className="alret-parent send-message-parent">
          <div
            className="close-alert"
            onClick={(e) => setOpenSendMessage(!openSendMessage)}
          ></div>
          <div className="cont">
            <div className="heading">
              <h2>إرسال رسالة للمعلن</h2>
            </div>
            <SendMessageCat
              recevierId={dealAuthorId}
              type={type}
              CatID={props.match.params.id}
            />
          </div>
        </div>
      ) : null}
      {/* {openSendMessage ? (
            <div className="alret-parent send-message-parent">
              <div
                className="close-alert"
                onClick={(e) => setOpenSendMessage(!openSendMessage)}
              ></div>
              <div className="cont">
                <div className="heading">
                  <h2>إرسال رسالة للمعلن</h2>
                </div>
                <SendMessageCat recevierId={dealAuthorId} type={type} CatID={props.match.params.id}/>             </div>
            </div>
          ) : null} */}
    </>
  );
};

export default DealsShow;
