import {getCookie} from './Components/Auth/ForCookie';
// const BaseUrl = 'https://development.dimofinf.net/skitab'; 
const BaseUrl = process.env.REACT_APP_API_ENDPOINT; 
const headers = {
    "Content-Type": "multipart/form-data",
    "Accept":"application/json",
    "Authorization":`Bearer ${getCookie('token')}`
}

export {
    headers ,
};
export default BaseUrl;