import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHeart  } from '@fortawesome/free-solid-svg-icons';
import DimLogo from '../../../../Assets/Img/dimLogo.png';
import './index.scss';

const WithLove = () => {
    return (
        <>
        <section className="with-love-section flx-centered">
            <div className="with-love flx-centered">
                <span>
                    صنع بـ
                </span>
                <FontAwesomeIcon className="with-love-icon" icon={faHeart}/>
                <span>بواسطة</span>
                <a href="https://www.dimofinf.net/" target="_blank" rel="noopener noreferrer" className="m-r-8">
                    <img src={DimLogo} alt=""/>
                </a>
            </div>
        </section>
            
        </>
    );
}
 
export default WithLove;