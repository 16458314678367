import React from "react";
import { Route, Switch } from "react-router-dom";
import ForgetPassword from "../Components/Auth/ForgetPassword/ForgetPassword";
import Login from "../Components/Auth/Login";
import Register from "../Components/Auth/Register";
import ResetPassword from "../Components/Auth/ResetPassword/ResetPassword";
import SearchProvider from "../Components/Auth/WebsiteContext/SearchContext/SearchProvider";
import AboutUS from "../pages/AboutUs";
import AddBook from "../pages/Books/AddBook";
import AllBooks from "../pages/Books/AllBooks";
import BookShow from "../pages/Books/BookShow";
import BooksCategories from "../pages/Books/CategoriesBooks";
import SingleCategory from "../pages/Books/SingleCategoryBook";
import MessagesList from "../pages/Chats/MessageList";
import ContactUS from "../pages/ContactUs";
import DealsAdd from "../pages/Deals/DealsAdd";
import DealsList from "../pages/Deals/DealsList";
import DealsShow from "../pages/Deals/DealsShow";
import EventsList from "../pages/Events/EventsList/EventsList";
import EventsShow from "../pages/Events/EventsShow";
import Home from "../pages/Home/Home";
import HowToUse from "../pages/HowToUse";
import NewsShow from "../pages/NewsBlogs/BlogsNewsDetails";
import BlogsNewsList from "../pages/NewsBlogs/BlogsNewsList";
import NewsAdd from "../pages/NewsBlogs/NewsAdd/NewsAdd";
import NewsCatsSingle from "../pages/NewsBlogs/NewsCatsSingle";
import ErrorPage from "../pages/NotFound/404Page";
import PaymentInformation from "../pages/PaymentInfo/PaymentInfo";
import Privacy from "../pages/PrivacyPolicy/Privacy";
import AuthorPage from "../pages/Profile/Author-page/Index";
import EditProfile from "../pages/Profile/Edit-profile-components/Edit-profile";
import SearchIndex from "../pages/Search";
import BestSeller from "../pages/Seller";
import Terms from "../pages/TermsAconditions";
import CategoryDetailed from "../pages/Books/SingleCategoryBook/CategoryDetaild";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";

const AppRoutes = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" component={AboutUS} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route
          exact
          path="/verify-email/:token/:email"
          component={VerifyEmail}
        />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/deals" component={DealsList} />
        <Route path="/deals/add" component={DealsAdd} />
        <Route path="/deals/:id" component={DealsShow} />
        <Route exact path="/books" component={AllBooks} />
        <Route path="/books/add" component={AddBook} />
        <Route exact path="/books/categories" component={BooksCategories} />
        <Route
          exact
          path="/books/categories/:id"
          component={CategoryDetailed}
        />
        <Route path="/books/categories/:sub/:id" component={SingleCategory} />
        <Route path="/books/:id" component={BookShow} />
        <Route exact path="/events" component={EventsList} />
        <Route path="/events/:id" component={EventsShow} />
        <Route exact path="/news" component={BlogsNewsList} />
        <Route path="/news/category/:id" component={NewsCatsSingle} />
        <Route path="/news/:id" component={NewsShow} />
        <Route exact path="/blogs" component={BlogsNewsList} />
        <Route path="/blogs/add" component={NewsAdd} />
        <Route path="/blogs/:id" component={NewsShow} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/how-to-use" component={HowToUse} />
        <Route path="/profile/:id" component={AuthorPage} />
        <Route path="/edit-profile" component={EditProfile} />
        <Route path="/messages" component={MessagesList} />
        <Route path="/contact-us" component={ContactUS} />
        <Route path="/payment" component={PaymentInformation} />
        <Route path="/best-seller" component={BestSeller} />
        <Route path="/terms-conditions" component={Terms} />
        <SearchProvider>
          <Route path="/search/:type" component={SearchIndex} />
        </SearchProvider>
        <Route path="*" component={ErrorPage} />

        <Route path="/404" component={ErrorPage} />

        {/* <Redirect to='/404'/> */}
      </Switch>
    </>
  );
};
export default AppRoutes;
