import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getCookie, setCookie } from "../../../../Components/Auth/ForCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AiFillCheckCircle } from "react-icons/ai";
import { createData, getData } from "../../../../Services/DataService";
import "./index.scss";
const EditInfo = (props) => {
  // const [userData, setUserData] = useState([]);
  const [userImage, setUserImage] = useState([]);
  const [userName, setUserName] = useState("");
  const [userUserName, setUserUserName] = useState("");
  const [userRegions, setUserRegions] = useState("");
  const [userCity, setUserCity] = useState("");
  const [aboutUser, setAboutUser] = useState("");
  const [Regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [Cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [errorsLog, setErrorsLog] = useState({});
  const [errorExsist, setErrorExsist] = useState(false);
  const [sucessExsist, setSucessExsist] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserImage(props.userImage);
    setUserName(props.userName);
    setUserUserName(props.userUserName);
    setMobile(props.userMobile);
    setGender(props.userGender);
    setUserRegions(props.userRegions);
    setUserCity(props.userCity);
    setAboutUser(props.aboutUser);
  }, [props]);

  const handleFileUpload = (e) => {
    let fileSrc = e.target.files;
    const [file] = fileSrc;
    setUserImage(fileSrc[0]);
    if (file) {
      document
        .getElementById("changePhoto")
        .setAttribute("src", URL.createObjectURL(file));
    } else if (file === undefined) {
      document.getElementById("changePhoto").setAttribute("src", "");
    }
  };

  const handleShowErrorExsist = (e) => {
    setErrorExsist(!errorExsist);
  };
  const handleShowSucessExsist = (e) => {
    setSucessExsist(!sucessExsist);
  };

  const hanldeREfreshUserData = (e) => {
    getData(`api/user-profile/${e}`).then(
      (response) => {
        const data = response.data.data;
        // setUserData(data);
        setCookie("userName", response.data.data.name);
        setCookie("userimage", response.data.data.avatar);
        if(response.data.data.completed_at)
          setCookie("c_at", response.data.data.completed_at);
        },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    formData.append("avatar", userImage);
    formData.append("name", userName.length > 0 ? userName : props.userName);
    formData.append("mobile", mobile.length > 0 ? mobile : props.mobile);
    formData.append("gender", gender ? gender : props.gender);
    formData.append(
      "username",
      userUserName.length > 0 ? userUserName : props.userUserName
    );

    formData.append(
      "region_id",
      userRegion.length > 0 ? userRegion : props.userRegions ? props.userRegions : Regions[0].id
    );
    formData.append("city_id", userCity || Cities[0].id);
    formData.append(
      "contentAr",
      aboutUser.length > 0 ? aboutUser : props.aboutUser
    );
    formData.append(
      "contentEn",
      aboutUser.length > 0 ? aboutUser : props.aboutUser
    );
    formData.append("mobile", mobile);
    formData.append("avatar", avatar);
    formData.append("gender", gender);

    if (userImage?.length > 0) {
      formData.append("avatar", userImage);
    }

    createData(`api/update-main-data`, formData).then(
      (response) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setSucessExsist(true);
        hanldeREfreshUserData(getCookie("userId"));
      },
      (error) => {
        if (error) {
          const errors = error.response.data;
          setErrorsLog(errors);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );
  };

  const loopOnRegions =
    Regions.length > 0
      ? Regions.map((item) => {
          return (
            <option
              key={item.id}
              value={item.id}
              selected={userRegions == item.id}
            >
              {item.title.ar}
            </option>
          );
        })
      : null;

  const loopOnCities =
    Cities.length > 0
      ? Cities.map((item) => {
          return (
            <option
              key={item.id}
              value={item.id}
              selected={userCity == item.id}
            >
              {item.title.ar}
            </option>
          );
        })
      : null;

  //=============================================================================================================================
  const [userRegion, setUserRegion] = useState("");
  const [mobile, setMobile] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [userPicName, setUserPicName] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [gender, setGender] = useState("male");
  const [alertErrors, setAlertErrors] = useState(false);
  const [sucessRegister, setSucessRegister] = useState(false);
  const [code, setCode] = useState();
  const [sucsessSendCode, setSucsessSendCode] = useState(false);
  const [checkCode, setCheckCode] = useState("");
  const [codeSucsess, setCodeSucsess] = useState("");
  const [counter, setCounter] = useState(60);
  const [counterDown, setCounterDown] = useState(60);
  const [textarea, setTextarea] = useState("");

  //========
  useEffect(() => {
    //send request to get the regions
    getData(`api/regions`).then(
      (response) => {
        const Regions = response.data.data;
        setRegions(Regions);
        setUserRegion(Regions[0].id);
        if (Regions.length > 0) {
          getData(`api/regions/${props.userRegions ? props.userRegions : Regions[0].id}`).then(
            (response) => {
              const Cities = response.data.data;
              setCities(Cities);
              setUserCity(props.userCity ? props.userCity : Cities[0].id);
            },
            (error) => {
              setCities([]);
            }
          );
        }
      },
      (error) => {
        setRegions([]);
      }
    );
  }, []);

  //handle chage regions
  const handleChangeRegions = (e) => {
    setUserRegion(e.target.value);
    getData(`api/regions/${e.target.value}`).then(
      (response) => {
        const Cities = response.data.data;
        setCities(Cities);
        setUserCity(Cities[0].id);
      },
      (error) => {
        setCities([]);
      }
    );
  };
  // Sms Provider

  const handelChangeCode = (e) => {
    setCheckCode(!checkCode);
  };

  const handelSmsCode = (e) => {
    e.preventDefault();
    setErrorsLog({});
    setAlertErrors(false);
    {
      mobile === "" ? setSucsessSendCode(false) : setSucsessSendCode(true);
    }

    const formData = new FormData();
    formData.append("mobile", mobile);
    createData(`api/send-mobile-code`, formData).then(
      (response) => {
        setSucsessSendCode(true);
        let counter = 60;
        setCounter(counter);
        let interval = setInterval(() => {
          counter--;
          setCounterDown(counter);
          if (counter === 0) {
            clearInterval(interval);
          }
        }, 1000);
      },
      (error) => {
        if (error) {
          const errors = error.response.data;
          setErrorsLog(errors);
          setAlertErrors(true);
        }
      }
    );
  };
  const handelCheckCode = () => {
    checkCode === "0000" ? setCodeSucsess(true) : setCodeSucsess(false);
  };

  useEffect(() => {
    handelCheckCode();
  }, [checkCode]);

  // upload and get the image of user
  const handleUserPicUpload = (e) => {
    let fileSrc = e.target.files;
    setAvatar(fileSrc[0]);
  };

  const getTextOfInputName = (e) => {
    let uploadFileText = e.target.value;
    const [fileText] = e.target.files;
    if (fileText) {
      setUserPicName(uploadFileText);
    }
    // setState({userPic:fileText});
  };
  const handleChangeCity = (e) => {
    setUserCity(e.target.value);
  };
  useEffect(() => {
    getData(`api/mobile-code/${mobile}`).then(
      (response) => {
        const code = response.data.data.code;
        setCode(code);
      },
      (error) => {}
    );
  }, [mobile]);

  const BaseUrl = process.env.REACT_APP_API_ENDPOINT;

  //=============================================================================================================================
  return (
    <>
      <Form className="FormParent edit-info-form" onSubmit={handleSubmit}>
        <Row>
          <Col>
            {errorsLog && errorsLog.error ? (
              <div className="errors-alert">
                <FontAwesomeIcon
                  className="alert-icon"
                  icon={faInfoCircle}
                ></FontAwesomeIcon>
                <span>{errorsLog.error}</span>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className="upload-image-parent flex-center required">
              <div className="cont-img">
                <LazyLoadImage src={`${BaseUrl}/${props.userImage}`} id="changePhoto" alt="" />
              </div>
              <div className="input-file-parent">
                <Form.Control
                  type="file"
                  id="userImage"
                  className="btn primary-btn"
                  onChange={handleFileUpload}
                />
                <Button className="btn primary-btn">تبديل الصورة</Button>
              </div>
              {errorsLog && errorsLog.avatar ? (
                <div className="errors-parent">
                  <span className="text-danger">{errorsLog.avatar.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group
              className={`${errorsLog && errorsLog.name ? "has-error" : null}`}
            >
              <Form.Label>الاسم كامل</Form.Label>
              <Form.Control
                id="userName"
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                value={userName}
                placeholder={props.userName}
              />
              {errorsLog && errorsLog.name ? (
                <div className="errors-parent">
                  <span className="text-danger">{errorsLog.name.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group
              className={`${
                errorsLog && errorsLog.username ? "has-error" : null
              }`}
            >
              <Form.Label>اسم العضوية</Form.Label>
              <Form.Control
                id="userUserName"
                onChange={(e) => setUserUserName(e.target.value)}
                type="text"
                value={userUserName}
                placeholder={props.userUserName}
              />
              {errorsLog && errorsLog.username ? (
                <div className="errors-parent">
                  <span className="text-danger">{errorsLog.username.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={3}>
            <Form.Group
                className={`${
                    errorsLog && errorsLog.country ? "has-error" : null
                }`}
            >
              <Form.Label>المنطقة</Form.Label>
              {Regions.length > 0 ? (
                  <select onChange={handleChangeRegions}>{loopOnRegions}</select>
              ) : (
                  <div className="form-txt">لا يوجد مناطق لعرضها</div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group
                className={`${errorsLog && errorsLog.city ? "has-error" : null}`}
            >
              <Form.Label>المدينة</Form.Label>
              {Cities.length > 0 ? (
                  <select onChange={handleChangeCity}>{loopOnCities}</select>
              ) : (
                  <div className="form-txt">لا يوجد مدن لعرضها</div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group
                className={`${
                    errorsLog && errorsLog.mobile ? "has-error" : null
                }`}
            >
              <Form.Label>رقم الجوال *</Form.Label>
              <Form.Control
                  id="mobile"
                  onChange={(e) =>
                      setMobile(e.target.value)
                  }
                  type="text"
                  value={mobile}
                  placeholder="رقم جوالك"
              />

              <div className="smsProvider">
                <span onClick={handelSmsCode} className="sendCode" title="">
                  {sucsessSendCode === false || (errorsLog && errorsLog.mobile)
                      ? " أرسل كود التحقق"
                      : "اعادة ارسال"}
                </span>
                {sucsessSendCode == false ||
                (errorsLog && errorsLog.mobile) ? null : (
                    <>
                      <Form.Label>
                        أدخل الكودالمرسل لجوالك *{" "}
                        {codeSucsess === true ? null : (
                            <span className="counterNotif">
                          {" "}
                              خلال {counterDown} ثانية
                        </span>
                        )}{" "}
                      </Form.Label>
                      <Form.Control
                          id="checkCode"
                          onChange={(e) => setCheckCode(e.target.value)}
                          type="text"
                          placeholder="الكود"
                      />
                      <span onClick={handelCheckCode} className="checkCode">
                      {codeSucsess === true
                          ? "  كود صحيح"
                          : codeSucsess === false &&
                          errorsLog &&
                          errorsLog.checkCode
                              ? "اعادة ارسال"
                              : "تحقق"}
                    </span>

                      {codeSucsess === true ? (
                          <span
                              className="rightCode"
                              title="الكود صحيح يمكنك استكمال البيانات لتتمكن من تسجيل عضوية جديده"
                          >
                        {" "}
                            الكود صحيح يمكنك استكمال البيانات لتتمكن من تسجيل عضوية
                        جديده
                        <AiFillCheckCircle />
                      </span>
                      ) : codeSucsess === false ? (
                          <div className="has-error errors-parent">
                            <span className="text-danger">{errorsLog.data}</span>
                          </div>
                      ) : null}
                    </>
                )}
                {errorsLog && errorsLog.checkCode ? (
                    <div className="errors-parent">
                      <span className="text-danger">{errorsLog.data}</span>
                    </div>
                ) : null}
              </div>
              {errorsLog && errorsLog.mobile ? (
                  <div className="errors-parent">
                    <span className="text-danger">{errorsLog.mobile?.ar}</span>
                  </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3  mobile-wrap" id="formType">
              <Form.Label>حدد الجنس</Form.Label>
              <div className="radio-parent">
                <label className="radio-label">
                  ذكر
                  <input
                      type="radio"
                      id="male"
                      name="gender"
                      checked={gender === 'male'}
                      defaultChecked="checked"
                      onClick={(e) => setGender(e.target.id)}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="radio-label">
                  انثي
                  <input
                      type="radio"
                      id="female"
                      name="gender"
                      checked={gender === 'female'}
                      onClick={(e) => setGender(e.target.id)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {errorsLog && errorsLog.gender ? (
                  <div className="errors-parent">
                    <span className="text-danger">{errorsLog.gender.ar}</span>
                  </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className={`${
                errorsLog && errorsLog.contentAr ? "has-error" : null
              }`}
            >
              <Form.Label>معلومات عني</Form.Label>
              <Form.Control
                id="aboutUser"
                as="textarea"
                defaultValue={props.aboutUser}
                onChange={(e) => setAboutUser(e.target.value)}
                placeholder={props.aboutUser}
              />
              {errorsLog && errorsLog.contentAr ? (
                <div className="errors-parent">
                  <span className="text-danger">{errorsLog.contentAr.ar}</span>
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <div className="form-controls">
          <Button type="submit" className="btn primary-btn">
            حفظ التعديلات
          </Button>
        </div>
      </Form>

      {sucessExsist ? (
        <div className="alret-parent">
          <div className="close-alert" onClick={handleShowSucessExsist}></div>
          <div className="cont">
            <div className="cont-txt">
              <div className="heading error">
                <FontAwesomeIcon
                  className="alert-icon sucess"
                  icon={faCheckDouble}
                ></FontAwesomeIcon>
                <h2>تم تعديل البيانات بنجاح</h2>
              </div>
            </div>
            <div className="controls">
              <Button
                className="btn primary-btn"
                onClick={handleShowSucessExsist}
              >
                اغلاق
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EditInfo;
