import React, { Component } from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import "./Ticker.scss";
import axios from "axios";
import BaseUrl from "../../../config";

class Ticker extends Component {
  state = {
    articles: "",
  };

  async componentDidMount() {
    await axios.get(`${BaseUrl}/api/newsticker`).then((response) => {
      const articles = response?.data?.data;
      this.setState({ articles });
    });
  }

  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      rtl: true,
      vertical: true,
      verticalSwiping: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            arrows: false,
          },
        },
      ],
    };
    return (
      <>
        <Container>
          <Row>
            <Col>
              <Slider {...settings} className="news-ticker">
                {this.state?.articles?.length > 0 ? (
                  this.state.articles.map((item) => {
                    return (
                      <div key={item.id}>
                        <p className="ticker-body">{item.title.ar}</p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p className="ticker-body">لا توجد بيانات</p>
                  </div>
                )}
              </Slider>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Ticker;
