import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import BookCard from "../../../Components/Blocks/BookCard";
import { getData } from "../../../Services/DataService";
import "./index.scss";

const RecommendedBooks = () => {
  const [recommendedBooks, setRecommendedBooks] = useState();
  useEffect(() => {
    getData(`api/books`).then(
      (response) => {
        const data = response?.data?.data?.data;
        setRecommendedBooks(data);
      },
      (error) => {
        setRecommendedBooks([]);
      }
    );
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Number(recommendedBooks?.length) >= 4 ? 4 : 1,
    slidesToScroll: 2,
    arrows: true,
    rtl: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Number(recommendedBooks?.length) >= 3 ? 3 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 922,
        settings: {
          slidesToShow: Number(recommendedBooks?.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Number(recommendedBooks?.length) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      // {
      //   breakpoint: 580,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: false,
      //   },
      // },
    ],
  };
  const settings_2 = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    rtl: true,
  };
  return (
    <>
      <section className="recommended-books-sec">
        <Container>
          <Row>
            <Col>
              <div className="widget-title">
                <h2>كتب مقترحة</h2>
                <div className="all-books">
                  <a href="/books" className="all-btn">
                    جميع الكتب
                  </a>
                </div>
              </div>
              <BookCard
                data={recommendedBooks}
                settings={settings}
                settings_2={settings_2}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RecommendedBooks;
